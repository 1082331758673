import React from 'react';
import styles from './tag.module.scss';

const tagColors = {
  status: '#EAEAEA',
  caseProductType: '#D8EEEE',
  datasetName: '#F4EBD2',
  motivation: '#DBE7FD',
  specialty: '#F2E4E4',
};

const Tag = ({ type, textAlign, tagName, onClick = () => {} }) => {
  const tagStyles = {
    backgroundColor: tagColors[type] || tagColors['status'],
    textAlign: textAlign || 'center',
  };

  return (
    <div className={styles.tag} style={tagStyles} onClick={onClick}>
      <div className="txt size-13 w-500 lh-20">{tagName}</div>
    </div>
  );
};

export default Tag;
