import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FiCheck } from 'react-icons/fi';
import { ButtonCustom } from 'components/form-input/Button';
import { useMixpanel } from 'react-mixpanel-browser';
import {
  setOnboardingData,
  updateProfileInfo,
  requestGetUserProfile,
  setVideoModalDisplay,
  completeProfile,
} from 'redux/modules/userProfile/actions';
import {
  initiateUserSubscription,
  getStripeConfig,
  setStripeError,
} from 'redux/modules/userSubscription/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getRawToken } from '../utils/validations';
import urlConstantsEnum from '../constants/enum/urlConstantsEnum';
import { useHistory } from 'react-router-dom';
import AuthLoader from 'components/AuthLoader';
import { RiErrorWarningFill } from 'react-icons/ri';
import {
  subscriptionObjEnum,
  subscriptionEnum,
  subscriptionFrequencyEnum,
} from '../constants/enum/subscriptionEnum';

const OnboardingPricing = ({ onClickOfPricingBackBtn }) => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  let history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const { onboardingData, userData, isLoading, updateProfileError } = useSelector(
    ({ userProfile }) => userProfile,
  );

  const { stripeData, sessionData, isShowLoading } = useSelector(
    ({ userSubscription }) => userSubscription,
  );

  const { orderScopeData } = useSelector(({ orderDetails }) => orderDetails);

  const [subscriptionsValue, setSubscriptionsValue] = useState(
    onboardingData?.subscription_guid ? onboardingData?.subscription_guid : {},
  );
  const [subscriptionFrequency, setSubscriptionFrequency] = useState(
    onboardingData?.subscription_frequency
      ? onboardingData?.subscription_frequency
      : subscriptionFrequencyEnum.MONTHLY,
  );

  const [tabIndex, setTabIndex] = useState(
    onboardingData?.subscription_frequency === subscriptionFrequencyEnum.YEARLY ? 1 : 0,
  );

  const [isShowLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const getStripeConfigData = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getStripeConfig({
          token: getRawToken(token),
          tokenExpiredCallback: redirectToLogin,
        }),
      );
    };
    getStripeConfigData();
  }, [dispatch]);

  const initiateSubscription = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      initiateUserSubscription({
        token: getRawToken(token),
        successCallback: redirectToStripeCheckout,
        tokenExpiredCallback: redirectToLogin,
      }),
    );
  };

  const redirectToLogin = () => {
    history.push(urlConstantsEnum.LOGIN);
  };

  const redirectToStripeCheckout = async () => {
    setShowLoader(false);
  };

  const setPricing = (subscription) => {
    mixpanel.track('User selected subscription', {
      subscription_guid: subscription.guid,
    });
    setSubscriptionsValue(subscription);
  };

  const setPricingOnChangeOfTab = (index) => {
    setTabIndex(index);
    setSubscriptionFrequency(
      index === 0 ? subscriptionFrequencyEnum.MONTHLY : subscriptionFrequencyEnum.YEARLY,
    );
    setSubscriptionsValue({});
  };

  const onClickOfPricingBackButton = () => {
    saveInformation();
    !!onClickOfPricingBackBtn && onClickOfPricingBackBtn();
  };

  const saveInformation = () => {
    let onboardingDataObj = {
      agreed_terms_of_service: onboardingData?.agreed_terms_of_service,
      subscription_guid: subscriptionsValue,
      subscription_frequency: subscriptionFrequency,
    };
    dispatch(setOnboardingData(onboardingDataObj));
  };

  const saveUsersSettings = async () => {
    setShowLoader(true);
    const token = await getIdTokenClaims();
    dispatch(
      updateProfileInfo({
        params: {
          agreed_terms_of_service: onboardingData?.agreed_terms_of_service,
          subscription_guid: subscriptionsValue.guid,
        },
        userGuid: userData.guid,
        token: getRawToken(token),
        successCallback: redirectBasedOnSubscription,
      }),
    );
    document.body.classList.remove('hide-chat');
    dispatch(setOnboardingData({}));
  };

  const getProfileData = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      requestGetUserProfile({
        token: getRawToken(token),
      }),
    );
  };

  const redirectBasedOnSubscription = async () => {
    const token = await getIdTokenClaims();
    dispatch(completeProfile({ userGuid: userData.guid, token: getRawToken(token) }));
    if (subscriptionsValue.name === subscriptionEnum.STANDARD) {
      initiateSubscription();
    } else {
      setShowLoader(false);
      dispatch(
        setVideoModalDisplay({
          isDisplayModal: true,
          subscriptionType: subscriptionsValue.name,
        }),
      );
      getProfileData();
      history.push(
        orderScopeData?.scopeTabIndex === 1
          ? urlConstantsEnum.LIBRARY_DASHBOARD
          : urlConstantsEnum.DASHBOARD,
      );
    }
  };

  const showSubscriptions = () => {
    const listItems = subscriptionObjEnum?.map((subscription) => {
      if (subscription.frequency === subscriptionFrequency) {
        return (
          <label
            htmlFor="html"
            className={`p-3 mb-lg-0 mb-3 pricing-label ${
              subscriptionsValue.guid === subscription.guid ? 'label-checked' : ''
            }`}
            onClick={() => setPricing(subscription)}
            key={subscription.guid}
          >
            <div className="input-box p-3">
              <div className="d-flex">
                <input
                  type="radio"
                  id={subscription.name + '-' + subscription.guid}
                  name="subscription"
                  value={subscription.guid}
                  checked={subscriptionsValue.guid === subscription.guid ? true : false}
                  className="flex-self-center"
                  onClick={() => setPricing(subscription)}
                  onChange={(e) => {}}
                ></input>
                <div className="input-name flex-self-center">{subscription.name}</div>
              </div>
              {subscription.pricing !== '' ? (
                <div className="pricing-value mt-2">{subscription.pricing}</div>
              ) : (
                ''
              )}
              <div className="pricing-desc mt-2">{subscription.subtext}</div>
            </div>
            <ul>
              {subscription.features.map((list, i) => {
                return (
                  <li key={subscription.guid + '-' + i}>
                    <div className="d-flex">
                      <div className="check-icon">
                        <FiCheck />
                      </div>
                      <div>{list}</div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </label>
        );
      }
    });
    return <div className="d-lg-flex flex-justify-between">{listItems}</div>;
  };

  !!updateProfileError ? setShowLoader(false) : '';

  return (
    <>
      {isLoading || isShowLoading || isShowLoader ? (
        <div className="position-relative">
          <AuthLoader fullScreen={false} />
        </div>
      ) : (
        <>
          <div className="onboarding-section-header my-4">Plans &amp; pricing</div>
          <Tabs
            selectedTabClassName="active-tab gradient-btn "
            className="custom-tabs"
            onSelect={(index) => setPricingOnChangeOfTab(index)}
            defaultIndex={tabIndex}
          >
            <div className="d-flex flex-md-justify-center mb-4 flex-md-row flex-column-reverse">
              <TabList className="d-flex custom-tablist" id="pricingTabs">
                <Tab id={'tab-monthly'}>MONTHLY</Tab>
                <Tab id={'tab-yearly'}>YEARLY</Tab>
              </TabList>
            </div>
            <TabPanel>
              <div className="d-lg-flex flex-justify-between">{showSubscriptions()}</div>
            </TabPanel>
            <TabPanel>
              <div className="d-lg-flex flex-justify-between">{showSubscriptions()}</div>
            </TabPanel>
          </Tabs>
          <div className="d-lg-flex flex-justify-between mt-3 container-lg clearfix">
            <div className="col-12 col-lg-6 pricing-text">
              If you’re interested in one-time Publication Support,{' '}
              <div className="d-inline d-lg-block">
                click <a href="mailto:support@atroposhealth.com">here</a> to purchase
              </div>
            </div>
            <div className="col-12 col-lg-6 d-flex flex-justify-end mt-lg-0 mt-3 flex-self-center">
              <ButtonCustom
                cssClass={`back-btn py-3 px-3 mr-3 width-50 fixed-height`}
                onClick={onClickOfPricingBackButton}
                buttonAction={'Onboarding pricing back button clicked'}
                id={'btn-onboarding-pricing-back-button'}
              >
                Back
              </ButtonCustom>
              <ButtonCustom
                cssClass={`gradient-btn mt-md-0 py-3 px-3 width-50 fixed-height ${
                  Object.keys(subscriptionsValue).length === 0 ? 'btn-disabled' : ''
                }`}
                onClick={() => saveUsersSettings()}
                disabled={Object.keys(subscriptionsValue).length === 0 ? true : false}
                id={'btn-onboarding-pricing-confirm-button'}
                buttonAction={'Onboarding pricing confirm button clicked'}
              >
                {isLoading ? 'Loading...' : 'Confirm and Continue'}
              </ButtonCustom>
            </div>
          </div>
          {!!updateProfileError ? (
            <div className="d-flex onboarding-error-container mt-4">
              <div className="mr-3 flex-self-center">
                <RiErrorWarningFill className="d-block" />
              </div>
              <div className="flex-self-center">{updateProfileError}</div>
            </div>
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default OnboardingPricing;
