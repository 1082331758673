import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RiErrorWarningFill } from 'react-icons/ri';
import { VscChromeClose } from 'react-icons/vsc';
import CustomModal from 'components/CustomModal';
import { ButtonCustom } from 'components/form-input/Button';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import { getorderByGuidError } from 'redux/modules/orderDetails/actions';
import { labels } from 'constants/labels';

function Unauthorized() {
  const dispatch = useDispatch();
  let history = useHistory();
  const { getOrderDetailError } = useSelector(({ orderDetails }) => orderDetails);

  return (
    <CustomModal
      isOpen={getOrderDetailError && true}
      handleClose={() => {}}
      cssClass="white-overlay"
      modalname="Order modal"
      overlayCssClass="order-modal-overlay"
    >
      <div className="email-modal p-3 order-modal">
        <div className="d-flex error-container my-4">
          <div className="mr-3 flex-self-center">
            <RiErrorWarningFill size={20} className="d-block" />
          </div>
          <div className="flex-self-center">
            {labels.accessErrorMessage}&nbsp;
            <a href="mailto:support@atroposhealth.com">{labels.email}</a>
          </div>
        </div>
        <ButtonCustom
          cssClass="close-btn color-white"
          onClick={() => {
            history.push(urlConstantsEnum.LIBRARY_DASHBOARD);
            dispatch(getorderByGuidError({ error: undefined }));
          }}
          buttonAction="Error Modal closed button clicked"
          id="btn-error-modal-closed-button"
        >
          <VscChromeClose />
        </ButtonCustom>
      </div>
    </CustomModal>
  );
}

export default Unauthorized;
