import React, {useEffect} from 'react';
import OrderRoutes from 'routes/OrderRoutes';
import { getQuestionTypes, resetLocalData } from 'redux/modules/orderDetails/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { requestGetUserProfile } from 'redux/modules/userProfile/actions';
import { getRawToken } from '../utils/validations';
import AuthLoader from '../components/AuthLoader';

const OrderDashboard = () => {
  const { getIdTokenClaims } = useAuth0();
  const { isLoading } = useSelector(
    ({ userProfile }) => userProfile,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetLocalData());
    const getQuestions = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getQuestionTypes({
          token: token ? token.__raw : '',
        }),
      );
    };
    getQuestions();
  }, [dispatch]);

  return (
    isLoading ? (
      <AuthLoader fullScreen={false} />
    ) : (   
      <OrderRoutes />
    )
  );
};
export default OrderDashboard;
