import React, { useRef } from 'react';
import { StyledTogglePanel } from './StyledPanel';
import { ReactComponent as ChevronDown } from 'assets/images/ChevronDown.svg';
import { ReactComponent as ChevronUp } from 'assets/images/ChevronUp.svg';
import { useMixpanel } from 'react-mixpanel-browser';

function ToggleablePanel({
  children,
  header,
  collapsed = true,
  expandIcon = <ChevronDown />,
  collapseIcon = <ChevronUp />,
  panelClassName = '',
  ...props
}) {
  const ref = useRef(null);
  const mixpanel = useMixpanel();

  function handleExpand() {
    if (mixpanel) {
      const actionName = header + ' ' + 'expanded';
      mixpanel.track(actionName, {
        action: 'expanded',
        action_value: header,
      });
    }
  }

  function handleCollapse() {
    if (mixpanel) {
      const actionName = header + ' ' + 'collapsed';
      mixpanel.track(actionName, {
        action: 'collapsed',
        action_value: header,
      });
    }
  }

  function handleClick() {
    if (ref?.current) {
      const isOpen = ref.current.getContent();

      if (!isOpen) {
        const actionName = header + ' ' + 'expanded';
        mixpanel.track(actionName, {
          action: 'expanded',
          action_value: header,
        });
      } else {
        const actionName = header + ' ' + 'collapsed';
        mixpanel.track(actionName, {
          action: 'collapsed',
          action_value: header,
        });
      }
      ref.current.toggle();
    }
  }

  return (
    <StyledTogglePanel
      className={`ah-toggle-panel ${panelClassName}`}
      expandIcon={expandIcon}
      collapseIcon={collapseIcon}
      ref={ref}
      header={<div onClick={handleClick}>{header}</div>}
      toggleable
      collapsed={collapsed}
      onExpand={handleExpand}
      onCollapse={handleCollapse}
      {...props}
    >
      {children}
    </StyledTogglePanel>
  );
}

export default ToggleablePanel;
