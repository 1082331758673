import React from 'react';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { StudyForm } from '../StudyDetailsForm';
import { useDispatch } from 'react-redux';
import { savePicoOptions } from 'redux/modules/orderDetails/actions';
import { labels } from 'constants/labels';

function ManuallyEnterStudyParameters() {
  const dispatch = useDispatch();
  const {
    dispatchOrderPageAction,
    handleButtonAction,
    token,
    ordersPageData: { chatRWD },
  } = useOrdersPagedata();

  function handleSubmit(values) {
    handleButtonAction({ key: aiButtonsEnum.MANUALLY_SAVE_PICO });
    dispatch(
      savePicoOptions({
        params: {
          study_design: chatRWD?.selectedStudyFormat,
          study_format: {
            ...values,
          },
        },
        token,
      }),
    );
  }

  function handleCancel() {
    dispatchOrderPageAction({
      type: 'order/setChatRWDData',
      payload: {
        formValues: {},
        selectedStudyFormat: labels.comparativeEffectiveness,
        visibleFields: ['population', 'cohort_1', 'cohort_2', 'outcomes'],
        nextFieldIndex: 3,
        isManualInputMode: false,
      },
    });
  }

  return (
    <StudyForm
      title={labels.placeholderCopy}
      onFormSubmit={handleSubmit}
      onCancel={handleCancel}
      cancelBtnId="cancel-manually-enter-study-parameters-btn"
      cancelBtnAction="Manually Enter Study Parameters form closed"
      saveBtnId="save-manually-enter-study-parameters"
      saveBtnAction="Save Manually Enter Study Parameters button clicked"
    />
  );
}

export default ManuallyEnterStudyParameters;
