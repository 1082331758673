import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getDownloadLink } from 'redux/modules/orderDetails/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const FileDownloadUtil = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();

  const redirectToLogin = () => {
    history.push(urlConstantsEnum.LOGIN);
  };

  const getDownloadUrl = async (orderGuid, resource) => {
    const token = await getIdTokenClaims();
    dispatch(
      getDownloadLink({
        token: token ? token.__raw : '',
        guid: orderGuid,
        resource: resource,
        tokenExpiredCallback: redirectToLogin,
      }),
    );
  };
  return { getDownloadUrl };
};
export default FileDownloadUtil;
