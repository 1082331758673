import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Close } from 'assets/images/Close.svg';
import { useToken } from 'hooks/useToken';
import { useSessionStorageState } from 'hooks/useSessionStorageState';

function ViewAsBanner() {
  const dispatch = useDispatch();
  const token = useToken();
  const [viewAsSession, setViewAsSession] = useSessionStorageState({}, 'viewAsSession');
  const { user } = viewAsSession;

  function handleClose() {
    setViewAsSession({});
    window.location.href = '/dashboard';
  }

  if (viewAsSession && (user?.id || user?.email)) {
    return (
      <div
        className="d-flex flex-justify-center align-items-center w-100 py-1 px-2"
        style={{
          background: 'var(--bg-gray-200)',
          position: 'relative',
          zIndex: 20,
        }}
      >
        <span className="txt size-15">{viewAsSession?.message}</span>
        <Close className="d-flex mt-1 cursor-pointer" onClick={handleClose} />
      </div>
    );
  }
  return null;
}

export default ViewAsBanner;
