export const dateFormat = (date) => {
  var d = new Date(date);
  Date.shortMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  var monthShort = Date.shortMonths[d.getMonth()],
    day = '' + d.getDate(),
    hours = d.getHours(),
    minutes = d.getMinutes(),
    ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm.toUpperCase();

  var fullDate = monthShort + ' ' + day + ' ' + d.getFullYear();

  return fullDate;
};

export const dateFormatmmddyyyy = (date) => {
  var newDate = new Date(date);
  return (
    (newDate.getMonth() > 8 ? newDate.getMonth() + 1 : '0' + (newDate.getMonth() + 1)) +
    '/' +
    (newDate.getDate() > 9 ? newDate.getDate() : '0' + newDate.getDate()) +
    '/' +
    newDate.getFullYear()
  );
};

export const dateFormatyyyymmdd = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const formatDateForDisplay = (timestamp) => {
  const date = new Date(timestamp);
  const today = new Date();

  if (date.toDateString() === today.toDateString()) {
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    return `Today ${formattedHours}:${minutes}${ampm}`;
  } else {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }
};

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};
