import React from 'react';
import { Dropdown } from 'components/dropdown';
import { useSelector } from 'react-redux';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';

function StudyFormats() {
  const { studyDetails } = useSelector(({ orderDetails }) => orderDetails);
  const {
    ordersPageData: { chatRWD },
    dispatchOrderPageAction,
  } = useOrdersPagedata();
  const { selectedStudyFormat } = chatRWD;

  const options =
    studyDetails && studyDetails.length
      ? studyDetails.map((format) => ({
          label: format.label,
          value: format.value,
        }))
      : [];

  function handleChange(event) {
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'selectedStudyFormat',
        value: event.value,
      },
    });
  }

  return (
    <div className="d-flex flex-column gap-8">
      <div className="txt size-14 w-700 lh-22 text-black-900">Study Format</div>
      <Dropdown
        placeholder="Select"
        options={options}
        value={selectedStudyFormat}
        onChange={handleChange}
        dropdownname="Study Format"
      />
    </div>
  );
}

export default StudyFormats;
