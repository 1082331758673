import CustomModal from 'components/CustomModal';
import React from 'react';
import { useOrdersPagedata } from '../OrderPageContext';
import { useDispatch } from 'react-redux';
import { Strings } from 'constants/Strings';
import { ButtonCustom } from 'components/form-input/Button';
import { stopBackgroundSync } from 'redux/modules/orderDetails/actions';

function ConfirmStartOverModal() {
  const dispatch = useDispatch();
  const {
    ordersPageData: { chatRWD },
    dispatchOrderPageAction,
    handleReset,
  } = useOrdersPagedata();

  function handleStartOver() {
    dispatch(stopBackgroundSync());
    handleReset();
  }

  return (
    <CustomModal
      isOpen={chatRWD?.hasClickedOnStartOver}
      cssClass="white-overlay"
      modalname="Start Over modal"
      overlayCssClass={'phi-modal-overlay'}
      handleClose={() => {}}
    >
      <div className="email-modal p-5 delete-modal cancel-request-confirmation-modal">
        <div className="profile-sub-header mt-4 mb-2">{Strings.startOver}</div>
        <div className="cancel-sub-header mt-4">{Strings.confirmationMessage}</div>
        <div className="d-md-flex flex-justify-end">
          <ButtonCustom
            cssClass="plain-btn mt-5 px-4"
            onClick={() =>
              dispatchOrderPageAction({
                type: 'order/updateChatRWDField',
                payload: {
                  field: 'hasClickedOnStartOver',
                  value: false,
                },
              })
            }
            buttonAction={'continue chatting button clicked'}
            actionLabel={'continue chatting'}
            id={'btn-continue-chatting-button'}
          >
            {Strings.continueChattingModalButton}
          </ButtonCustom>
          <ButtonCustom
            cssClass="btn-primary mt-2 mt-md-5 px-4 ml-md-3"
            onClick={handleStartOver}
            buttonAction="Start Over button clicked"
            actionLabel="Start Over"
            id="btn-start-over-button"
          >
            {Strings.startOverModalButton}
          </ButtonCustom>
        </div>
      </div>
    </CustomModal>
  );
}

export default ConfirmStartOverModal;
