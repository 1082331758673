import React from 'react';
import styles from './tooltip.module.scss';

function Tooltip({ tooltipIcon, name = '', tooltiptext, cssClassName, children }) {
  return (
    <div className={`${styles.tooltip} ${cssClassName} cursor-pointer`}>
      {tooltipIcon}
      <span className={styles.tooltiptext}>{children}</span>
    </div>
  );
}

export default Tooltip;
