export const converBase64toLink = (file) => {
  if (file) {
    try {
      const binaryString = window.atob(file);
      const bytes = new Uint8Array(binaryString.length);
      const convertedFile = bytes.map((byte, i) => binaryString.charCodeAt(i));
      if (convertedFile) {
        var newFile = new Blob([convertedFile], { type: 'image/png' });
        var fileURL = URL.createObjectURL(newFile);
        return fileURL;
      }
    } catch (err) {
      return false;
    }
  }
};
