import React from 'react';

const AuthLoader = ({ fullScreen }) => {
  return (
    <div
      className={`authloader bg-white d-flex flex-items-center flex-justify-center${
        fullScreen === true ? ' position-fixed left-0 right-0 top-0 bottom-0' : ''
      }`}
    >
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default AuthLoader;
