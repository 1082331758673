import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AuthLoader from 'components/AuthLoader';

const Auth = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    !isAuthenticated &&
      !isLoading &&
      loginWithRedirect({
        redirectUri: `${window.location.origin}`,
        appState: { returnTo: window.location.pathname },
      });
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return <AuthLoader fullScreen={true} />;
};
export default Auth;
