import React from 'react';
import {
  StyledFrame,
  StyledFrameWrapper,
  StyledFrameAside,
  StyledFrameBody,
  StyledFrameFooter,
} from './StyledFrame';
import { ReactComponent as AhAvatar } from 'assets/images/AhAvatar.svg';

function Frame({ children, ...props }) {
  return (
    <StyledFrame {...props}>
      <StyledFrameWrapper>{children}</StyledFrameWrapper>
    </StyledFrame>
  );
}

function Aside({ icon = <AhAvatar /> }) {
  return <StyledFrameAside>{icon}</StyledFrameAside>;
}

function Body({ children, ...props }) {
  return <StyledFrameBody {...props}>{children}</StyledFrameBody>;
}

function Footer({ children }) {
  return <StyledFrameFooter>{children}</StyledFrameFooter>;
}

Frame.Aside = Aside;
Frame.Body = Body;
Frame.Footer = Footer;

export default Frame;
