import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Home from 'pages/Home';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useMixpanel } from 'react-mixpanel-browser';

const AppRoutes = () => {
  let { path } = useRouteMatch();
  const mixpanel = useMixpanel();
  const { userData } = useSelector(({ userProfile }) => userProfile);
  const [isAuthorised, setAuthorised] = useState(false);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (userData) {
      userData.emails?.forEach((emailObj) => {
        if (emailObj.email === userData.last_login_email) {
          if (emailObj.verification_status === 'Verified') {
            setAuthorised(true);
          } else {
            setAuthorised(false);
          }
        }
      });
    }
  }, [userData]);
  useEffect(() => {
    mixpanel.track('Screen path changed event', {
      screen_path: window.location.pathname,
    });
  }, []);

  return !isAuthorised || isAuthenticated? (
    <Switch>
      <Route exact path={path}>
        <Home />
      </Route>
      <Route path={`${path}/`}>
        <Redirect to={path} />
      </Route>
    </Switch>
  ) : (
    <Redirect to="/" />
  );
};

export default AppRoutes;
