import React from 'react';
import { ButtonCustom } from 'components/form-input/Button';
import { AiFillLock } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import AuthLoader from 'components/AuthLoader';


const UserUnverifiedTemplate = ({
  resendEmailVerification,
  verification_status,
  sendEmailSuccessMessage,
  sendEmailVerificationError,
}) => {
  const {
    isSendEmailVerificationStart,
  } = useSelector(({ userProfile }) => userProfile);

  const onClickOfButton = () => {
      resendEmailVerification();
  };

  return isSendEmailVerificationStart ? (
    <AuthLoader fullScreen={true} />
  ) : (
    <div className="d-flex col-12 flex-justify-center flex-items-center">
      <div className="unverified-outer-container d-flex flex-items-start">
        <div className="d-flex flex-column flex-items-center email-inner-container">
          <div className="email-error-icon mb-6 d-flex flex-items-center flex-justify-center">
            <AiFillLock />
          </div>
          <div>
            <div className="mt-3">
              Your email verification is pending or expired, please verify your email
              address.
            </div>
            <ButtonCustom
              onClick={() => onClickOfButton()}
              cssClass={`link mt-3`}
              id={'btn-resend-email-button'}
              buttonAction={'Resend email verification button clicked'}
            >
              Resend Email Verification
            </ButtonCustom>
          </div>
          {!!sendEmailSuccessMessage ? (
            <div className="color-success error-text mt-3">{sendEmailSuccessMessage}</div>
          ) : (
            ''
          )}
          {!!sendEmailVerificationError ? (
            <div className="color-error error-text mt-3">
              {sendEmailVerificationError}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
export default UserUnverifiedTemplate;
