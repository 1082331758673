import React from 'react';
import { Strings } from 'constants/Strings';
import { useDispatch, useSelector } from 'react-redux';
import { setPhenotypeData } from 'redux/modules/orderDetails/actions';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { InputNumber } from 'components/form/inputNumber';
import { Dropdown } from 'components/dropdown';

const outcomePeriodOptions = [
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Months',
    value: 'months',
  },
  {
    label: 'Years',
    value: 'years',
  },
];

function OutcomePeriod({ entity, entityRowIndex, phenotype }) {
  const dispatch = useDispatch();
  const {
    dispatchOrderPageAction,
    ordersPageData: { chatRWD },
  } = useOrdersPagedata();

  const {
    phenotypeResponse: { phenotypes },
  } = useSelector(({ orderDetails }) => orderDetails);
  const {
    outcome_min_value = 3,
    outcome_max_value = 1,
    outcome_min_type = 'days',
    outcome_max_type = 'years',
  } = entity;
  const isWorkflowDisabled = chatRWD?.isPhenotypeFinalized;

  const handleSelect = ({ event, type }) => {
    const phenotypeData = structuredClone(phenotypes);
    const outcomePhenotype = phenotypeData?.[phenotype]?.entities?.[entityRowIndex];
    if (outcomePhenotype) {
      const outcomeValues = {
        ...outcomePhenotype,
        [type]: event?.value,
      };
      phenotypeData[phenotype].entities[entityRowIndex] = outcomeValues;
      dispatch(setPhenotypeData({ phenotypeData, phenotype: undefined }));
    }
  };

  function handleChange({ event, name }) {
    const { value } = event;

    if (value === 0) {
      if (chatRWD.isGeneralStudyParamVisible) {
        dispatchOrderPageAction({
          type: 'order/updateChatRWDField',
          payload: {
            field: 'isGeneralStudyParamVisible',
            value: false,
          },
        });
      }
    }

    const phenotypeData = structuredClone(phenotypes);
    const outcomePhenotype = phenotypeData?.[phenotype]?.entities?.[entityRowIndex];
    if (outcomePhenotype) {
      const outcomeValues = {
        ...outcomePhenotype,
        [name]: value,
      };
      phenotypeData[phenotype].entities[entityRowIndex] = outcomeValues;
      dispatch(setPhenotypeData({ phenotypeData }));
    }
  }

  return (
    <div className="d-flex flex-column gap-15" id="custom-textinput-container">
      <div className="d-flex flex-column gap-10">
        <div className="txt size-15 w-600 lh-28 form-input-header">
          Outcome time period
        </div>
        <div className="custom-textinput-container input-wrapper">
          <div className="d-xl-flex gap-50">
            <div>
              <label
                className="mb-1 txt size-14 w-500 lh-20 font-inter primary-1"
                htmlFor="start"
              >
                Start
              </label>
              <div className="d-lg-flex align-items-center gap-20">
                <div className="input-1 ">
                  <InputNumber
                    value={outcome_min_value}
                    onChange={(event) =>
                      handleChange({ event, name: 'outcome_min_value' })
                    }
                    border="1px solid var(--border--muted-green-1)"
                    borderradius="6px"
                    padding="3px 13px 3px 12px;"
                    height="35px"
                    min={0}
                    background="var(--bg-white)"
                    disabled={isWorkflowDisabled}
                  />
                </div>

                <div className="input-conatainer">
                  <div className="input-2">
                    <Dropdown
                      placeholder="Select"
                      options={outcomePeriodOptions}
                      value={outcome_min_type}
                      onChange={(event) =>
                        handleSelect({ event, type: 'outcome_min_type' })
                      }
                      dropdownname="Follow up start period"
                      border="1px solid var(--border--muted-green-1)"
                      background="var(--bg-white)"
                      padding="7px"
                      disabled={isWorkflowDisabled}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <label
                className="mb-1 txt size-14 w-500 lh-20 font-inter primary-1"
                htmlFor="start"
              >
                End
              </label>
              <div className="d-lg-flex align-items-center gap-20">
                <div className="input-1 ">
                  <InputNumber
                    value={outcome_max_value}
                    onChange={(event) =>
                      handleChange({ event, name: 'outcome_max_value' })
                    }
                    border="1px solid var(--border--muted-green-1)"
                    borderradius="6px"
                    padding="6px"
                    min={0}
                    background="var(--bg-white)"
                    disabled={isWorkflowDisabled}
                  />
                </div>

                <div className="input-conatainer">
                  <div className="input-2">
                    <Dropdown
                      placeholder="Select"
                      options={outcomePeriodOptions}
                      value={outcome_max_type}
                      onChange={(event) =>
                        handleSelect({ event, type: 'outcome_max_type' })
                      }
                      dropdownname="Follow up max period"
                      border="1px solid var(--border--muted-green-1)"
                      background="var(--bg-white)"
                      padding="7px"
                      disabled={isWorkflowDisabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(outcome_max_value <= 0 || !outcome_max_value) && (
            <div className="color-error error-text">{Strings.outcomePeriodError}</div>
          )}
          {(outcome_min_value === false ||
            outcome_min_value === null ||
            outcome_min_value === undefined ||
            outcome_min_value === '') && (
            <div className="color-error error-text">
              Please enter Follow up start period
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OutcomePeriod;
