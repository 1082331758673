import React from 'react';
import CustomModal from './CustomModal';
import { ButtonCustom } from './form-input/Button';
import { VscChromeClose } from 'react-icons/vsc';
import {subscriptionEnum} from '../constants/enum/subscriptionEnum';
import { Strings } from '../constants/Strings';

const TutorialVideoModal = ({ isOpen, handleClose, subscriptionType }) => {
  const showModalTextBasedOnSubscription = () => {
    switch (subscriptionType) {
      case subscriptionEnum.FREE:
        return (
          <>
            <div className="video-title mb-4 mt-2">
              {Strings.freeSubscriptionVideoHeaderText}
            </div>
            <ul>
              <li>
                <strong>2 Free Prognostograms</strong>
              </li>
              <li>
                Access to a preview of the <strong>Evidence Library</strong>
              </li>
            </ul>
          </>
        );
        break;
      case subscriptionEnum.STANDARD:
        return (
          <>
            <div className="video-title mb-4 mt-2">
              {Strings.standardSubscriptionVideoHeaderText}
            </div>
            <div>{Strings.standardSubscriptionVideoSubText}</div>
          </>
        );
        break;
      default:
      case subscriptionEnum.ENTERPRISE:
        return (
          <>
            <div className="video-title mb-4 mt-2">
              {Strings.enterpriseSubscriptionVideoHeaderText}
            </div>
            <strong>{Strings.enterpriseSubscriptionVideoSubText}</strong>
            <ul>
              <li>{Strings.enterpriseSubscriptionVideoSubText1}</li>
              <li>{Strings.enterpriseSubscriptionVideoSubText2}</li>
            </ul>
          </>
        );
        break;
    }
  };
  return (
    <CustomModal
      isOpen={isOpen}
      handleClose={handleClose}
      cssClass="white-overlay"
      modalname="Tutorial video modal"
      overlayCssClass={'tutorial-video-modal-overlay'}
    >
      <div className={`tutorial-video-modal text-center d-flex flex-justify-between`}>
        <ButtonCustom
          cssClass="close-btn"
          onClick={() => handleClose()}
          buttonAction={'Tutorial video modal closed button clicked'}
          id={'btn-tutorial-video-close-button'}
        >
          <VscChromeClose />
        </ButtonCustom>
        <div className="d-flex w-100">
          <div className="col-md-4 d-md-block d-none video-bg p-4">
            <div className="video-bg-title d-flex flex-items-center px-4">
              Welcome to <br />
              Atropos
              <br />
              Health
            </div>
          </div>
          <div className="col-md-8 text-left px-4 video-col-2 pb-3 pt-4">
            {showModalTextBasedOnSubscription()}
            <div className="my-4">
              To learn more about how to order a Prognostogram,{' '}
              <strong>click on the demo below</strong>!
            </div>
            <div className="mb-5 ifrme-top">
              <iframe
                src="https://demo.arcade.software/ak9X4jzXJLtiinpkqPht?embed"
                frameBorder="0"
                loading="lazy"
                webkitallowfullscreen={1}
                mozallowfullscreen={1}
                allowFullScreen={1}
              ></iframe>
            </div>
            <div>
              To learn more about the different subscription plans we offer,{' '}
              <a
                href="https://atroposhealth.helpscoutdocs.com/article/21-subscriptions"
                target="_blank"
                id={'click-here-link'}
              >
                click here
              </a>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};
export default TutorialVideoModal;
