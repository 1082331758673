import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import RequestPrognostogram from 'pages/RequestPrognostogram';
import QuestionTypeForm from 'pages/QuestionTypeForm';
import ClinicalQuestionForm from 'pages/ClinicalQuestionForm';
import OrderSuccessModal from 'components/OrderSuccessModal';
import { useAuth0 } from '@auth0/auth0-react';
import Auth from '../pages/Auth';
import Order from 'pages/order/Order';

const OrderRoutes = () => {
  const { isAuthenticated } = useAuth0();
  let { path } = useRouteMatch();

  return (
    <>
      {isAuthenticated ? (
        <Switch>
          <Route exact path={[path, `${path}/edit/:guid`, `${path}/reorder/:guid`]}>
            <Order />
          </Route>
          <Route exact path={`${path}/submitted`}>
            <OrderSuccessModal />
          </Route>
        </Switch>
      ) : (
        <Switch>
          <Route exact path={[path, `${path}/edit/:guid`, `${path}/reorder/:guid`]}>
            <Auth />
          </Route>
          <Route exact path={`${path}/submitted`}>
            <OrderSuccessModal />
          </Route>
          <Route
            exact
            path={[
              `${path}/questions-type`,
              `${path}/questions-type/edit/:guid`,
              `${path}/questions-type/reorder/:guid`,
            ]}
          >
            <QuestionTypeForm />
          </Route>
          <Route
            exact
            path={[
              `${path}/clinical-question`,
              `${path}/clinical-question/edit/:guid`,
              `${path}/clinical-question/reorder/:guid`,
            ]}
          >
            <ClinicalQuestionForm />
          </Route>
        </Switch>
      )}
    </>
  );
};

export default OrderRoutes;
