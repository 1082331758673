import React, { useEffect, useState } from 'react';
import { useOrdersPagedata } from '../../pages/order/OrderPageContext';
import { ImInfo } from 'react-icons/im';
import { useSelector } from 'react-redux';
import { TextInput } from 'components/form-input';
import DatePicker from 'react-datepicker';
import Tooltip from 'components/tooltip/Tooltip';
import { isValidDate } from 'utils/dateFormats';
import { Strings } from 'constants/Strings';
import { dateFormatmmddyyyy } from 'utils/dateFormats';
import { Dropdown } from 'components/dropdown';

function MotivationOptions() {
  const [ShouldShowBirthdayError, setShouldShowBirthdayError] = useState(false);
  const { motivations, singleOrderData } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const { usersConfig } = useSelector(({ userProfile }) => userProfile);
  const { ordersPageData, dispatchOrderPageAction } = useOrdersPagedata();
  const { errorsObject, selectedProviderMotivation, patientInfo } = ordersPageData;
  const [patientDatePickerIsOpen, setPatientDatePickerIsOpen] = useState(false);
  const { motivations: ordersMotivations, patient } = singleOrderData || {};

  useEffect(() => {
    if (ordersMotivations && ordersMotivations.length) {
      const presentMotivations = ordersMotivations.find((motivation) => motivation.value);
      if (presentMotivations) {
        dispatchOrderPageAction({
          type: 'order/setSelectedProviderMotivation',
          payload: presentMotivations?.category,
        });
      }

      if (patient && Object.keys(patient).length !== 0) {
        dispatchOrderPageAction({
          type: 'order/patientInfo',
          payload: {
            field: 'patientBirthDate',
            value: dateFormatmmddyyyy(patient.birth_date),
          },
        });
        dispatchOrderPageAction({
          type: 'order/patientInfo',
          payload: {
            field: 'patientLastName',
            value: patient.last_name,
          },
        });
      }
    }
  }, [ordersMotivations, patient]);

  const handleDateChange = (date) => {
    dispatchOrderPageAction({
      type: 'order/patientInfo',
      payload: { field: 'patientBirthDate', value: date },
    });
    setPatientDatePickerIsOpen(false);

    if (!date) {
      dispatchOrderPageAction({
        type: 'order/setFormErrorByKey',
        payload: {
          key: 'patientBirthDate',
          error: Strings.patientBirthDateError,
        },
      });
    }

    if (errorsObject['patientBirthDate']) {
      dispatchOrderPageAction({
        type: 'order/setFormErrorByKey',
        payload: {
          key: 'patientBirthDate',
          error: '',
        },
      });
    }
  };

  const handleChange = (value, name) => {
    dispatchOrderPageAction({
      type: 'order/patientInfo',
      payload: { field: name, value: value },
    });
    setPatientDatePickerIsOpen(false);

    if (errorsObject[name])
      dispatchOrderPageAction({
        type: 'order/setFormErrorByKey',
        payload: {
          key: name,
          error: '',
        },
      });

    if (!value) {
      dispatchOrderPageAction({
        type: 'order/setFormErrorByKey',
        payload: {
          key: 'patientLastName',
          error: Strings.patientLastNameError,
        },
      });
    }
  };

  const handleClickonOption = (option) => {
    if (!option?.value) {
      dispatchOrderPageAction({
        type: 'order/setFormErrorByKey',
        payload: {
          key: 'motivation',
          error: Strings.motivationError,
        },
      });
    }

    if (errorsObject?.motivation && option?.value) {
      dispatchOrderPageAction({
        type: 'order/setFormErrorByKey',
        payload: {
          key: 'motivation',
          error: '',
        },
      });
    }

    dispatchOrderPageAction({
      type: 'order/setSelectedProviderMotivation',
      payload: option?.value,
    });
  };

  function motivationRequiresPatientData(providerMotivation) {
    return providerMotivation && providerMotivation === 'Patient Care';
  }

  return (
    <div>
      <div className="advanced-setting-title mb-3">
        <div className="d-flex align-items-center">
          <div className="txt size-18 w-600">
            What is the motivation for this request?
          </div>
        </div>
      </div>
      {motivations && motivations.length ? (
        <div className="mb-3">
          <Dropdown
            placeholder="Select Answer"
            showClear
            options={(motivations || []).map((motivation) => ({
              label: motivation.category,
              value: motivation.category,
            }))}
            value={selectedProviderMotivation}
            invalid={errorsObject?.motivation ? true : false}
            onChange={handleClickonOption}
            dropdownname="PG motivation"
          />

          {errorsObject.motivation ? (
            <div style={{ color: '#DB0028', fontSize: '11px', marginTop: '3px' }}>
              {errorsObject.motivation}
            </div>
          ) : (
            ''
          )}
        </div>
      ) : null}
      {selectedProviderMotivation &&
        motivationRequiresPatientData(selectedProviderMotivation) &&
        usersConfig?.configuration?.prognostogram?.motivations?.patient_care && (
          <div className="mb-4">
            <div className="d-flex align-items-center mb-2">
              <div className="txt size-18 w-600">Patient’s Information</div>
              <Tooltip
                tooltipIcon={<ImInfo fontSize={18} className="pt-1" />}
                cssClassName="ml-2 d-flex"
                name="Patient’s Information"
              >
                We take your patient’s Protected Health Information (PHI) seriously and
                have implemented infrastructure to comply with HIPAA and general privacy
                practices. See more on our policy{' '}
                <a className="text-white" href="https://www.atroposhealth.com/HIPAA">
                  here
                </a>
              </Tooltip>
            </div>
            <div className="col-md-6 pr-1 mb-4">
              <div className="input-label mb-2">Last Name</div>
              <TextInput
                isError={errorsObject.patientLastName && !patientInfo?.patientLastName}
                errorText={errorsObject.patientLastName}
                value={patientInfo?.patientLastName}
                placeholder="Last Name"
                isFloatingLabel={false}
                onChange={(e) => handleChange(e.target.value, 'patientLastName')}
                id={'textInput-first-name'}
              />
            </div>

            <div className="input-label mb-1">Date of Birth</div>
            <div className="col-md-6 pr-1 mb-4">
              <TextInput
                isError={
                  errorsObject.patientBirthDate &&
                  !isValidDate(new Date(patientInfo?.patientBirthDate))
                }
                errorText={errorsObject.patientBirthDate}
                value={patientInfo?.patientBirthDate}
                placeholder="MM/DD/YYYY"
                isFloatingLabel={false}
                className={`${ShouldShowBirthdayError ? 'border-error' : ''}`}
                onFocus={() => {
                  setPatientDatePickerIsOpen(true);
                }}
                onChange={(e) => {
                  setPatientDatePickerIsOpen(false);
                  let value = e.target.value;
                  const numberOfSlashes = value.split('/').length - 1;

                  const needsSlashAfterDay =
                    value.replace(/[^0-9]/g, '').length == 2 && numberOfSlashes < 1;
                  const needSlashAfterMonth =
                    numberOfSlashes == 1 &&
                    value.split('/')[1].replace(/[^0-9]/g, '').length == 2;
                  if (
                    (needsSlashAfterDay || needSlashAfterMonth) &&
                    patientInfo?.patientBirthDate.slice(-1) != '/'
                  ) {
                    value += '/';
                  }
                  handleDateChange(value);
                }}
                id={'datepicker-birthdate'}
              />
              {patientDatePickerIsOpen && (
                <DatePicker
                  inline
                  className={`${ShouldShowBirthdayError ? 'border-error' : ''}`}
                  onChange={(date) => {
                    handleDateChange(date.toLocaleDateString('en-US'));
                  }}
                  id={'datepicker-birthdate-picker'}
                />
              )}
            </div>

            {ShouldShowBirthdayError ? (
              <div className="color-error error-text">{orderFormErrorStr.birthDate}</div>
            ) : (
              ''
            )}
          </div>
        )}
    </div>
  );
}

export default MotivationOptions;
