import React, { useEffect, useState } from 'react';
import { ButtonPrimary } from 'components/form-input/Button';
import RadioButton from 'components/form-input/RadioButton';
import { useHistory } from 'react-router-dom';
import OrderPrognostogramHeader from 'components/OrderPrognostogramHeader';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderFormData } from 'redux/modules/orderDetails/actions';
import AuthLoader from 'components/AuthLoader';
import orderHeadereEnum from '../constants/enum/orderHeadereEnum';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import { useMixpanel } from 'react-mixpanel-browser';
import { getRawToken } from '../utils/validations';
import { requestGetUserProfile } from 'redux/modules/userProfile/actions';
import { useAuth0 } from '@auth0/auth0-react';

const QuestionTypeForm = () => {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  let history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const { questionData, isOrderLoading, getQuestionError, orderFormData } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const [questionTypeValue, setQuestionTypeValue] = useState('');
  const [questionName, setQuestionName] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (questionData && questionData?.length !== 0) {
      if (orderFormData?.question_type) {
        setQuestionName(orderFormData?.question_type);
        questionData?.map((question) => {
          if (question.name === orderFormData?.question_type) {
            setQuestionTypeValue(question.guid);
          }
        });
      } else {
        setQuestionTypeValue(questionData[0].guid);
        setQuestionName(questionData[0].name);
      }
    }
  }, [orderFormData, questionData]);

  const setQuestionValue = (e) => {
    setQuestionTypeValue(e.target.value);
    questionData?.map((question) => {
      if (question.guid === e.target.value) {
        setQuestionName(question.name);
        mixpanel.track('Question type selected event', {
          selected_question_name: question.name,
        });
      }
    });
  };

  let orderProfileFormData = {
    question_type: questionName,
    firstName: orderFormData?.firstName,
    lastName: orderFormData?.lastName,
    email: orderFormData?.email,
    clinicalQuestion: orderFormData?.clinicalQuestion,
    previous_order_guid: orderFormData?.previous_order_guid,
    questionTitle: orderFormData?.questionTitle,
    population: orderFormData?.population,
    intervention: orderFormData?.intervention,
    control: orderFormData?.control,
    outcome: orderFormData?.outcome,
    timeframe: orderFormData?.timeframe,
    picotSwitch: orderFormData?.picotSwitch,
    orderStatus: orderFormData?.orderStatus,
    isEditOrder: orderFormData?.isEditOrder,
    institute_name: orderFormData?.institute_name,
    institute_guid: orderFormData?.institute_guid,
    data_source: orderFormData?.data_source,
    sharing_type: orderFormData?.sharing_type,
    otherOrganization: orderFormData?.otherOrganization,
    physicianInstitution: orderFormData?.physicianInstitution,
    urgencyType: orderFormData?.urgencyType,
    phiDisclaimer: orderFormData?.phiDisclaimer,
    documentFileName: orderFormData?.documentFileName,
  };

  useEffect(() => {
    if (orderFormData?.previous_order_guid && orderFormData?.isEditOrder) {
      setUrl('/edit/' + orderFormData?.previous_order_guid);
    } else if (orderFormData?.previous_order_guid && !orderFormData?.isEditOrder) {
      setUrl('/reorder/' + orderFormData?.previous_order_guid);
    } else {
      setUrl('');
    }
  }, []);

  const submitQuestionType = () => {
    dispatch(setOrderFormData(orderProfileFormData));
    history.push(urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_THREE + url);
  };

  const onClickOfBackBtn = () => {
    history.push(urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_ONE + url);
  };

  useEffect(() => {
    document.addEventListener('keypress', checkIfAnyKeyIsPressed);
    return () => {
      document.removeEventListener('keypress', checkIfAnyKeyIsPressed);
    };
  });

  const checkIfAnyKeyIsPressed = (e) => {
    if (questionTypeValue) {
      if (e.charCode === 13) {
        submitQuestionType();
      }
    }
  };

  useEffect(() => {
    const getProfileData = async () => {
      const token = await getIdTokenClaims();
      if (token) {
        dispatch(
          requestGetUserProfile({
            token: getRawToken(token),
          }),
        );
      }
    };
    getProfileData();
  }, []);

  return (
    <div className="order-container">
      <OrderPrognostogramHeader
        headerArray={[
          { name: orderHeadereEnum.PROFILE_INFO, isActive: false, isCompleted: true },
          { name: orderHeadereEnum.QUESTION_TYPE, isActive: true, isCompleted: false },
          {
            name: orderHeadereEnum.CLINICAL_QUESTION,
            isActive: false,
            isCompleted: false,
          },
        ]}
        onClickOfBackBtn={onClickOfBackBtn}
        headerText={
          orderFormData?.previous_order_guid && orderFormData?.isEditOrder
            ? 'Edit Order'
            : orderFormData?.previous_order_guid && !orderFormData?.isEditOrder
            ? 'Modify and Reorder'
            : 'Request a Prognostogram'
        }
      />
      <div className="order-section-container question-type-container">
        {isOrderLoading ? (
          <div className="position-relative">
            <AuthLoader fullScreen={false} />
          </div>
        ) : (
          <div>
            {!!getQuestionError ? (
              <div className="text-center color-error error-text">{getQuestionError}</div>
            ) : (
              questionData?.map((question, index) => (
                <div
                  style={{ backgroundColor: question.style.backgroundColor }}
                  className="question-radio-btn my-2 my-md-3"
                  key={index}
                >
                  <RadioButton
                    buttonName={question.name}
                    checked={questionTypeValue === question.guid ? true : false}
                    onClick={setQuestionValue}
                    name={'questionType'}
                    value={question.guid}
                    desc={question.description}
                    id={`RadioButton-${question.name}`}
                  />
                </div>
              ))
            )}
            <ButtonPrimary
              label="Confirm and ask clinical question"
              isDisabled={false}
              onClick={submitQuestionType}
              buttonAction={'Select question type button clicked'}
              id={'btn-select-question-type'}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default QuestionTypeForm;
