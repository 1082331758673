import axios from 'axios';

export const fetchUserViewAsAccess = async ({ token, params }) => {
  return axios({
    method: 'post',
    url: `/view-as/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};
