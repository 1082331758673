import Highlighter from 'react-highlight-words';

export const highlightText = (context, text) => {
  return (
    <Highlighter
      highlightClassName="highlight-red"
      searchWords={text}
      autoEscape={true}
      textToHighlight={context}
    />
  );
};
