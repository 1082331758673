import { subscription } from 'api/';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  INITIATE_USER_SUBSCRIPTION,
  INITIATE_USER_SUBSCRIPTION_START,
  INITIATE_USER_SUBSCRIPTION_COMPLETE,
  INITIATE_USER_SUBSCRIPTION_ERROR,
  GET_STRIPE_CONFIG,
  GET_STRIPE_CONFIG_START,
  GET_STRIPE_CONFIG_COMPLETE,
  GET_STRIPE_CONFIG_ERROR,
  GET_SUBSCRIPTION_DATASET,
  GET_SUBSCRIPTION_DATASET_START,
  GET_SUBSCRIPTION_DATASET_COMPLETE,
  GET_SUBSCRIPTION_DATASET_ERROR,
} from './actions';
import orderFormErrorStr from '../../../constants/errorStrings';
import * as userSubscriptionAction from './actions';

export function* getStripeConfig({ payload: { token, tokenExpiredCallback } }) {
  try {
    yield put({
      type: GET_STRIPE_CONFIG_START,
    });
    const { response, error } = yield call(subscription.getStripeConfig, {
      token,
    });

    if (!!response) {
      yield put({
        type: GET_STRIPE_CONFIG_COMPLETE,
        payload: { data: response },
      });
    } else {
      if (error.status === 401) {
        tokenExpiredCallback();
      }
      {
        yield put(
          onGetStripeConfigError({
            error: error?.data.message
              ? error?.data.message
              : orderFormErrorStr.commonError,
          }),
        );
      }
    }
  } catch (error) {
    yield put(
      onGetStripeConfigError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* initiateUserSubscription({
  payload: { token, successCallback, tokenExpiredCallback },
}) {
  try {
    yield put({
      type: INITIATE_USER_SUBSCRIPTION_START,
    });
    const { response, error } = yield call(subscription.initiateUserSubscription, {
      token,
    });

    if (!!response) {
      yield put({
        type: INITIATE_USER_SUBSCRIPTION_COMPLETE,
        payload: { data: response },
      });
      successCallback?.();
    } else {
      if (error.status === 401) {
        tokenExpiredCallback();
      }
      {
        yield put(
          onGetStripeConfigError({
            error: error?.data.message
              ? error?.data.message
              : orderFormErrorStr.commonError,
          }),
        );
      }
    }
  } catch (error) {
    yield put(
      onInitiateSubscriptionError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getSubscriptionDataset({ payload: { token, guid } }) {
  try {
    yield put({
      type: GET_SUBSCRIPTION_DATASET_START,
    });
    const { response, error } = yield call(subscription.getSubscriptionDataSet, {
      token,
      guid,
    });
    if (!!response) {
      yield put({
        type: GET_SUBSCRIPTION_DATASET_COMPLETE,
        payload: { data: response.data },
      });
    } else {
      yield put(
        onGetSubscriptionDatasetError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onGetSubscriptionDatasetError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* getChatRWDDataset({ payload: { token, guid, product_case_type } }) {
  try {
    yield put({
      type: userSubscriptionAction.GET_CHATRWD_DATASET_START,
    });
    const { response, error } = yield call(subscription.getSubscriptionDataSet, {
      token,
      guid,
      product_case_type,
    });
    if (!!response) {
      yield put({
        type: userSubscriptionAction.GET_CHATRWD_DATASET_COMPLETE,
        payload: response.data,
      });
    } else {
      yield put({
        type: userSubscriptionAction.GET_SUBSCRIPTION_DATASET_ERROR,
        payload: error?.data.message
          ? error?.data.message
          : orderFormErrorStr.commonError,
      });
    }
  } catch (error) {
    yield put({
      type: userSubscriptionAction.GET_SUBSCRIPTION_DATASET_ERROR,
      payload: orderFormErrorStr.commonError,
    });
  }
}

const onGetSubscriptionDatasetError = (payload = {}) => ({
  type: GET_SUBSCRIPTION_DATASET_ERROR,
  payload,
});

const onInitiateSubscriptionError = (payload = {}) => ({
  type: INITIATE_USER_SUBSCRIPTION_ERROR,
  payload,
});

const onGetStripeConfigError = (payload = {}) => ({
  type: GET_STRIPE_CONFIG_ERROR,
  payload,
});

function* watchGetStripeConfig() {
  yield takeLatest(GET_STRIPE_CONFIG, getStripeConfig);
}

function* watchInitiateUserSubscription() {
  yield takeLatest(INITIATE_USER_SUBSCRIPTION, initiateUserSubscription);
}

function* watchGetSubscriptionDataset() {
  yield takeLatest(GET_SUBSCRIPTION_DATASET, getSubscriptionDataset);
}

function* watchGetChatRWDDataset() {
  yield takeLatest(userSubscriptionAction.GET_CHATRWD_DATASET, getChatRWDDataset);
}

export const sagas = [
  watchGetStripeConfig,
  watchInitiateUserSubscription,
  watchGetSubscriptionDataset,
  watchGetChatRWDDataset,
];
