import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleCancelOrderModal } from 'redux/modules/orderDetails/actions';
import CustomModal from './CustomModal';
import { ButtonCustom } from './form-input/Button';
import { VscChromeClose } from 'react-icons/vsc';
import { Strings } from 'constants/Strings';

function CancelOrderModal({ cancelOrder }) {
  const dispatch = useDispatch();
  const { orderGuidToCancel } = useSelector(({ orderDetails }) => orderDetails);

  const handleCancelOrder = (guid) => {
    dispatch(handleCancelOrderModal(null));
    cancelOrder(guid);
  };

  return (
    <CustomModal
      isOpen={orderGuidToCancel ? true : false}
      handleClose={() => dispatch(handleCancelOrderModal(null))}
      cssClass="white-overlay"
      modalname="Order cancel modal"
      overlayCssClass={'cancel-modal-overlay'}
    >
      <div className="email-modal p-5 delete-modal text-center">
        <ButtonCustom
          cssClass="close-btn"
          onClick={() => dispatch(handleCancelOrderModal(null))}
          buttonAction={'Close cancel modal button clicked'}
          id={'btn-cancel-modal-close-button'}
        >
          <VscChromeClose />
        </ButtonCustom>
        <div className="profile-sub-header mt-4 mb-2">{Strings.confirmation_message}</div>
        <div className="cancel-sub-header mt-4">{Strings.order_cancellation_message}</div>
        <div className="d-flex flex-justify-between">
          <ButtonCustom
            cssClass="plain-btn mt-5"
            onClick={() => handleCancelOrder(orderGuidToCancel)}
            buttonAction={'Cancel order button clicked'}
            actionLabel={'Order is canceled'}
            id={'btn-cancel-order-button'}
          >
            {Strings.cancel_order_action}
          </ButtonCustom>
          <ButtonCustom
            cssClass="btn-primary mt-5 px-4"
            onClick={() => dispatch(handleCancelOrderModal(null))}
            buttonAction={'Order keep it button clicked'}
            actionLabel={'Order does not cancel'}
            id={'btn-keep-it-button'}
          >
            {Strings.Keep_it_action}
          </ButtonCustom>
        </div>
      </div>
    </CustomModal>
  );
}

export default CancelOrderModal;
