import React from 'react';
import { StyledCheckbox } from './StyledCheckbox';
import { useMixpanel } from 'react-mixpanel-browser';

function Checkbox({ className, onChange, ...props }) {
  const mixpanel = useMixpanel();
  const { checkboxname } = props;

  function handleChange(e) {
    let buttonAction;
    if (e.checked) {
      buttonAction = checkboxname + ' ' + 'checked';
    } else {
      buttonAction = checkboxname + ' ' + 'unchecked';
    }

    mixpanel.track(buttonAction, {
      action: buttonAction,
      action_value: checkboxname,
    });

    onChange(e);
  }

  return (
    <StyledCheckbox
      className={`ah-checkbox ${className}`}
      onChange={handleChange}
      {...props}
    />
  );
}

export default Checkbox;
