import React from 'react';
import Table from 'components/Table';
import TableHeader from './TableHeader';
import OutcomephenotypeTable from './OutcomephenotypeTable';
import styles from './phenotype.module.scss';

function OutcomePhenotypelayout({ phenotype, data, onDelete }) {
  const entities = data[phenotype]?.entities || [];

  return (
    <div id="Phenotype-table" className={styles.tableResponsive}>
      <Table>
        <Table.TableHead>
          <Table.Row>
            <TableHeader phenotype={phenotype} />
          </Table.Row>
        </Table.TableHead>
        <Table.TableBody>
          {entities &&
            entities.length > 0 &&
            entities.map((entity, index) => (
              <OutcomephenotypeTable
                key={`outcome-entity-${phenotype}-row-${index}`}
                entity={entity.phenotypes}
                phenotype={phenotype}
                curEntity={entity}
                onDelete={(codeSet) =>
                  onDelete({ ...codeSet, entityIndex: index, phenotype })
                }
                entityRowIndex={index}
              />
            ))}
        </Table.TableBody>
      </Table>
    </div>
  );
}

export default OutcomePhenotypelayout;
