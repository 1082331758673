import React from 'react';

const Checkbox = ({
  idValue,
  classNameValue,
  buttonName,
  checked,
  onChange,
  name,
  value,
  id,
  disabled,
}) => {
  const handleOnChange = (e) => {
    !!onChange && onChange(e);
  };
  return (
    <div className={`checkbox-input  ${classNameValue ? classNameValue : ''} `}>
      <label>
        <input
          id={idValue}
          type="checkbox"
          name={name}
          value={value}
          className="mr-3"
          checked={checked}
          onChange={handleOnChange}
          id={id}
          disabled={disabled}
        />
        {buttonName}
      </label>
    </div>
  );
};

export default Checkbox;
