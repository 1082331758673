import React from 'react';
import { OrderPageProvider } from './OrderPageContext';
import OrderpageLayout from './OrderpageLayout';

function Order() {
  return (
    <OrderPageProvider>
      <OrderpageLayout />
    </OrderPageProvider>
  );
}

export default Order;
