import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AiOutlineMinusCircle,
  AiOutlinePlusSquare,
  AiOutlineRightCircle,
} from 'react-icons/ai';
import Table from 'components/Table';
import Codeset from './Codeset';
import styles from './phenotype.module.scss';
import { Overlay } from 'components/overlaypanel';
import Tag from 'components/tag/Tag';
import { labels } from 'constants/labels';
import { useMixpanel } from 'react-mixpanel-browser';

function PICphenotypeTable({ entity, phenotype, onDelete, curEntity }) {
  const [expandRow, setExpandRow] = useState(false);
  const mixpanel = useMixpanel();

  const {
    phenotypeResponse: { phenotypes },
  } = useSelector(({ orderDetails }) => orderDetails);

  const isComparativeEffectiveness =
    phenotype == labels.intervention || phenotype == labels.control;
  const colspan = !isComparativeEffectiveness ? 5 : 6;

  const isTagsPresent = curEntity?.tags?.length;
  const phenotypeName = curEntity?.phenotypes[0]?.entity_result ?? '';

  const isError = entity?.some(
    ({ code_set }) =>
      code_set.length === 0 ||
      code_set?.some(
        ({ concept_description }) =>
          concept_description === labels.unableToReturnPhenotypeDescription,
      ),
  );

  function handleRenderContent(tags) {
    return (
      <div className="d-flex flex-column gap-7">
        <div className="txt size-12">Tags</div>
        <div className="d-flex flex-wrap gap-7">
          {tags.map((tag, index) => (
            <Tag
              key={`${phenotype}-${curEntity?.entity_name
                .split(' ')
                .join('-')}-tag-${tag}-${index}`}
              tagName={tag}
            />
          ))}
        </div>
      </div>
    );
  }

  // const phenotypeName =
  //   entity && entity.length && entity[0].code_set.length
  //     ? entity[0]?.code_set[0]?.concept_name
  //     : '-';

  function handleExpandRow({ entity }) {
    mixpanel.track(
      `Phenotype Table: ${entity} Row ${expandRow ? 'collapsed' : 'Expanded'}`,
      {
        'Tab Name': phenotype,
        'Entity Name': entity,
        'User Action': expandRow ? 'collapse' : 'Expand',
      },
    );

    setExpandRow((expand) => !expand);
  }

  function handleDelete(codeSet) {
    mixpanel.track(
      `Phenotype code set ${codeSet?.code?.concept_name} - ${codeSet?.code?.concept_code} deleted`,
      {
        'Tab Name': phenotype,
        'Entity Name': curEntity?.entity_name,
        'Code Vocabulary': codeSet?.code?.vocabulary,
        'User Action': 'Delete',
      },
    );
    onDelete(codeSet);
  }

  return entity && entity.length
    ? entity.map((entityPhenotype, index) => (
        <React.Fragment
          key={`pic-entity-${curEntity?.entity_name.split(' ').join('-')}-${index}`}
        >
          <Table.Row className={`${expandRow ? styles.activeRow : ''}`}>
            <Table.TableData>
              {!isError ? (
                <div
                  className="d-flex gap-7 cursor-pointer align-items-center cursor-pointer"
                  onClick={() => handleExpandRow({ entity: curEntity?.entity_name })}
                >
                  {expandRow ? (
                    <AiOutlineMinusCircle className="flex-shrink-0" size={20} />
                  ) : (
                    <AiOutlineRightCircle className="flex-shrink-0" size={20} />
                  )}
                  {curEntity?.entity_name}
                </div>
              ) : (
                curEntity?.entity_name
              )}
            </Table.TableData>
            <Table.TableData>
              {!isError ? (
                phenotypeName
              ) : (
                <span className="color-error">{labels.failedToGeneratePhenotype}</span>
              )}
            </Table.TableData>
            <Table.TableData>{!isError && entityPhenotype.source}</Table.TableData>

            <Table.TableData>
              {isTagsPresent ? (
                <Overlay title={<AiOutlinePlusSquare size={18} />}>
                  {handleRenderContent(curEntity?.tags)}
                </Overlay>
              ) : (
                ''
              )}
            </Table.TableData>

            {isComparativeEffectiveness && (
              <Table.TableData>{curEntity?.subset ? 'TRUE' : 'FALSE'}</Table.TableData>
            )}
            <Table.TableData className="text-center">
              {!isError && entityPhenotype.code_set?.length}
            </Table.TableData>
          </Table.Row>

          {expandRow && (
            <Codeset
              onDelete={(codeSet) => handleDelete(codeSet)}
              colspan={colspan}
              codeset={entityPhenotype.code_set}
              phenotype={phenotype}
            />
          )}
        </React.Fragment>
      ))
    : null;
}

export default PICphenotypeTable;
