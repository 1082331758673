import React from 'react';
import StyledInputTextarea from './StyledInputTextarea';

function InputTextarea({
  rows = 9,
  autoResize = true,
  value = '',
  onChange,
  placeholder,
  ...props
}) {
  return (
    <StyledInputTextarea
      className="ah-texarea"
      rows={rows}
      autoResize={autoResize}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      {...props}
    />
  );
}

export default InputTextarea;
