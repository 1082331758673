import styled from 'styled-components';
import { Button } from 'primereact/button';

const StyledButton = styled(Button)`
  &.ah-button {
    font-size: 15px;
    color: var(--color-black-opacity-60);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: ${(props) => props.padding || '14px 15px'};
    background: var(--primary-bg-gradient);
    border-radius: var(--border-radius-10);
    color: var(--color-white);
    border: 1px solid var(--border-dark-teal);

    &.p-button-outlined {
      border-radius: ${(props) => props.borderRadius || 'var(--border-radius-lg)'};
      border: ${(props) =>
        props.border || '1px solid var(--border-on-surface-medium-emphasis)'};
      background: ${(props) => props.backgroundColor || 'var(--bg-white)'};
      color: ${(props) => props.color || 'var(--color-black-opacity-60)'};
      padding: ${(props) => props.padding || '14px 15px'};

      :not(:disabled):hover {
        background: var(--primary-bg-gradient);
        color: var(--color-white);

        svg {
          path {
            fill: var(--color-white);
          }
        }
      }
    }

    &.p-button-secondary {
      background: ${(props) => props.backgroundColor || 'var(--bg-black-opacity-60)'};
    }

    &.p-button-text {
      background: transparent;
      color: var(--color-Neutral-700);
      border-color: transparent;
      padding: 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.4px;
    }

    svg {
      margin-right: 10px;
    }

    :disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export default StyledButton;
