export const validateAsText = (value) => {
  if (value !== undefined) {
  const newValue = value.trim();
  const reg = /^[A-Za-z]+(['\- A-Za-z]+)*/;
  return !!newValue && newValue.length !== 0 && reg.test(String(newValue));
  } else {
    return false;
  }
};

export const validateEmail = (value) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
};

export const getRawToken = (token) => {
  if (!token || !token?.__raw) {
    window.location.reload();
    return null;
  }
  return token.__raw;
};
