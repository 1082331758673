export const REQUEST_GET_USER_PROFILE = 'userProfile/requestGetUserProfile';
export const REQUEST_GET_USER_PROFILE_START = 'userProfile/requestGetUserProfileStart';
export const REQUEST_GET_USER_PROFILE_COMPLETE =
  'userProfile/requestGetUserProfileCompleted';
export const SET_USER_DATA = 'userProfile/setUserData';
export const SET_ERROR = 'userProfile/setError';
export const SET_LOADING = 'userProfile/setLoading';
export const RESET_DATA = 'userProfile/resetData';

export const UPDATE_PROFILE_INFO = 'userProfile/updateProfileInfo';
export const UPDATE_PROFILE_INFO_START = 'userProfile/updateProfileInfoStart';
export const UPDATE_PROFILE_INFO_COMPLETE = 'userProfile/updateProfileInfoCompleted';
export const UPDATE_PROFILE_INFO_ERROR = 'userProfile/updateProfileInfoError';

export const SEND_EMAIL_VERIFICATION = 'userProfile/sendEmailVerification';
export const SEND_EMAIL_VERIFICATION_START = 'userProfile/sendEmailVerificationStart';
export const SEND_EMAIL_VERIFICATION_COMPLETE =
  'userProfile/sendEmailVerificationCompleted';
export const SEND_EMAIL_VERIFICATION_ERROR = 'userProfile/sendEmailVerificationError';

export const EMAIL_VERIFICATION = 'userProfile/emailVerification';
export const EMAIL_VERIFICATION_START = 'userProfile/emailVerificationStart';
export const EMAIL_VERIFICATION_COMPLETE = 'userProfile/emailVerificationCompleted';
export const EMAIL_VERIFICATION_ERROR = 'userProfile/emailVerificationError';

export const SEND_PHONE_VERIFICATION = 'userProfile/sendPhoneVerification';
export const SEND_PHONE_VERIFICATION_START = 'userProfile/sendPhoneVerificationStart';
export const SEND_PHONE_VERIFICATION_COMPLETE =
  'userProfile/sendPhoneVerificationCompleted';
export const SEND_PHONE_VERIFICATION_ERROR = 'userProfile/sendPhoneVerificationError';

export const PHONE_VERIFICATION = 'userProfile/phoneVerification';
export const PHONE_VERIFICATION_START = 'userProfile/phoneVerificationStart';
export const PHONE_VERIFICATION_COMPLETE = 'userProfile/phoneVerificationCompleted';
export const PHONE_VERIFICATION_ERROR = 'userProfile/phoneVerificationError';

export const GET_PHYISICIAN_INSTITUTE_LIST = 'userProfile/getPhysicianInstituteList';
export const GET_PHYISICIAN_INSTITUTE_LIST_START =
  'userProfile/getPhysicianInstituteListStart';
export const GET_PHYISICIAN_INSTITUTE_LIST_COMPLETE =
  'userProfile/getPhysicianInstituteListCompleted';
export const GET_PHYISICIAN_INSTITUTE_LIST_ERROR =
  'userProfile/getPhysicianInstituteListError';

export const GET_PROFESSION_LIST = 'userProfile/getProfessionList';
export const GET_PROFESSION_LIST_START = 'userProfile/getProfessionListStart';
export const GET_PROFESSION_LIST_COMPLETE = 'userProfile/getProfessionListCompleted';
export const GET_PROFESSION_LIST_ERROR = 'userProfile/getProfessionListError';

export const CREATE_NEW_USER = 'userProfile/createNewUser';
export const CREATE_NEW_USER_START = 'userProfile/createNewUserStart';
export const CREATE_NEW_USER_COMPLETE = 'userProfile/createNewUserCompleted';
export const CREATE_NEW_USER_ERROR = 'userProfile/createNewUserError';

export const COMPLETE_PROFILE = 'userProfile/completeProfile';
export const COMPLETE_PROFILE_START = 'userProfile/completeProfileStart';
export const COMPLETE_PROFILE_COMPLETE = 'userProfile/completeProfileCompleted';
export const COMPLETE_PROFILE_ERROR = 'userProfile/completeProfileError';

export const GET_SPECIALTY_DROPDOWN_LIST = 'userProfile/getSpecialtyDropDownList';
export const GET_SPECIALTY_DROPDOWN_LIST_START =
  'userProfile/getSpecialtyDropDownListStart';
export const GET_SPECIALTY_DROPDOWN_LIST_COMPLETE =
  'userProfile/getSpecialtyDropDownListCompleted';
export const GET_SPECIALTY_DROPDOWN_LIST_ERROR =
  'userProfile/getSpecialtyDropDownListError';

export const CLEAR_PROFILE_ERROR = 'userProfile/clearProfileError';

export const SET_ONBOARDING_DATA = 'orderDetails/setOnboardingData';
export const SET_VIDEO_MODAL_DISPLAY = 'orderDetails/setVideoModalDisplay';

export const GET_REQUESTERS = 'orderDetails/getRequesters'
export const GET_REQUESTERS_START = 'orderDetails/getRequestersStart';
export const GET_REQUESTERS_COMPLETE = 'orderDetails/getRequestersCompleted';
export const GET_REQUESTERS_ERROR = 'ratorderDetailsing/getRequestersError';

export const GET_USERS_CONFIG = 'userProfile/getUsersConfig'
export const GET_USERS_CONFIG_START = 'userProfile/getUsersConfigStart';
export const GET_USERS_CONFIG_COMPLETE = 'userProfile/getUsersConfigCompleted';
export const GET_USERS_CONFIG_ERROR = 'userProfile/getUsersConfigError';
export const SET_GUEST_INSTITUTE_GUID = 'userProfile/setGuestInstituteGuid';

export const getUsersConfig = (payload = {}) => ({
  type: GET_USERS_CONFIG,
  payload,
});

export const createNewUser = (payload = {}) => ({
  type: CREATE_NEW_USER,
  payload,
});

export const getSpecialtyDropDownList = (payload = {}) => ({
  type: GET_SPECIALTY_DROPDOWN_LIST,
  payload,
});

export const completeProfile = (payload = {}) => ({
  type: COMPLETE_PROFILE,
  payload,
});

export const getProfessionList = (payload = {}) => ({
  type: GET_PROFESSION_LIST,
  payload,
});

export const getPhysicianInstituteList = (payload = {}) => ({
  type: GET_PHYISICIAN_INSTITUTE_LIST,
  payload,
});

export const sendPhoneVerification = (payload = {}) => ({
  type: SEND_PHONE_VERIFICATION,
  payload,
});

export const phoneVerification = (payload = {}) => ({
  type: PHONE_VERIFICATION,
  payload,
});

export const sendEmailVerification = (payload = {}) => ({
  type: SEND_EMAIL_VERIFICATION,
  payload,
});

export const emailVerification = (payload = {}) => ({
  type: EMAIL_VERIFICATION,
  payload,
});

export const updateProfileInfo = (payload = {}) => ({
  type: UPDATE_PROFILE_INFO,
  payload,
});

export const requestGetUserProfile = (payload = {}) => ({
  type: REQUEST_GET_USER_PROFILE,
  payload,
});

export const setUserData = (payload = []) => ({
  type: SET_USER_DATA,
  payload,
});

export const setError = (payload = {}) => ({
  type: SET_ERROR,
  payload,
});

export const setLoading = (payload = []) => ({
  type: SET_LOADING,
  payload,
});

export const resetData = (payload = []) => ({
  type: RESET_DATA,
  payload,
});

export const clearProfileError = (payload = {}) => ({
  type: CLEAR_PROFILE_ERROR,
  payload,
});

export const setOnboardingData = (payload = {}) => ({
  type: SET_ONBOARDING_DATA,
  payload,
});

export const setVideoModalDisplay = (payload = {}) => ({
  type: SET_VIDEO_MODAL_DISPLAY,
  payload,
});

export const getRequesters = (payload = {}) => ({
  type: GET_REQUESTERS,
  payload,
});

export const setGuestInstituteGuid = (payload = {}) => ({
  type: SET_GUEST_INSTITUTE_GUID,
  payload,
});
