import {
  INITIATE_USER_SUBSCRIPTION_START,
  INITIATE_USER_SUBSCRIPTION_COMPLETE,
  INITIATE_USER_SUBSCRIPTION_ERROR,
  GET_STRIPE_CONFIG_START,
  GET_STRIPE_CONFIG_COMPLETE,
  GET_STRIPE_CONFIG_ERROR,
  SET_STRIPE_ERROR,
  RESET_SUBSCRPTION_ERROR,
  RESET_STRIPE_REDIRECT_URL,
  GET_SUBSCRIPTION_DATASET_START,
  GET_SUBSCRIPTION_DATASET_COMPLETE,
  GET_SUBSCRIPTION_DATASET_ERROR,
} from './actions';
import * as userSubscriptionAction from './actions';

let initialState = {
  sessionData: undefined,
  chatRwdDataset: [],
  userSubscriptionErrors: undefined,
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_STRIPE_CONFIG_START:
      return {
        ...state,
        isShowLoading: true,
      };
    case GET_STRIPE_CONFIG_COMPLETE:
      return {
        ...state,
        stripeData: payload.data,
        isShowLoading: false,
      };
    case GET_STRIPE_CONFIG_ERROR:
      return {
        ...state,
        ...payload,
        isShowLoading: false,
      };
    case GET_SUBSCRIPTION_DATASET_START:
      return {
        ...state,
        isShowDatasetLoading: true,
      };
    case GET_SUBSCRIPTION_DATASET_COMPLETE:
      return {
        ...state,
        datasetList: payload.data,
        isShowDatasetLoading: false,
      };
    case GET_SUBSCRIPTION_DATASET_ERROR:
      return {
        ...state,
        ...payload,
        isShowDatasetLoading: false,
      };
    case INITIATE_USER_SUBSCRIPTION_START:
      return {
        ...state,
        subscriptionInitiationError: undefined,
        isShowSubscriptionLoading: true,
      };
    case INITIATE_USER_SUBSCRIPTION_COMPLETE:
      return {
        ...state,
        sessionData: payload.data,
        isShowSubscriptionLoading: false,
        subscriptionInitiationError: undefined,
      };
    case INITIATE_USER_SUBSCRIPTION_ERROR:
      return {
        ...state,
        ...payload,
        subscriptionInitiationError: payload.error,
        isShowSubscriptionLoading: false,
      };
    case RESET_SUBSCRPTION_ERROR:
      return {
        ...state,
        subscriptionInitiationError: undefined,
        ...payload,
      };
    case RESET_STRIPE_REDIRECT_URL:
      return {
        ...state,
        sessionData: undefined,
        ...payload,
      };
    case SET_STRIPE_ERROR:
      return {
        ...state,
        stripeError: payload,
        ...payload,
      };
    case userSubscriptionAction.GET_CHATRWD_DATASET_START:
      return {
        ...state,
        userSubscriptionErrors: {
          ...state.userSubscriptionErrors,
          chatRwdDatasetError: undefined,
        },
        chatRwdDataset: [],
        isLoadingChatRwdDataset: true,
      };
    case userSubscriptionAction.GET_CHATRWD_DATASET_COMPLETE:
      return {
        ...state,
        chatRwdDataset: payload,
        isLoadingChatRwdDataset: false,
      };
    case userSubscriptionAction.GET_CHATRWD_DATASET_ERROR:
      return {
        ...state,
        userSubscriptionErrors: {
          ...state.userSubscriptionErrors,
          chatRwdDatasetError: payload,
        },
        chatRwdDataset: [],
        isLoadingChatRwdDataset: false,
      };
    default:
      return state;
  }
};

export default reducer;
