import axios from 'axios';

export const getStripeConfig = async ({ token }) => {
  return axios({
    method: 'get',
    url: '/config/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const initiateUserSubscription = async ({ token }) => {
  return axios({
    method: 'get',
    url: '/create-checkout-session/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getSubscriptionDataSet = async ({
  token,
  guid,
  product_case_type = 'PG',
}) => {
  return axios({
    method: 'get',
    url: `/prognostograms/orders/datasources/?organization_guid=${guid}&product_case_type=${product_case_type}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};
