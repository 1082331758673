import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import { getRawToken } from 'utils/validations';

export function useToken() {
  const { getIdTokenClaims } = useAuth0();
  const [token, setToken] = useState(null);

  useEffect(() => {
    async function fetchToken() {
      try {
        const idToken = await getIdTokenClaims();
        const rawToken = getRawToken(idToken);
        setToken(rawToken);
      } catch (error) {
        console.error('Error fetching the token:', error);
      }
    }

    fetchToken();
  }, [getIdTokenClaims]);

  return token;
}
