import React from 'react';
import { Button } from 'components/button';
import { Frame } from 'components/Frame';
import { labels } from 'constants/labels';
import { ReactComponent as CheckGray } from 'assets/images/CheckGray.svg';

function SubmissionPrompt({ onClick, disabled }) {
  return (
    <>
      <Frame>
        <Frame.Aside></Frame.Aside>
        <Frame.Body>
          <p className="txt size-16 lh-24 gray-80">
            That’s it! Are you ready to submit your request?
          </p>
        </Frame.Body>
      </Frame>
      <Frame.Footer>
        <div className="flex-wrap flex-justify-end gap-10 d-md-flex">
          <Button
            onClick={onClick}
            outlined
            label={labels.confirmAndSendRequest}
            icon={<CheckGray />}
            disabled={disabled}
          />
        </div>
      </Frame.Footer>
    </>
  );
}

export default SubmissionPrompt;
