import React from 'react';
import Checkbox from 'components/form-input/Checkbox';
import RangeSlider from 'components/form-input/RangeSlider';
import { useDispatch, useSelector } from 'react-redux';
import { setPhenotypeData } from 'redux/modules/orderDetails/actions';
import { labels } from 'constants/labels';
import { handlePhenotypeValidationError } from 'redux/modules/orderDetails/actions';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { useMixpanel } from 'react-mixpanel-browser';

let initialState = {
  ageRange: {
    minValue: 18,
    maxValue: 90,
    min: 0,
    max: 100,
  },
};

function AgeRange({ phenotype }) {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const {
    phenotypeResponse: { phenotypes },
    phenotypeValidationsErrors,
  } = useSelector(({ orderDetails }) => orderDetails);

  const {
    dispatchOrderPageAction,
    ordersPageData: { chatRWD },
  } = useOrdersPagedata();

  const studyParams = phenotypes[phenotype]?.study_params;
  const isCheckboxDisabled =
    phenotype !== labels.population
      ? chatRWD?.isSexCheckboxchecked &&
        !Array.isArray(phenotypes[labels.population]?.study_params?.sexes)
      : false;

  const isWorkflowDisabled = chatRWD?.isPhenotypeFinalized;

  const validationErrors =
    phenotypeValidationsErrors && phenotypeValidationsErrors[phenotype];

  const populationStudyParams = phenotypes[labels.population]?.study_params;

  const { ageRange } = initialState;
  const {
    age_max = ageRange?.maxValue,
    age_min = ageRange.minValue,
    sexes = [],
  } = studyParams || {};

  const normalizedSexes = !sexes ? [] : typeof sexes === 'string' ? [sexes] : sexes;

  const ageMaxValue = age_max
    ? age_max === 'max'
      ? ageRange?.maxValue
      : age_max
    : ageRange?.maxValue;

  const errorMapping = {
    conflicting_ages: `Population and ${phenotype} are conflicting, please adjust the demographics in either section to remove the conflict.`,
    conflicting_sexes: `Population and ${phenotype} are conflicting, please adjust the demographics in either section to remove the conflict.`, // Same message for both
    no_sex_selected: 'Please select at least one sex.',
  };
  const uniqueErrorMessages = new Set();
  if (validationErrors && validationErrors.length) {
    if (
      validationErrors.includes('conflicting_ages') ||
      validationErrors.includes('conflicting_sexes')
    ) {
      uniqueErrorMessages.add(errorMapping['conflicting_ages']);
    }
    if (validationErrors.includes('no_sex_selected')) {
      uniqueErrorMessages.add(errorMapping['no_sex_selected']);
    }
  }

  const handleInputChange = (e, name) => {
    const { maxValue, minValue } = e;

    if (age_min !== e.minValue || ageMaxValue !== e.maxValue) {
      mixpanel.track('Age Range changed', {
        'Tab Name': phenotype,
        age_min: e.minValue,
        age_max: e.maxValue,
      });

      const phenotypeData = structuredClone(phenotypes);
      const studyParams = phenotypeData[phenotype].study_params || {};
      const updatedStudyParams = {
        ...studyParams,
        age_max: maxValue,
        age_min: minValue,
      };

      if (phenotype === labels.population) {
        for (const key in phenotypeData) {
          if (key === 'outcomes') continue;

          const currentSexes = phenotypeData[key]?.study_params?.sexes || [];

          if (key === labels.population) {
            phenotypeData[key].study_params = {
              ...phenotypeData[key].study_params,
              age_max: maxValue,
              age_min: minValue,
              sexes: currentSexes,
            };
          } else {
            const curStudyParams = phenotypeData[key]?.study_params || {};
            phenotypeData[key].study_params = {
              ...phenotypeData[key].study_params,
              age_max:
                maxValue < curStudyParams?.age_max ? maxValue : curStudyParams?.age_max,
              age_min:
                minValue < curStudyParams?.age_min ? curStudyParams?.age_min : minValue,
              sexes: currentSexes,
            };
          }
        }
      } else {
        phenotypeData[phenotype].study_params = updatedStudyParams;
      }

      if (validationErrors && validationErrors.includes(labels.conflicting_ages)) {
        const errorsArr = validationErrors.filter(
          (error) => error !== labels.conflicting_ages,
        );
        const payload = {
          [phenotype]: [...errorsArr],
        };
        dispatch(handlePhenotypeValidationError(payload));
      }

      dispatch(setPhenotypeData({ phenotypeData, phenotype: undefined }));
    }
  };

  function handleCheckboxChange(event) {
    const { value, checked, name } = event.target;

    mixpanel.track('Gender selection changed', {
      'Tab Name': phenotype,
      action: checked ? 'checked' : 'unchecked',
      value: name,
    });

    if (!chatRWD?.isSexCheckboxchecked && phenotype === labels.population) {
      dispatchOrderPageAction({
        type: 'order/updateChatRWDField',
        payload: {
          field: 'isSexCheckboxchecked',
          value: true,
        },
      });
    }

    const phenotypeData = structuredClone(phenotypes);
    const studyParams = phenotypeData[phenotype]?.study_params?.sexes || [];
    let updatedSexes;
    if (Array.isArray(studyParams)) {
      updatedSexes = checked
        ? [...studyParams, value]
        : studyParams.filter((v) => v !== value);
      updatedSexes = updatedSexes.length === 1 ? updatedSexes[0] : updatedSexes;
    } else {
      updatedSexes = checked ? (studyParams ? [studyParams, value] : value) : [];
    }

    if (phenotype === 'population') {
      for (const key in phenotypeData) {
        if (key === 'outcomes') continue;
        if (key === labels.population) {
          phenotypeData[key].study_params = {
            ...phenotypeData[key]?.study_params,
            sexes: updatedSexes,
          };
        } else {
          const curStudyParams = phenotypeData[key]?.study_params || {};
          const values = Array.isArray(updatedSexes)
            ? curStudyParams?.sexes
            : updatedSexes;

          phenotypeData[key].study_params = {
            ...phenotypeData[key]?.study_params,
            sexes: values,
          };
        }
      }
    } else {
      phenotypeData[phenotype].study_params = {
        ...phenotypeData[phenotype]?.study_params,
        sexes: updatedSexes,
      };
    }

    dispatch(setPhenotypeData({ phenotypeData }));

    if (validationErrors && validationErrors.includes(labels.no_sex_selected)) {
      const errorsArr = validationErrors.filter(
        (error) => error !== labels.no_sex_selected,
      );
      const payload = {
        [phenotype]: [...errorsArr],
      };
      dispatch(handlePhenotypeValidationError(payload));
    }

    if (validationErrors && validationErrors.includes(labels.conflicting_sexes)) {
      const errorsArr = validationErrors.filter(
        (error) => error !== labels.conflicting_sexes,
      );
      const payload = {
        [phenotype]: [...errorsArr],
      };
      dispatch(handlePhenotypeValidationError(payload));
    }
  }

  const min =
    phenotype === labels.population
      ? ageRange.min
      : populationStudyParams?.age_min || ageRange.min;
  const max =
    phenotype === labels.population
      ? ageRange.max
      : populationStudyParams?.age_max || ageRange.max;

  const sliderWidth =
    phenotype !== labels.population
      ? populationStudyParams?.age_max && populationStudyParams?.age_min
        ? populationStudyParams?.age_max - populationStudyParams?.age_min + '%'
        : '100%'
      : '100%';

  return (
    <>
      <div>
        <div className="input-heading-label">{labels.studyAgeRange}</div>
        <div className="d-flex align-items-center w-100 flex-nowrap">
          <div className="slider-part slider-left" style={{ width: `${min}%` }}></div>
          <RangeSlider
            cssclass={`${min !== 0 ? 'rm-left-radius' : ''} ${
              max !== 100 ? 'rm-right-radius' : ''
            }`}
            min={min}
            max={max}
            minValue={age_min}
            maxValue={ageMaxValue}
            name="ageRange"
            step={1}
            handleInputChange={handleInputChange}
            width={sliderWidth}
            disabled={isWorkflowDisabled}
          />

          <div
            className="slider-part slider-right"
            style={{ width: `${100 - max}%` }}
          ></div>
        </div>
      </div>
      <div></div>

      <div>
        <div className="input-heading-label mt-5">{labels.studySexSelection}</div>
        <ul className="my-2">
          <li>
            <Checkbox
              buttonName="Female"
              onChange={handleCheckboxChange}
              name="female"
              value="female"
              checked={normalizedSexes.includes('female')}
              id="checkbox-filter-female"
              disabled={isCheckboxDisabled || isWorkflowDisabled}
            />
          </li>
          <li>
            <Checkbox
              buttonName="Male"
              onChange={handleCheckboxChange}
              name="male"
              value="male"
              checked={normalizedSexes.includes('male')}
              id="checkbox-filter-male"
              disabled={isCheckboxDisabled || isWorkflowDisabled}
            />
          </li>
        </ul>
      </div>

      {validationErrors && (
        <div className="mt-4">
          <ul className="list list-disc pl-3">
            {Array.from(uniqueErrorMessages).map((errorMessage, index) => (
              <li
                key={errorMessage}
                className="color-error txt size-13 list-spacing lh-20"
              >
                {errorMessage}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default AgeRange;
