import { GET_ALL_NOTIFICATIONS_COMPLETE, GET_BADGE_NOTIFICATIONS_COMPLETE, READ_NOTIFICATION_COMPLETE, RESET_NOTIFICATION_COUNT_COMPLETE, RESET_NOTIFICATION_COUNT_START, SET_BADGE_NOTIFICATION_COUNT, SET_INTERVAL_TASK, SET_NOTIFICATIONS_LOADING, SET_NOTIFICATION_DROPDOWN_OPEN, SET_NOTIFICATION_GUID, SET_PAGE_NUMBER, TOGGLE_NOTIFICATIONS_DROPDOWN } from "./actions";

let initialState = {
	allNotificationList: {
		count: 0,
		notifications: []
	},
	orderStatusNotifications: [],
	isDropdownOpen: false,
	notificationGuid: undefined,
	pageNumber: 1,
	isNotificationLoading: false,
	badgeNotifications: [],
	badgeNotificationsCount: {},
	isNotificationDropdownOpen: false,
	isCountResetting: false,
	intervalTask: null
}

const reducer = (state = initialState, { payload, type }) => {
	switch (type) {

		case GET_ALL_NOTIFICATIONS_COMPLETE:
			return {
				...state,
				allNotificationList: payload,
			};

		case READ_NOTIFICATION_COMPLETE:
			const { notificationGuid } = payload;
			const notifications = state.allNotificationList.notifications.filter(notification => notification.guid !== notificationGuid);
			return {
				...state,
				allNotificationList: {
					...state.allNotificationList,
					notifications: notifications
				},
			};

		case SET_NOTIFICATION_DROPDOWN_OPEN:
			return {
				...state,
				isDropdownOpen: payload,
			};

		case RESET_NOTIFICATION_COUNT_COMPLETE:
			return {
				...state,
				allNotificationList: {
					...state.allNotificationList,
					...payload
				},
			}

		case SET_NOTIFICATION_GUID:
			return {
				...state,
				notificationGuid: payload
			}

		case SET_PAGE_NUMBER:
			return {
				...state,
				pageNumber: payload
			}
		case SET_NOTIFICATIONS_LOADING:
			return {
				...state,
				isNotificationLoading: payload
			}

		case GET_BADGE_NOTIFICATIONS_COMPLETE:
			return {
				...state,
				badgeNotifications: payload.length === 0 ? [] : [...state.badgeNotifications, ...payload]
			}

		case SET_BADGE_NOTIFICATION_COUNT:
			return {
				...state,
				badgeNotificationsCount: payload
			}

		case TOGGLE_NOTIFICATIONS_DROPDOWN:
			return {
				...state,
				isNotificationDropdownOpen: payload
			}

		case RESET_NOTIFICATION_COUNT_START:
			return {
				...state,
				isCountResetting: payload
			}

		case SET_INTERVAL_TASK:
			return {
				...state,
				intervalTask: payload
			}
		default:
			return state;
	}
}

export default reducer