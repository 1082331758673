export function constructQuestionFromStudyFormat(studyFormat) {
  if (studyFormat && Object.keys(studyFormat).length === 0) {
    return '';
  }
  const questionParts = [];
  const picoOrder = ['population', 'intervention', 'control', 'outcome', 'outcomes'];
  const additionalKeys = Object.keys(studyFormat).filter(
    (key) => !picoOrder.includes(key),
  );

  picoOrder.forEach((key) => {
    if (key in studyFormat && studyFormat[key]) {
      questionParts.push(
        `${key.charAt(0).toUpperCase() + key.slice(1)}: ${studyFormat[key]}`,
      );
    }
  });

  const populationIndex = questionParts.findIndex((part) =>
    part.startsWith('Population:'),
  );
  const outcomeIndex = questionParts.findIndex(
    (part) => part.startsWith('Outcome:') || part.startsWith('Outcomes:'),
  );

  const additionalParts = additionalKeys
    .map((key) => {
      if (studyFormat[key]) {
        return `${key.charAt(0).toUpperCase() + key.slice(1)}: ${studyFormat[key]}`;
      }
      return null;
    })
    .filter((part) => part !== null);

  if (populationIndex !== -1) {
    const insertIndex = populationIndex + 1;
    questionParts.splice(insertIndex, 0, ...additionalParts);
  } else {
    questionParts.unshift(...additionalParts);
  }

  const question = questionParts.join(', ');
  return question;
}

export const keyOrder = [
  'population',
  'intervention',
  'control',
  'cohort_1',
  'cohort_2',
  'cohort_3',
  'cohort_4',
  'cohort_5',
  'outcomes',
];
