import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useOrdersPagedata } from '../OrderPageContext';
import QuestionTitleHeader from './QuestionTitleHeader';
import {
  orderPrognostogram,
  resetOrderIndexData,
} from 'redux/modules/orderDetails/actions';
import ClinicalQuestionForm from '../../../features/chatRWD/ClinicalQuestionForm';
import GeneralStudyParameters from 'features/chatRWD/studyparameters/GeneralStudyparameters';
import { ManuallyEnterStudyParameters } from 'features/chatRWD/manuallyEnterStudyParameters';
import StudyDetails from 'features/chatRWD/studydetails/StudyDetails';
import { SkeletonLoader } from 'components/SkeletonLoader';
import MotivationSelect from 'features/chatRWD/MotivationSelect';
import DatasetSelect from 'features/chatRWD/DatasetSelect';
import SubmissionPrompt from 'features/chatRWD/SubmissionPrompt';
import CancelRequest from 'features/chatRWD/CancelRequest';
import PhenotypePage from 'features/chatRWD/phenotype/PhenotypePage';

const customSuggestionsButtons = {
  INCORPORATE_CHANGES: 'Incorporate Changes',
  KEEP_ORIGINAL: 'Keep Original',
  REGENERATE_SUGGESTIONS: 'Regenerate Suggestions',
  START_OVER: 'Start Over',
};

function ChartRwdOrder({ onOrderSuccessCallbackForChatrwd }) {
  const dispatch = useDispatch();

  const {
    ordersPageData: { chatRWD, loadingTitle, errorsObject },
    dispatchOrderPageAction,
    isChatRWDFormValid,
    orderPayloadData,
    handleButtonAction,
    token,
    hasZeroOutcomeMaxValue,
  } = useOrdersPagedata();
  const {
    isManualInputMode,
    isManualEnterFromPhenotype,
    hasWorkflowStarted,
    hasUserSelectedManualWorkflow,
    selectedSuggestionsOptions,
    questionTitle,
    initialClinicalQuestions,
    isPICOTFinalized,
    isPhenotypeFinalized,
    errors,
    selectedDataSourceOption,
    selectedProviderMotivation,
    generalStudyParameters,
    isStudyParamFinlized,
    hasMotivationSelectChanged,
  } = chatRWD;

  const {
    isOpenAiLoading,
    studyFormats,
    PICOGuid,
    phenotypeResponse,
    aIResponseErrors,
    orderPrognostogramError,
    picoSummaryQuestion,
    studyFormat,
    orderTaskStatusError,
    syncedPhenotype,
    isPhenotypeSyncing,
    isPhenotypeLoading,
  } = useSelector(({ orderDetails }) => orderDetails);

  const isPhenotypeResponse =
    phenotypeResponse && Object.keys(phenotypeResponse).length > 0;

  const chatRWDWorkflowErrors = {
    ...errors,
    firstName: errorsObject?.firstName,
    lastName: errorsObject?.lastName,
    email: errorsObject?.email,
  };

  const hasErrors = Object.values(chatRWDWorkflowErrors).some(
    (value) => value && value !== '',
  );

  const isSubmitButtonDisabled =
    !questionTitle ||
    !picoSummaryQuestion ||
    selectedDataSourceOption.length === 0 ||
    !selectedProviderMotivation ||
    !isPhenotypeFinalized ||
    (generalStudyParameters?.downsample === 'downsample' &&
      generalStudyParameters?.downsample_number <= 99) ||
    hasZeroOutcomeMaxValue ||
    generalStudyParameters?.min_history_value == null ||
    generalStudyParameters?.min_followup_value == null;

  function handleOptionClick(data) {
    handleButtonAction(data);
  }

  function handleSubmitOrder() {
    if (isChatRWDFormValid() && !hasErrors) {
      dispatch(resetOrderIndexData());
      dispatchOrderPageAction({
        type: 'order/seIsOrderSubmitted',
        payload: true,
      });

      const order = {
        ...orderPayloadData,
        order: {
          ...orderPayloadData?.order,
          initial_clinical_questions: initialClinicalQuestions,
          clinical_questions: picoSummaryQuestion,
          question_title: questionTitle,
          population: studyFormat?.population ?? '',
          intervention: studyFormat?.intervention ?? '',
          control: studyFormat?.control ?? '',
          outcome: studyFormat?.outcomes || studyFormat?.outcome || '',
          timeframe: studyFormat?.timeframe ?? '',
          product_case_type: 'ChatRWD',
          pico_guid: PICOGuid,
          general_study_params: {
            ...generalStudyParameters,
            downsample:
              generalStudyParameters?.downsample === 'downsample' ? true : false,
            downsample_number:
              generalStudyParameters?.downsample === 'downsample'
                ? generalStudyParameters?.downsample_number
                : null,
          },
          user_phenotypes: syncedPhenotype,
          data_source: selectedDataSourceOption,
        },
      };

      dispatch(
        orderPrognostogram({
          params: order,
          csrfToken: Cookies.get('csrftoken'),
          token: token || '',
          isChatrwd: true,
          navigateToDashboard: onOrderSuccessCallbackForChatrwd,
        }),
      );
    }
  }

  return (
    <>
      {!hasWorkflowStarted ? (
        !isManualInputMode ? (
          <ClinicalQuestionForm />
        ) : (
          <ManuallyEnterStudyParameters />
        )
      ) : (
        <>
          {!hasUserSelectedManualWorkflow && <QuestionTitleHeader />}
          {studyFormats &&
            studyFormats.length > 0 &&
            studyFormats?.map((studyFormat, index) => (
              <StudyDetails
                key={index}
                studyFormatObject={studyFormat}
                customButtons={customSuggestionsButtons}
                dispatch={dispatchOrderPageAction}
                onOptionClick={handleOptionClick}
                multiSelect={studyFormat?.multiSelect}
                selectedSuggestionsOptions={selectedSuggestionsOptions[index]}
                optionIndex={index}
              />
            ))}
          {isPICOTFinalized && !aIResponseErrors?.openAiPhenotypeError && (
            <MotivationSelect />
          )}
          {isPhenotypeResponse &&
            !aIResponseErrors?.openAiPhenotypeError &&
            !isManualEnterFromPhenotype &&
            hasMotivationSelectChanged && <PhenotypePage />}

          {isPhenotypeFinalized &&
            !aIResponseErrors?.syncedPhenotypeError &&
            !isPhenotypeSyncing && (
              <>
                <DatasetSelect />

                {selectedDataSourceOption.length > 0 && (
                  <div
                    className="ai-worflow-container"
                    data-testid="study-parameters-section"
                  >
                    <GeneralStudyParameters />
                    {isStudyParamFinlized && (
                      <SubmissionPrompt
                        onClick={handleSubmitOrder}
                        disabled={isSubmitButtonDisabled}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          {(isOpenAiLoading || isPhenotypeSyncing) && (
            <SkeletonLoader loadingTitle={loadingTitle} />
          )}
          {isPhenotypeLoading && hasMotivationSelectChanged && (
            <SkeletonLoader loadingTitle={loadingTitle} />
          )}
        </>
      )}

      {aIResponseErrors &&
        Object.keys(aIResponseErrors).map((key) => (
          <div key={key} className="color-error error-text">
            {aIResponseErrors[key]}
          </div>
        ))}

      <div className="my-md-6 my-2"></div>

      <div className="d-block d-md-none mt-4">
        <CancelRequest />
      </div>

      {hasErrors && (
        <ul className="ah-alert ah-alert-error list-bullet order-page-error-container mt-2">
          {Object.entries(chatRWDWorkflowErrors).map(
            ([key, value]) =>
              value && (
                <li
                  key={`${key}-${value}`}
                  className="color-error txt size-13 list-spacing"
                >
                  {value}
                </li>
              ),
          )}
        </ul>
      )}

      {!!orderPrognostogramError || !!orderTaskStatusError ? (
        <div className="color-error error-text mt-3">
          {orderPrognostogramError || orderTaskStatusError}
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default ChartRwdOrder;
