import React from 'react';
import Form from './Form';

function PicotOrder() {
  return (
    <div>
      <div className="study-protocol-text mb-3 mt-4">
        If you have a study protocol that you would like us to emulate, please include it
        using the Document Upload feature in the left bar.
      </div>
      <Form />
    </div>
  );
}

export default PicotOrder;
