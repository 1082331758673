import React from 'react';
import { useSelector } from 'react-redux';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { Strings } from 'constants/Strings';
import { Frame } from 'components/Frame';
import { GroupMultiSelect } from 'components/GroupMultiSelect';
import CustomTooltip from 'components/OverlayTooltip/CustomTooltip';
import { ImInfo } from 'react-icons/im';

function DatasetSelect() {
  const { chatRwdDataset: datasetList } = useSelector(
    ({ userSubscription }) => userSubscription,
  );
  const { singleOrderData } = useSelector(({ orderDetails }) => orderDetails);
  const { ordersPageData, dispatchOrderPageAction, isReorder, orderTypePath } =
    useOrdersPagedata();
  const {
    chatRWD: { selectedDataSourceOption, errors },
  } = ordersPageData;

  const groupedItemTemplate = (option) => {
    return (
      <div className="d-flex align-items-center gap-5">
        {option.name}
        <CustomTooltip content={<ImInfo />} info={option?.description} />
      </div>
    );
  };

  function handleChange(e) {
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'selectedDataSourceOption',
        value: e.value,
      },
    });

    if (e.value.length === 0) {
      dispatchOrderPageAction({
        type: 'order/setChatRWDErrorByKey',
        payload: {
          key: 'dataSourceSelectionError',
          error: Strings.dataSourceSelectionError,
        },
      });
    }

    if (e.value.length && errors.dataSourceSelectionError) {
      dispatchOrderPageAction({
        type: 'order/setChatRWDErrorByKey',
        payload: {
          key: 'dataSourceSelectionError',
          error: '',
        },
      });
    }
  }

  return (
    <>
      <Frame>
        <Frame.Aside></Frame.Aside>
        <Frame.Body>
          <div className="w-100">
            <div className="mb-3 txt size-16 gray-20 lh-24 intro-text">
              Select your dataset:
            </div>
          </div>

          <GroupMultiSelect
            placeholder="Select Answer"
            value={selectedDataSourceOption}
            options={datasetList || []}
            optionLabel="name"
            optionGroupLabel="name"
            optionGroupChildren="data_sources"
            onChange={handleChange}
            invalid={errors.dataSourceSelectionError ? true : false}
            disabled={
              singleOrderData?.guid &&
              singleOrderData?.status === 'Pending' &&
              orderTypePath === 'edit'
            }
            itemTemplate={groupedItemTemplate}
            dropdownname="ChatRWD Dataset"
          />
          {errors.dataSourceSelectionError && (
            <div className="color-error error-text">
              {errors.dataSourceSelectionError}
            </div>
          )}
        </Frame.Body>
      </Frame>
      <Frame.Footer></Frame.Footer>
    </>
  );
}

export default DatasetSelect;
