import React from 'react';
import RequestDetails from './RequestDetails';
import SubmissionOnBehalf from './SubmissionOnBehalf';
import MotivationOptions from './MotivationOptions';
import DatasourceOptions from './DatasourceOptions';
import Documentupload from './Documentupload';
import OtherSettings from './OtherSettings';
import { useOrdersPagedata } from '../../pages/order/OrderPageContext';
import CancelRequest from 'features/chatRWD/CancelRequest';

function Sidebar({ isSidebarHidden, toggleSidebar }) {
  const {
    ordersPageData: { questionTypeSelection },
  } = useOrdersPagedata();

  if (isSidebarHidden) {
    return null;
  }
  return (
    <aside className="order-left-section md-position-sticky left-section-wraper ">
      <div className="wrapper">
        <div className="header header-container d-flex justify-content-between">
          <div className="header-title">Request Details</div>
          <div className="collapse-btn position-static" onClick={toggleSidebar}>
            Hide
          </div>
        </div>
        <div className="content content-wrapper" id="content-wrapper">
          <div className="order-section-container clinical-question-container">
            <div className="d-flex flex-column gap-15">
              <RequestDetails />
              <SubmissionOnBehalf />
              {questionTypeSelection !== 3 && <MotivationOptions />}
              {questionTypeSelection !== 3 && <DatasourceOptions />}
              {questionTypeSelection !== 3 && <Documentupload />}
              {questionTypeSelection !== 3 && <OtherSettings />}
              {questionTypeSelection == 3 && (
                <div className="d-none d-md-block">
                  <CancelRequest />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
