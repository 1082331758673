import axios from 'axios';

export const getAllNotifications = async ({ token, userGuid, page, size }) => {
  return axios({
    method: 'get',
    url: `users/${userGuid}/notifications/?page=${page}&size=${size}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

	.then((response) => ({ response: response.data }))
	.catch((error) => ({ error: error.response }));
};

export const readNotification = async ({ token, userGuid, notificationGuid }) => {
  return axios({
    method: 'Put',
    url: `users/${userGuid}/notifications/${notificationGuid}/read/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

	.then((response) => ({ response: response.data }))
	.catch((error) => ({ error: error.response }));
};

export const resetNotificationCount = async ({ token, userGuid, params }) => {
  return axios({
    method: 'Put',
    url: `users/${userGuid}/notifications/reset-unread-count/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data:params
  })

	.then((response) => ({ response: response.data }))
	.catch((error) => ({ error: error.response }));
};