import {
  GET_SEARCH_SUGGESTION_START,
  GET_SEARCH_SUGGESTION_COMPLETE,
  GET_SEARCH_SUGGESTION_ERROR,
  CLEAR_SEARCH_TERM_DATA,
} from './actions';

let initialState = {
  waitingForSearchTerms: false,
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_SEARCH_SUGGESTION_START:
      return {
        ...state,
        getSearchTermsError: undefined,
        waitingForSearchTerms: true,
      };
    case GET_SEARCH_SUGGESTION_COMPLETE:
      return {
        ...state,
        getSearchTermsError: undefined,
        waitingForSearchTerms: false,
        searchTerms: payload.searchTerms,
      };
    case GET_SEARCH_SUGGESTION_ERROR:
      return {
        ...state,
        getSearchTermsError: payload.error,
        waitingForSearchTerms: false,
        ...payload,
      };
    case CLEAR_SEARCH_TERM_DATA:
      return {
        ...state,
        searchTerms: [],
        ...payload,
      };
    default:
      return state;
  }
};

export default reducer;
