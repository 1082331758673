import React from 'react';
import { useSelector } from 'react-redux';

function RequestDetails() {
  const { userData } = useSelector(({ userProfile }) => userProfile);
  const {
    first_name = '',
    email = '',
    last_name = '',
    institute = '',
    guid = '',
  } = userData || {};
  const instituteName = institute?.name || '';

  return (
    <div className="user-details">
      <div className="col-md-12 d-md-flex">
        <div className="col-6">
          <div className="user-details-label">First Name</div>
          <div className="user-details-value">{first_name}</div>
        </div>
        <div className="col-6">
          <div className="user-details-label">Last Name</div>
          <div className="user-details-value">{last_name}</div>
        </div>
      </div>
      <div className="col-md-12 mt-3">
        <div className="col-12 user-details-wrapper mb-3">
          <div className="user-details-label">Email</div>
          <div className="user-details-value">{email}</div>
        </div>
        <div className="col-12">
          <div className="user-details-label">Organization</div>
          <div className="user-details-value">{instituteName}</div>
        </div>
      </div>
    </div>
  );
}

export default RequestDetails;
