import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useMixpanel } from 'react-mixpanel-browser';
import AuthLoader from './AuthLoader';
import { ButtonCustom } from './form-input/Button';
import modalClose from '../assets/images/modal-close.png'

Modal.setAppElement('#root');

const ChatrwdOrderLoader = ({handleClose}) => {
  const mixpanel = useMixpanel();
  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    if (showModal) {
      mixpanel.track('Modal opened', {
        modal_name: 'New ChatRWD order creation success modal',
      });
    }
  }, [showModal]);

  const OnClickOfClose = () => {
    setShowModal(false);
    handleClose();
  };
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={OnClickOfClose}
      className="modal chatrwdModal"
      overlayClassName="modal-overlay"
    >
      <div className='d-flex justify-content-end'>
        <ButtonCustom
          cssClass="bg-white border-none cursor-pointer"
          onClick={() => OnClickOfClose()}
          buttonAction={'ChatRWD order creation success modal closed button clicked'}
          id={'btn-ChatRWD-success-modal-closed-button'}
        >
          <img src={modalClose} alt="close-icon" />
        </ButtonCustom>
      </div>
      <div className="modal-container">
        <div className="position-relative">
          <AuthLoader fullScreen={false} />
        </div>
        <div className="modal-outer-container">
          <div className="my-4 header-text-chat">Generating your Prognostogram!</div>
          <div className="sub-header-text-chat">
            Feel free to close this window or return to the{' '}
            <ButtonCustom
              cssClass={'chat-link'}
              onClick={() => OnClickOfClose()}
              buttonAction="Dashboard link clicked"
              id={'btn-dashboard-link'}
            >
              dashboard
            </ButtonCustom>
            {/* <ButtonLink
              buttonAction="Dashboard link clicked"
              id={'btn-dashboard-link'}
              route={urlConstantsEnum.DASHBOARD}
              cssClass={'chat-link'}
            >
              dashboard
            </ButtonLink> */}
            . Your Prognostogram will be in your Personal tab when it completes <span className='space-no-wrap'>(usually
            in 1-4 minutes).</span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChatrwdOrderLoader;
