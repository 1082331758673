import React, { useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import styled from 'styled-components';

const StylesOverlayPanel = styled(OverlayPanel)`
  background: #fff;
  max-width: ${(props) => props.maxwidth};
  min-width: ${(props) => props.minwidth};
  padding: ${(props) => props.padding};
  border-radius: ${(props) => props.radius};
  box-shadow: ${(props) => props.shadow};
  margin-top: ${(props) => props.margintop};
`;

StylesOverlayPanel.defaultProps = {
  maxwidth: '250px',
  minwidth: '75px',
  padding: '8px 10px',
  radius: '6px',
  shadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  margintop: '-5px',
};

const Overlay = ({ title, children }) => {
  const op = useRef(null);

  return (
    <div className="ah-overlaypanel">
      <div
        onClick={(e) => op.current.toggle(e)}
        aria-haspopup
        aria-controls="ah-overlay_panel"
      >
        {title}
      </div>

      <StylesOverlayPanel ref={op} id="ah-overlay_panel">
        {children}
      </StylesOverlayPanel>
    </div>
  );
};

export default Overlay;
