import React, { useRef } from 'react';
import StyledMultiSelect from './StyledMultiSelect';
import { ReactComponent as ChevronDown } from 'assets/images/ChevronDown.svg';
import { ReactComponent as InputCheck } from 'assets/images/InputCheck.svg';
import { useMixpanel } from 'react-mixpanel-browser';

function MultiSelect({
  onChange,
  options,
  dropdownIcon = <ChevronDown />,
  value = null,
  optionLabel,
  closeonclick = false,

  ...props
}) {
  const multiSelectRef = useRef(null);
  const mixpanel = useMixpanel();

  function handleShow() {
    const { dropdownname } = props;
    const action = dropdownname + ' ' + 'dropdown opened';
    mixpanel.track(action, {
      action: action,
      action_value: dropdownname,
    });
  }

  function handleHide() {
    const { dropdownname } = props;
    const action = dropdownname + ' ' + 'dropdown closed';
    mixpanel.track(action, {
      action: action,
      action_value: dropdownname,
    });
  }

  function handleChange(e) {
    const { value, selectedOption } = e;
    const isPresent = value.some((v) => v.name === selectedOption.name);
    let action;
    if (isPresent) {
      action = selectedOption.name + ' ' + 'option selected';
    } else {
      action = selectedOption.name + ' ' + 'option unselected';
    }

    mixpanel.track(action, {
      action: action,
      action_value: selectedOption.name,
    });

    if (closeonclick && multiSelectRef.current) {
      multiSelectRef.current.hide();
    }
    onChange(e);
  }

  return (
    <StyledMultiSelect
      ref={multiSelectRef}
      className="ah-dropdown"
      panelClassName="ah-dropdown-panel ah-multiselect-dropdown-panel"
      value={value}
      onChange={handleChange}
      options={options}
      optionLabel={optionLabel}
      dropdownIcon={dropdownIcon}
      checkboxIcon={<InputCheck className="p-checkbox-box" />}
      onShow={handleShow}
      onHide={handleHide}
      {...props}
    />
  );
}

export default MultiSelect;
