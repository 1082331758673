import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from './redux/configureStore';
import Routes from './routes/Routes';
import { ToastContainer } from 'react-toastify';
import { MixpanelProvider } from 'react-mixpanel-browser';

const { reduxStore } = configureStore();
localStorage.removeItem('persist:root');

const App = () => (
  <ReduxProvider store={reduxStore}>
    <MixpanelProvider>
      <Routes />
      <ToastContainer />
    </MixpanelProvider>
  </ReduxProvider>
);

export default App;
