import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/button';
import CustomModal from 'components/CustomModal';
import { InputText } from 'components/form/input';
import { checkViewAccessRequest } from 'redux/modules/viewAs/actions';
import { useToken } from 'hooks/useToken';
import { requestGetUserProfile } from 'redux/modules/userProfile/actions';
import { labels } from 'constants/labels';
import { getOrderDetails } from 'redux/modules/orderDetails/actions';
import { useSessionStorageState } from 'hooks/useSessionStorageState';

function ViewAsConfirmationModal({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const token = useToken();
  const [viewAsSession, setViewAsSession] = useSessionStorageState({}, 'viewAsSession');
  const { orderIndexData } = useSelector(({ orderDetails }) => orderDetails);

  const { errors: viewAsErrors, checkViewAccessLoading } = useSelector(
    ({ viewAs }) => viewAs,
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  function onSubmit({ email }) {
    dispatch(
      checkViewAccessRequest({
        params: {
          email,
        },
        token,
        handleViewAsTransfer,
      }),
    );
  }

  function handleViewAsTransfer({ response }) {
    setViewAsSession(response);
    window.location.href = '/dashboard';
  }

  function handleClose() {
    reset();
    onClose();
  }

  return (
    <CustomModal
      isOpen={isOpen}
      cssClass="white-overlay"
      modalname="View As Confirmation Modal"
      overlayCssClass={'phi-modal-overlay'}
      handleClose={() => {}}
    >
      <div className="email-modal p-5 delete-modal cancel-request-confirmation-modal">
        <div className="profile-sub-header my-2">View As</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="email" className="txt size-16 text-gray-dark">
            {labels.pleaseEnterEmailAddress}
          </label>
          <InputText
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: emailRegex,
                message: 'Invalid email address',
              },
            })}
          />
          <div className="txt size-14 text-danger mt-1">{errors.email?.message}</div>
          <div className="cancel-sub-header mt-4"></div>
          <div className="d-md-flex flex-justify-end gap-10">
            <Button
              outlined
              label="Cancel"
              onClick={handleClose}
              disabled={checkViewAccessLoading}
            />
            <Button type="submit" label="Submit" disabled={checkViewAccessLoading} />
          </div>
          <div className="txt size-14 text-danger mt-1">{viewAsErrors?.viewAsError}</div>
        </form>
      </div>
    </CustomModal>
  );
}

export default ViewAsConfirmationModal;
