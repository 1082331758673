import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ImInfo } from 'react-icons/im';
import { TextInput } from 'components/form-input';
import { useOrdersPagedata } from '../../pages/order/OrderPageContext';
import Tooltip from 'components/tooltip/Tooltip';
import { Strings } from 'constants/Strings';
import orderFormErrorStr from 'constants/errorStrings';
import { useMixpanel } from 'react-mixpanel-browser';
import { Dropdown } from 'components/dropdown';

function SubmissionOnBehalf() {
  const { requesters } = useSelector(({ userProfile }) => userProfile);
  const { singleOrderData } = useSelector(({ orderDetails }) => orderDetails);
  const [selectedPreviousRequestor, setSelectedPreviousRequestor] = useState(null);
  const { ordersPageData, dispatchOrderPageAction } = useOrdersPagedata();
  const { requestOnBehalf, ordered_for, errorsObject } = ordersPageData;
  const { requester } = singleOrderData || {};
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (requester && Object.keys(requester).length > 0) {
      dispatchOrderPageAction({ type: 'order/toggleRequestOnBehalf', payload: true });
      dispatchOrderPageAction({
        type: 'order/setOrderedFor',
        payload: {
          firstName: requester.first_name,
          lastName: requester.last_name,
          email: requester.email,
        },
      });

      const matchingUser = requesters.find(
        (option) =>
          option.first_name === requester.first_name &&
          option.last_name === requester.last_name &&
          option.email === requester.email,
      );
      setSelectedPreviousRequestor(matchingUser || null);
    }
  }, [requester]);

  const optionTemplate = (option) => (
    <span>{`${option.first_name} ${option.last_name} - ${option.email}`}</span>
  );

  const selectedUserTemplate = (option) => {
    return option
      ? `Previously submitted - ${option.first_name} ${option.last_name}`
      : 'Previously submitted';
  };

  const handleRequestOnBehalf = (value) => {
    mixpanel.track(`submitting on behalf ${value ? 'yes' : 'no'} selected`, {
      selection_Value: value,
    });
    dispatchOrderPageAction({ type: 'order/toggleRequestOnBehalf', payload: value });
    if (!value) handleClearOrderedFor();
  };

  const handleChange = (value, name) => {
    dispatchOrderPageAction({
      type: 'order/updateOrderedFor',
      payload: { field: name, value },
    });
    if (errorsObject[name]) {
      dispatchOrderPageAction({
        type: 'order/setFormErrorByKey',
        payload: {
          key: name,
          error: '',
        },
      });
    }
    if (!value) {
      const message =
        name === 'firstName'
          ? Strings.orderFirstNameError
          : name === 'lastName'
          ? Strings.orderLastNameError
          : orderFormErrorStr.email;
      dispatchOrderPageAction({
        type: 'order/setFormErrorByKey',
        payload: {
          key: name,
          error: message,
        },
      });
    }
  };

  const clearFormErrors = () => {
    if (errorsObject?.firstName || errorsObject?.lastName || errorsObject?.email) {
      dispatchOrderPageAction({
        type: 'order/setFormErrorsObject',
        payload: {
          firstName: '',
          lastName: '',
          email: '',
        },
      });
    }
  };

  const handleClickonRequestersOption = (option) => {
    const { first_name, last_name, email } = option?.value || {};

    if (!option?.value) {
      dispatchOrderPageAction({
        type: 'order/setFormErrorsObject',
        payload: {
          firstName: Strings.orderFirstNameError,
          lastName: Strings.orderLastNameError,
          email: orderFormErrorStr.email,
        },
      });
    }

    setSelectedPreviousRequestor(option?.value);
    dispatchOrderPageAction({
      type: 'order/setOrderedFor',
      payload: {
        firstName: first_name,
        lastName: last_name,
        email: email,
      },
    });

    clearFormErrors();
  };

  const clearInputData = () => {
    setSelectedPreviousRequestor({});
    dispatchOrderPageAction({
      type: 'order/clearOrderedFor',
    });
  };

  const handleClearOrderedFor = () => {
    clearInputData();
    clearFormErrors();
  };

  return (
    <div className="urgency-container">
      <div className="advanced-setting-title mb-3">
        Are you submitting on behalf of somebody else?
      </div>
      <div className="d-lg-flex mb-4">
        <div className={`radio-input d-flex flex-self-center`}>
          <label className="d-flex flex-self-center">
            <input
              id={'not-requester'}
              type="radio"
              name="requestOnBehalf"
              value={false}
              className="mr-3"
              checked={!requestOnBehalf}
              onChange={() => handleRequestOnBehalf(false)}
            />
            <div className="d-flex flex-self-center flex-items-center">
              <span>No</span>
            </div>
          </label>
        </div>
        <div className={`radio-input d-flex flex-self-center`}>
          <label className="d-flex flex-self-center">
            <input
              id={'yes-requester'}
              type="radio"
              name={'requestOnBehalf'}
              value={true}
              className="mr-3"
              checked={requestOnBehalf}
              onChange={() => handleRequestOnBehalf(true)}
            />
            <div className="d-flex flex-self-center flex-items-center">
              <span>Yes</span>
            </div>
          </label>
        </div>
      </div>
      {requestOnBehalf && (
        <div className="mb-4">
          <div className=" mb-2 flex items-center">
            <div className="d-flex align-items-center">
              <div className="txt size-18 w-600">Prognostogram Requester</div>
              <Tooltip
                tooltipIcon={<ImInfo fontSize={18} className="pt-1" />}
                cssClassName="ml-2 d-flex"
                name="Requester"
              >
                Both yourself and the requester will be returned the Prognostogram and be
                able to view it in the Portal under your Personal Tab.
              </Tooltip>
            </div>
          </div>

          <div className="mb-3">
            {requesters && requesters.length ? (
              <>
                <div className="input-label mb-2">Select Answer</div>

                <Dropdown
                  placeholder="Previously submitted"
                  value={selectedPreviousRequestor}
                  options={requesters || []}
                  onChange={handleClickonRequestersOption}
                  optionLabel="first_name"
                  itemTemplate={optionTemplate}
                  valueTemplate={selectedUserTemplate}
                  showClear
                  dropdownname="Prognostogram Requester"
                />
              </>
            ) : null}
          </div>

          <div className="d-lg-flex mb-3">
            <div className="col-lg-6 mr-lg-2">
              <div className="input-label mb-2">First Name</div>
              <TextInput
                isError={!!errorsObject?.firstName && !ordered_for?.firstName}
                errorText={errorsObject.firstName}
                disabled={!!selectedPreviousRequestor?.label}
                value={ordered_for?.firstName || ''}
                placeholder="E.g. Filipe"
                isFloatingLabel={false}
                onChange={(e) => handleChange(e.target.value, 'firstName')}
                id="textInput-onboardinng-first-name"
              />
            </div>
            <div className="col-lg-6 ml-lg-2">
              <div className="input-label mb-2">Last Name</div>
              <TextInput
                isError={!!errorsObject?.lastName && !ordered_for?.lastName}
                errorText={errorsObject.lastName}
                disabled={!!selectedPreviousRequestor?.label}
                placeholder="E.g  Berger"
                value={ordered_for?.lastName || ''}
                onChange={(e) => handleChange(e.target.value, 'lastName')}
                isFloatingLabel={false}
                id="textInput-onboardinng-last-name"
              />
            </div>
          </div>
          <div className="mr-lg-2 mr-0">
            <div className="col-lg-6 pr-lg-1">
              <div className="input-label mb-2">Email</div>
              <TextInput
                isError={!!errorsObject?.email && !ordered_for?.email}
                errorText={errorsObject?.email}
                disabled={!!selectedPreviousRequestor?.label}
                placeholder="Email"
                value={ordered_for?.email}
                onChange={(e) => handleChange(e.target.value, 'email')}
                isFloatingLabel={false}
                id="textInput-email"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SubmissionOnBehalf;
