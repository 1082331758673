import React from 'react';
import MultiRangeSlider from 'multi-range-slider-react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: ${(props) => props.width};
`;

const RangeSlider = ({
  min,
  max,
  minValue,
  maxValue,
  handleInputChange,
  step,
  name,
  disabled,
  cssclass,
  width = '100%',
}) => {
  return (
    <StyledDiv style={{ width: width }} className={`custom-rage-slider ${cssclass}`}>
      <MultiRangeSlider
        min={min}
        max={max}
        step={step}
        minValue={minValue}
        maxValue={maxValue}
        onInput={(e) => {
          handleInputChange(e, name);
        }}
        ruler={false}
        style={{ border: 'none', boxShadow: 'none' }}
        barInnerColor={'#0D3F3C'}
        barRightColor={'#c1c1c1'}
        barLeftColor={'#c1c1c1'}
        thumbLeftColor={'#ffffff'}
        thumbRightColor={'#ffffff'}
        label={false}
        disabled={disabled}
      />
    </StyledDiv>
  );
};

export default RangeSlider;
