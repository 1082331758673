import React from 'react';
import { labels } from 'constants/labels';
import StudyYears from './StudyYears';
import Downsampling from './Downsampling';
import PatientRecord from './PatientRecord';
import { Frame } from 'components/Frame';
import { Button } from 'components/button';
import { ReactComponent as CheckGray } from 'assets/images/CheckGray.svg';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { ReactComponent as Check } from 'assets/images/Check.svg';

function GeneralStudyParameters() {
  const {
    ordersPageData: { chatRWD },
    dispatchOrderPageAction,
  } = useOrdersPagedata();
  const { generalStudyParameters: generalStudyParameterVal } = chatRWD;
  const { min_history_value, min_followup_value } = generalStudyParameterVal;
  const isSubmitButtonDisabled =
    min_history_value === null || min_followup_value === null || false;

  function handleClick() {
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'isStudyParamFinlized',
        value: true,
      },
    });
  }

  return (
    <>
      <Frame>
        <Frame.Aside></Frame.Aside>
        <Frame.Body>
          <div className="d-flex flex-column gap-25 w-100">
            <div className="txt lh-24 size-16 gray-80">{labels.oneMoreStep}</div>

            <div className="d-flex flex-column">
              <StudyYears />
              <Downsampling />
              <PatientRecord />
            </div>
          </div>
        </Frame.Body>
      </Frame>
      <Frame.Footer>
        <div className="flex-wrap flex-justify-end gap-10 d-md-flex">
          <Button
            outlined={!chatRWD.isStudyParamFinlized ? true : false}
            label={labels.studyParametersAccurate}
            icon={!chatRWD.isStudyParamFinlized ? <CheckGray /> : <Check />}
            onClick={handleClick}
            disabled={isSubmitButtonDisabled}
            buttonClass={chatRWD.isStudyParamFinlized ? 'hand-cursor' : ''}
          />
        </div>
      </Frame.Footer>
    </>
  );
}

export default GeneralStudyParameters;
