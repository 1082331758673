import React, { useContext } from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-size: 14.5px;
`;

const StyledHead = styled.thead``;

const StyledRow = styled.tr`
  > td:only-child {
    padding: 15px;
    background-color: rgb(249 249 249);
  }
`;

const StyledTableBody = styled.tbody``;

const StyledTableHead = styled.th`
  text-align: left;
  padding: 7px;
  width: ${(props) => props.width};
  border-bottom: 0.5px solid #016f66;
  background-color: #016f66;
  color: #fff;
`;

const StyledTd = styled.td`
  text-align: left;
  padding: 7px;
  border-bottom: 0.5px solid #016f66;
  width: ${(props) => props.width};
`;

function Table({ children, className }) {
  return <StyledTable className={className}>{children}</StyledTable>;
}

function TableHead({ children }) {
  return <StyledHead>{children}</StyledHead>;
}

function Row({ children, onClick, className }) {
  return (
    <StyledRow className={className} onClick={onClick}>
      {children}
    </StyledRow>
  );
}

function TableHeader({ children, width, colSpan }) {
  return (
    <StyledTableHead colSpan={colSpan} width={width}>
      {children}
    </StyledTableHead>
  );
}

function TableBody({ children }) {
  return <StyledTableBody>{children}</StyledTableBody>;
}

function TableData({ children, colSpan, className, width, onClick }) {
  return (
    <StyledTd width={width} className={className} colSpan={colSpan} onClick={onClick}>
      {children}
    </StyledTd>
  );
}

Table.TableHead = TableHead;
Table.Row = Row;
Table.TableHeader = TableHeader;
Table.TableBody = TableBody;
Table.TableData = TableData;

export default Table;
