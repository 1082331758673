import React from 'react';
import { useSelector } from 'react-redux';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { ReactComponent as Refresh } from 'assets/images/Refresh.svg';

function StudyDetailsSummary({ studyFormatObject, index, children, ...props }) {
  const { handleButtonAction } = useOrdersPagedata();
  const { studyFormats, isOpenAiLoading } = useSelector(
    ({ orderDetails }) => orderDetails,
  );

  const isNotOnCurrentFrame =
    studyFormats?.length - 1 > props?.optionIndex || isOpenAiLoading || false;

  function renderIcon(icon) {
    switch (icon) {
      case aiButtonsEnum.REGENERATE_SUGGESTIONS:
        return (
          <Refresh className="cursor-pointer" onClick={() => handleIconClick(icon)} />
        );
      default:
    }
  }

  function handleIconClick(key) {
    if (!isNotOnCurrentFrame)
      handleButtonAction({
        key,
        index: props?.optionIndex,
      });
  }

  return (
    <div className="w-80">
      {studyFormatObject?.reason !== '' && studyFormatObject?.reason !== undefined ? (
        <div className="mb-3 txt size-16 gray-20 lh-28 intro-text">
          {studyFormatObject?.reason}
        </div>
      ) : (
        <>
          {studyFormatObject?.display_text_1 && (
            <div
              className="mb-3 txt size-16 gray-20 lh-28 intro-text"
              dangerouslySetInnerHTML={{
                __html: studyFormatObject?.display_text_1,
              }}
            />
          )}
        </>
      )}

      {children}

      {studyFormatObject?.inline_buttons &&
      studyFormatObject?.inline_buttons?.length > 0 ? (
        <div className="d-flex mt-3 align-items-center w-100 gap-20">
          {studyFormatObject?.display_text_2 && (
            <div className="txt size-16 lh-28 conclusion-text text-gray-700">
              {studyFormatObject.display_text_2}
            </div>
          )}
          <div className="d-flex gap-15 justify-content-end ml-auto">
            {!isNotOnCurrentFrame &&
              studyFormatObject?.inline_buttons?.map((icon) => renderIcon(icon))}
          </div>
        </div>
      ) : (
        studyFormatObject?.display_text_2 && (
          <div className="mt-3 txt size-16 lh-28 conclusion-text text-gray-700">
            {studyFormatObject.display_text_2}
          </div>
        )
      )}
    </div>
  );
}

export default StudyDetailsSummary;
