import React, { useEffect, useState } from 'react';
import { ButtonLink } from 'components/form-input/Button';
import Checkbox from 'components/form-input/Checkbox';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { ImInfo } from 'react-icons/im';
import { useMixpanel } from 'react-mixpanel-browser';
import { useOrdersPagedata } from '../../pages/order/OrderPageContext';
import { useSelector } from 'react-redux';

const urgencyTypeList = ['Not Urgent', 'Urgent'];

function OtherSettings() {
  const mixpanel = useMixpanel();
  const { singleOrderData } = useSelector(({ orderDetails }) => orderDetails);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { ordersPageData, dispatchOrderPageAction } = useOrdersPagedata();
  const { isConfidential, urgencyType, selectedDataSourceOption } = ordersPageData;
  const { is_urgent, sharing_type } = singleOrderData || {};

  useEffect(() => {
    if (is_urgent) {
      setIsButtonClicked(true);
      dispatchOrderPageAction({
        type: 'order/setUrgencyType',
        payload: 'Urgent',
      });
    }
  }, [is_urgent]);

  useEffect(() => {
    if (sharing_type && sharing_type === 'Personal') {
      dispatchOrderPageAction({
        type: 'order/setConfidential',
        payload: true,
      });
    }
  }, [sharing_type]);

  const setAdvacedSettingBlock = () => {
    const event = !isButtonClicked ? 'opened' : 'closed';
    mixpanel.track('Other Settings option ' + event + ' event');
    if (!isButtonClicked) {
      setIsButtonClicked(true);
    } else {
      setIsButtonClicked(false);
    }
  };

  const getUrgencyOfRequestList = () => {
    const listItems = urgencyTypeList?.map((source, index) => (
      <div
        className={`radio-input d-flex flex-self-center ${
          index === 0 ? 'mr-0 mb-2' : ''
        }`}
        key={index + '-' + source}
      >
        <label className="d-flex flex-self-center">
          <input
            id={'not-urgent'}
            type="radio"
            name={'urgencyList'}
            value={urgencyTypeList[index]}
            onClick={onClickOfUrgencyButton}
            className="mr-3"
            checked={urgencyType === source ? true : false}
            readOnly
          />
          <div className="d-flex flex-self-center flex-items-center">
            <span>{source} &nbsp;</span> {source === 'Urgent' ? '< ' : '> '}48hrs
          </div>
        </label>
      </div>
    ));
    return (
      <div className="mb-4 urgency-container">
        <div className="heading-text mb-3">Urgency of Request</div>
        <div className="s">{listItems}</div>
      </div>
    );
  };

  const handleCheck = () => {
    mixpanel.track(
      'Confidential input ' + !isConfidential ? 'checked' : 'unchecked' + ' event',
    );
    // setConfidential(!isConfidential);
    dispatchOrderPageAction({
      type: 'order/setConfidential',
      payload: !isConfidential,
    });
  };

  const onClickOfUrgencyButton = (e) => {
    mixpanel.track('Urgency of request options clicked', {
      selected_urgency_value: e.target.value,
    });
    // setUrgencyType(e.target.value);
    dispatchOrderPageAction({
      type: 'order/setUrgencyType',
      payload: e.target.value,
    });
  };

  return (
    <div className="advaced-settings-div">
      <div
        className="d-flex cursor-pointer"
        onClick={() => setAdvacedSettingBlock()}
        id={'advanced-setting-optionn'}
      >
        <div className="advanced-setting-title">Other Settings</div>
        <div
          className={
            !isButtonClicked
              ? 'button-position right-arrow'
              : 'button-position right-arrow rotate-button'
          }
        >
          <ButtonLink id={'advaced-setting-icon'}>
            <HiOutlineChevronRight />
          </ButtonLink>
        </div>
      </div>
      {isButtonClicked ? (
        <div className="advanced-setting-body mt-2">
          {getUrgencyOfRequestList()}
          <div className="display-advaced-settings-block mt-4 mb-md-8">
            <div className="heading-text mt-3 mb-2">Sharing</div>
            <div className="button-container d-md-flex mb-md-10 ">
              <div className={`toolTip-div pr-4 d-flex align-items-center`} key={'index'}>
                <Checkbox
                  buttonName={'Confidential'}
                  onChange={handleCheck}
                  name={'sharing-1'}
                  value={isConfidential}
                  checked={isConfidential ? true : false}
                  id={'checkbox-confidential-sharing-setting'}
                />
                <div className="request-section-tooltip">
                  <span className="ml-1 position-relative top-1">
                    <ImInfo size={14} />
                  </span>
                  <span className="request-section-tooltiptext support-info-top-tooltip">
                    By checking this box, you are choosing to keep the question and
                    resulting Prognostogram confidential for 30 days. After 30 days,
                    Atropos may share the resulting Prognostogram with authorized users
                    based on your organization's preferences. Please contact us at{' '}
                    <a className="text-white" href="mailto:support@atroposhealth.com">
                      support@atroposhealth.com
                    </a>{' '}
                    if you have any questions.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default OtherSettings;
