import React from 'react';
import { useSelector } from 'react-redux';
import { converBase64toLink } from 'utils/converBase64toLink';
import { useOrdersPagedata } from '../OrderPageContext';
import styles from './chatrwd.module.scss';
import { BsFillPersonFill } from 'react-icons/bs';
import { Frame } from 'components/Frame';

function QuestionTitleHeader() {
  const { userData } = useSelector(({ userProfile }) => userProfile);
  const { first_name = '', last_name = '', profile_photo = '' } = userData || {};
  const {
    ordersPageData: { chatRWD },
  } = useOrdersPagedata();

  function profileImage(name, profilePicture) {
    if (profilePicture) {
      let profil_pic = converBase64toLink(profilePicture);
      if (profil_pic) {
        return (
          <div className={styles.orderAvtar}>
            <img src={profil_pic} alt={name} />
          </div>
        );
      } else {
        return (
          <div
            className={`d-flex flex-justify-center flex-items-center row-flex flex-shrink-0  ${styles.navImg}`}
          >
            {name.trim() !== '' ? (
              <span>{name.charAt(0).toUpperCase()} </span>
            ) : (
              <span>
                <BsFillPersonFill />
              </span>
            )}
          </div>
        );
      }
    } else {
      return (
        <div
          className={`d-flex flex-justify-center flex-items-center row-flex flex-shrink-0  ${styles.navImg}`}
        >
          {name.trim() !== '' ? (
            <span>{name.charAt(0).toUpperCase()} </span>
          ) : (
            <span>
              <BsFillPersonFill />
            </span>
          )}
        </div>
      );
    }
  }

  return (
    // <div className="px-md-6 px-3 py-md-4 py-3 d-md-flex gap-25 profile-question-container">
    //   {profileImage(first_name + ' ' + last_name, profile_photo)}
    //   <div className="w-80 txt size-16 gray-20 lh-28 question-title-text">
    //     {chatRWD?.questionTitle}
    //   </div>
    // </div>
    <Frame border={'var(--color-white)'} background={'var(--color-white)'}>
      <Frame.Aside
        icon={profileImage(first_name + ' ' + last_name, profile_photo)}
      ></Frame.Aside>
      <Frame.Body>{chatRWD?.questionTitle}</Frame.Body>
    </Frame>
  );
}

export default QuestionTitleHeader;
