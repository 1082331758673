import { MultiSelect } from 'primereact/multiselect';
import styled from 'styled-components';

const StyledGroupMultiSelect = styled(MultiSelect)`
  &.ah-dropdown {
    width: 100%;
    border: ${(props) => props.border || '1px solid var(--border-gray-neutral)'};
    padding: ${(props) => props.padding || '12px'};
    background: ${(props) => props.background || 'var(--bg-gray-50)'};
    border-radius: ${(props) => props.borderRadius || 'var(--border-radius-7)'};

    .p-multiselect-label {
      display: block;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--color-neutral-900);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      text-align: left;
    }

    &.p-disabled,
    &.p-component:disabled {
      opacity: 0.6;
    }

    :not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 2px var(--border-secondary-300);
      border-color: var(--border-secondary-300);
    }

    :hover {
      border-color: var(--border-secondary-300);
    }

    &.p-multiselect.p-invalid.p-component {
      border-color: var(--border-error);
    }
  }
`;

export default StyledGroupMultiSelect;
