import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { ButtonCustom, ButtonLink } from 'components/form-input/Button';
import { RiErrorWarningFill } from 'react-icons/ri';
import TextInput from 'components/form-input/TextInput';
import { validateAsText } from 'utils/validations';
import {
  getPhysicianInstituteList,
  setOnboardingData,
  updateProfileInfo,
} from 'redux/modules/userProfile/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { HiOutlineChevronDown, HiOutlineX } from 'react-icons/hi';
import Checkbox from 'components/form-input/Checkbox';
import { useMixpanel } from 'react-mixpanel-browser';
import { getSpecialtyList } from 'redux/modules/orderDetails/actions';
import { getRawToken } from '../utils/validations';
import orderScopeEnum from '../constants/enum/orderScopeEnum';
import AuthLoader from 'components/AuthLoader';

const OnboardingProfile = ({ onClickOfProfileBtn }) => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();
  const specialtyRef = useRef();
  const {
    isProfileUpdateStarted,
    updateProfileError,
    userData,
    instituteData = [],
    onboardingData,
    professionData = [],
    specialtyDropDownData = [],
  } = useSelector(({ userProfile }) => userProfile);

  const errorText = 'This field is required';
  const {
    first_name = '',
    last_name = '',
    institute = {},
    specialty = '',
    profession_list = [],
    other_professions = [],
  } = userData || {};

  let initialState = {
    firstName: {
      value: onboardingData?.first_name ? onboardingData.first_name : first_name,
      isValid: true,
      errorText: errorText,
    },
    lastName: {
      value: onboardingData?.last_name ? onboardingData.last_name : last_name,
      isValid: true,
      errorText: errorText,
    },
    otherOrganization: {
      value: onboardingData?.other_institute_name
        ? onboardingData.other_institute_name
        : !institute?.is_global
        ? institute?.name
        : '',
      isValid: true,
      errorText: errorText,
    },
    otherProfession: {
      value: onboardingData?.profession_name
        ? onboardingData?.profession_name
        : other_professions.length !== 0
        ? other_professions[0].name
        : '',
      isValid: true,
      errorText: errorText,
    },
  };

  const [newInstituteList, setNewInstituteList] = useState([]);
  const [newProfessionList, setNewProfessionList] = useState([]);
  const [newSpecialtyList, setNewSpecialtyList] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [organizationSelectedOption, setOrganizationSelectedOption] = useState(null);
  const [specialtySelectedOption, setSpecialtySelectedOption] = useState(null);
  const [showOrganizationError, setShowOrganizationError] = useState(false);
  const [showProfessionError, setShowProfessionError] = useState(false);
  const [showSpecialtyError, setShowSpecialtyError] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const [isProfessionDropdown, setIsProfessionDropdown] = useState(false);
  const [newProfessionValue, setNewProfessionValue] = useState('');
  const [newProfessionGuid, setNewProfessionGuid] = useState([]);

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuColor,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    indicatorContainer: (provided, state) => ({
      ...provided,
      padding: '8',
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '4px',
      boxShadow: 'none',
      border: '1px solid rgba(26, 26, 26, 0.5)',
      padding: '5px 0px 5px 16px',
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#000',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#000',
      backgroundColor: state.isSelected
        ? '#0D3F3C'
        : state.isFocused
        ? '#d8eeee'
        : '#fff',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    },
  };

  const getFiltersListData = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      getSpecialtyList({
        token: getRawToken(token),
        scope: orderScopeEnum.LIBRARY,
      }),
    );
  };

  useEffect(() => {
    getFiltersListData();
  }, []);

  useEffect(() => {
    const getInstituteList = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getPhysicianInstituteList({
          params: {
            institute_name: '',
          },
          token: token ? token.__raw : '',
        }),
      );
    };
    getInstituteList();
  }, [dispatch]);

  useEffect(() => {
    if (instituteData?.length > 0) {
      setInstitutelistOnLoad();
    }
  }, [instituteData]);

  const setInstitutelistOnLoad = () => {
    var newInstituteData = instituteData;
    var otherIndex = newInstituteData.find((o) => o.name === 'Other');
    if (otherIndex === undefined) {
      newInstituteData.unshift({
        guid: 'other',
        name: 'Other',
      });
    }
    const newInstitute = newInstituteData?.map(
      ({ name: label, guid: value, ...rest }) => ({
        label,
        value,
        ...rest,
      }),
    );
    setNewInstituteList(newInstitute);
  };

  useEffect(() => {
    if (professionData?.length > 0) {
      setProfessionlistOnLoad();
    }
  }, [professionData]);

  const setProfessionlistOnLoad = () => {
    var newProfessionData = professionData;
    var otherIndex = newProfessionData.find((o) => o.name === 'Other clinical');
    if (otherIndex === undefined) {
      newProfessionData.unshift({
        guid: 'other_clinical',
        name: 'Other clinical',
      });
    }
    var otherIndex1 = newProfessionData.find((o) => o.name === 'Other nonclinical');
    if (otherIndex1 === undefined) {
      newProfessionData.unshift({
        guid: 'other_nonclinical',
        name: 'Other nonclinical',
      });
    }
    const newProfession = newProfessionData?.map(
      ({ guid: label, name: value, ...rest }) => ({
        label,
        value,
        ...rest,
      }),
    );
    setNewProfessionList(newProfession);
  };

  useEffect(() => {
    if (specialtyDropDownData?.length > 0) {
      setSpecialtylistOnLoad();
    }
  }, [specialtyDropDownData]);

  const setSpecialtylistOnLoad = () => {
    var newSpecialtiesData = specialtyDropDownData;
    const newSpecialty = newSpecialtiesData?.map(
      ({ name: label, guid: value, ...rest }) => ({
        label,
        value,
        ...rest,
      }),
    );
    setNewSpecialtyList(newSpecialty);
  };

  const handleOrganizationChange = (organization) => {
    if (organization) {
      if (organization.label !== 'Other' && formState.otherOrganization.value !== '') {
        setFormState((formState) => ({
          ...formState,
          otherOrganization: { ...formState.otherOrganization, value: '' },
        }));
      }
      setShowOrganizationError(false);
    } else {
      setShowOrganizationError(true);
    }
    setOrganizationSelectedOption(organization);
  };

  const getProfessionList = (list) => {
    if (list) {
      var classNameForLi =
        'd-flex flex-content-center my-3 color-black li-list-specialty';
      var classNameForUi = 'specialty-list';
      const listItems = list?.map((filterValue, index) => (
        <li key={index + '-' + filterValue.value} className={classNameForLi}>
          <Checkbox
            buttonName={filterValue.value}
            onChange={(e) => handleProfessionChange(e)}
            name={'profession'}
            value={filterValue.value}
            checked={checkProfessionValue(filterValue.value)}
            id={`checkbox-profession-${filterValue.value}`}
          />
        </li>
      ));
      return <ul className={classNameForUi}>{listItems}</ul>;
    } else {
      return '';
    }
  };

  const checkProfessionValue = (value) => {
    if (newProfessionValue) {
      var filterValues = newProfessionValue.split(',');
      var index = filterValues.indexOf(value);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleProfessionChange = (e) => {
    var values = '';
    var guids = '';
    var data = '';
    data = newProfessionList;
    guids = newProfessionGuid !== '' ? newProfessionGuid : [];
    values = newProfessionValue !== '' ? newProfessionValue.split(',') : [];
    data?.map((filter) => {
      if (e.target.value === filter.value) {
        if (e.target.checked) {
          values.push(e.target.value);
          guids.push(filter.label);
        } else {
          var index = values.indexOf(e.target.value);
          if (index > -1) {
            values.splice(index, 1);
          }
          var index1 = guids.indexOf(filter.label);
          if (index1 > -1) {
            guids.splice(index1, 1);
          }
        }
      }
    });
    mixpanel.track('Profession clicked', {
      profession_value: values.join(),
    });
    if (values.length !== 0) {
      setShowProfessionError(false);
    } else {
      setShowProfessionError(true);
    }
    setNewProfessionGuid(guids);
    setNewProfessionValue(values.join());
  };

  useEffect(() => {
    function handler(event) {
      if (isProfessionDropdown && !specialtyRef.current?.contains(event.target)) {
        setIsProfessionDropdown(false);
      }
    }
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, [isProfessionDropdown]);

  const clearProfessions = (e) => {
    e.stopPropagation();
    setIsProfessionDropdown(false);
    setNewProfessionValue('');
    setNewProfessionGuid([]);
    setShowProfessionError(true);
  };

  const handleSpecialtyChange = (specialty) => {
    if (specialty) {
      setShowSpecialtyError(false);
    }
    setSpecialtySelectedOption(specialty);
  };

  const setOrganization = (value) => {
    newInstituteList.forEach((institute) => {
      if (institute.label === value) {
        setOrganizationSelectedOption(institute);
      }
    });
  };

  useEffect(() => {
    if (institute) {
      setOrganization(institute?.name);
    }
  }, [institute, instituteData, newInstituteList]);

  // set profession values
  const setProfession = (list, by) => {
    if (list) {
      var newProfessionsList = [];
      var newProfessionsGuids = [];
      newProfessionList.forEach((profession) => {
        if (list.includes(by === 'name' ? profession.value : profession.label)) {
          newProfessionsList.push(profession.value);
          newProfessionsGuids.push(profession.label);
        }
      });
      setNewProfessionGuid(newProfessionsGuids);
      setNewProfessionValue(newProfessionsList.join());
    }
  };

  const setSpecialty = (value) => {
    newSpecialtyList.forEach((specialty) => {
      if (specialty.label === value) {
        setSpecialtySelectedOption(specialty);
      }
    });
  };

  useEffect(() => {
    if (specialty) {
      setSpecialty(specialty);
    }
    if (profession_list.length !== 0) {
      setProfession(profession_list, 'name');
    }
    if (onboardingData !== undefined && onboardingData !== null) {
      if (onboardingData?.profession_guid) {
        setProfession(onboardingData?.profession_guid, 'guid');
      }
      if (onboardingData?.specialty_name) {
        setSpecialty(onboardingData?.specialty_name);
      }
    }
  }, [onboardingData, newSpecialtyList, newProfessionList, professionData]);

  //generic handles
  const handleFocus = (name) => {
    setShowErrorMessage(false);
    setFormState((formState) => ({
      ...formState,
      [name]: { ...formState[name], isValid: true },
    }));
  };

  const handleChange = (value, name) => {
    setFormState((formState) => ({
      ...formState,
      [name]: { ...formState[name], value: value },
    }));
  };

  const handleFirstNameBlur = () => {
    setFormState((formState) => ({
      ...formState,
      firstName: {
        ...formState.firstName,
        value: formState.firstName.value.trim(),
        isValid: validateAsText(formState.firstName.value),
      },
    }));
  };

  const handleLastNameBlur = () => {
    setFormState((formState) => ({
      ...formState,
      lastName: {
        ...formState.lastName,
        value: formState.lastName.value.trim(),
        isValid: validateAsText(formState.lastName.value),
      },
    }));
  };

  const handleOtherOrganizationBlur = () => {
    setFormState((formState) => ({
      ...formState,
      otherOrganization: {
        ...formState.otherOrganization,
        value:
          formState.otherOrganization.value !== undefined
            ? formState.otherOrganization.value.trim()
            : '',
        isValid: validateAsText(formState.otherOrganization.value),
      },
    }));
  };

  const handleOtherProfessionBlur = () => {
    setFormState((formState) => ({
      ...formState,
      otherProfession: {
        ...formState.otherProfession,
        value: formState.otherProfession.value.trim(),
        isValid: validateAsText(formState.otherProfession.value),
      },
    }));
  };

  const isFormValid = () => {
    return (
      validateAsText(formState.firstName.value) &&
      validateAsText(formState.lastName.value) &&
      checkForValidProfessionAnsSpecialty()
    );
  };

  const checkForValidProfessionAnsSpecialty = () => {
    if (newProfessionValue === '') {
      setShowProfessionError(true);
      return false;
    } else {
      setShowProfessionError(false);
      if (
        newProfessionValue.indexOf('Other clinical') > -1 ||
        newProfessionValue.indexOf('Other nonclinical') > -1
      ) {
        setShowSpecialtyError(false);
        //if profession is other then other profession is required
        setFormState((formState) => ({
          ...formState,
          otherProfession: {
            ...formState.otherProfession,
            isValid: validateAsText(formState.otherProfession.value),
          },
        }));
        return validateAsText(formState.otherProfession.value);
      } else if (
        newProfessionValue.indexOf('Physician') > -1 ||
        newProfessionValue.indexOf('Medical Resident/Fellow') > -1
      ) {
        //if profession is Physician or Medical resident/fellow, specailty is required
        if (specialtySelectedOption === null) {
          setShowSpecialtyError(true);
          return false;
        } else {
          setShowSpecialtyError(false);
          return true;
        }
      } else {
        setShowSpecialtyError(false);
        return true;
      }
    }
  };

  const onClickOfSubmitProfileInfo = () => {
    if (checkIfOrgaisationIsValid() && isFormValid()) {
      setShowErrorMessage(false);
      saveUsersSettings();
    } else {
      setShowErrorMessage(true);
    }
  };

  const saveUsersSettings = async () => {
    var professionParam = [];
    var professionGuids = newProfessionGuid;
    if (formState.otherProfession.value) {
      //set profession name values as per format
      newProfessionValue.split(',').forEach((value) => {
        if (value === 'Other clinical') {
          professionParam.unshift({
            is_clinical: true,
            name: formState.otherProfession.value,
          });
        } else if (value === 'Other nonclinical') {
          professionParam.unshift({
            is_clinical: false,
            name: formState.otherProfession.value,
          });
        }
      });
      //remove clinical or non clinical guid fron guid list
      professionGuids = professionGuids.filter(function (item) {
        return item !== 'other_clinical' && item !== 'other_nonclinical';
      });
    }
    const token = await getIdTokenClaims();
    dispatch(
      updateProfileInfo({
        params: {
          first_name: formState.firstName.value,
          last_name: formState.lastName.value,
          institute_name: organizationSelectedOption
            ? organizationSelectedOption.label === 'Other'
              ? formState.otherOrganization.value
              : organizationSelectedOption.label
            : '',
          institute_guid: organizationSelectedOption
            ? organizationSelectedOption.label === 'Other'
              ? ''
              : organizationSelectedOption.value
            : '',
          profession_guid_list: professionGuids,
          profession_name_list: professionParam,
          profession_name: formState.otherProfession.value,
          specialty_guid: specialtySelectedOption ? specialtySelectedOption.value : '',
        },
        userGuid: userData.guid,
        token: getRawToken(token),
        successCallback: redirectToNextScreen,
      }),
    );
    document.body.classList.remove('hide-chat');
  };

  const redirectToNextScreen = () => {
    !!onClickOfProfileBtn && onClickOfProfileBtn();
  };

  const checkIfOrgaisationIsValid = () => {
    if (organizationSelectedOption === null) {
      setShowOrganizationError(true);
      return false;
    } else if (organizationSelectedOption.label === 'Other') {
      setShowOrganizationError(false);
      setFormState((formState) => ({
        ...formState,
        otherOrganization: {
          ...formState.otherOrganization,
          isValid: validateAsText(formState.otherOrganization.value),
        },
      }));
      return validateAsText(formState.otherOrganization.value);
    } else {
      return true;
    }
  };

  const onFocusOfSelect = () => {
    setShowErrorMessage(false);
  };

  return (
    <>
      {isProfileUpdateStarted ? (
        <div className="position-relative">
          <AuthLoader fullScreen={false} />
        </div>
      ) : (
        <>
          {showErrorMessage || !!updateProfileError ? (
            <div className="d-flex onboarding-error-container mt-4">
              <div className="mr-3 flex-self-center">
                <RiErrorWarningFill className="d-block" />
              </div>
              <div className="flex-self-center">
                {showErrorMessage
                  ? 'Please complete all of the required fields'
                  : updateProfileError}
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="onboarding-section-header my-4">Personal Info</div>
          <div>
            <div className="d-flex mb-4">
              <div className="col-md-6 mr-2">
                <div className="input-label mb-2">First Name</div>
                <TextInput
                  value={formState.firstName.value}
                  isError={!formState.firstName.isValid}
                  errorText={formState.firstName.errorText}
                  onChange={(e) => handleChange(e.target.value, 'firstName')}
                  onFocus={() => handleFocus('firstName')}
                  onBlur={handleFirstNameBlur}
                  placeholder="E.g. Filipe"
                  isFloatingLabel={false}
                  id={'textInput-onboardinng-first-name'}
                />
              </div>
              <div className="col-md-6 ml-2">
                <div className="input-label mb-2">Last Name</div>
                <TextInput
                  value={formState.lastName.value}
                  isError={!formState.lastName.isValid}
                  errorText={formState.lastName.errorText}
                  onChange={(e) => handleChange(e.target.value, 'lastName')}
                  onFocus={() => handleFocus('lastName')}
                  onBlur={handleLastNameBlur}
                  placeholder="E.g  Berger"
                  isFloatingLabel={false}
                  id={'textInput-onboardinng-last-name'}
                />
              </div>
            </div>
            <div className="input-label mb-2">Organization</div>
            <Select
              isDisabled={institute?.name ? true : false}
              options={newInstituteList}
              value={organizationSelectedOption}
              styles={customStyles}
              onChange={handleOrganizationChange}
              onFocus={onFocusOfSelect}
              placeholder={'Select your organization'}
              isClearable={true}
              id={'select-onboardinng-organization'}
              noOptionsMessage={({ inputValue: string }) =>
                'Please select a valid organization or select Other'
              }
            />
            {showOrganizationError && (
              <div className="color-error error-text">{errorText}</div>
            )}
            {organizationSelectedOption !== null ? (
              <>
                {organizationSelectedOption?.label === 'Other' ? (
                  <>
                    <div className="input-label mb-2 mt-4">Other organization</div>
                    <TextInput
                      placeholder="Other organization"
                      isFloatingLabel={false}
                      value={formState.otherOrganization.value}
                      isError={!formState.otherOrganization.isValid}
                      errorText={formState.otherOrganization.errorText}
                      onChange={(e) => handleChange(e.target.value, 'otherOrganization')}
                      onFocus={() => handleFocus('otherOrganization')}
                      onBlur={handleOtherOrganizationBlur}
                      id={'textInput-onboardinng-other-organization'}
                    />
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
            <div className="input-label mb-2 mt-4">Profession</div>
            {professionData ? (
              <div className="specialty-shared-div">
                <div
                  className="specialty-shared mr-5"
                  onClick={() => setIsProfessionDropdown(!isProfessionDropdown)}
                >
                  <div
                    className={`specialty-title ${
                      newProfessionValue === '' ? 'dropdown-placeholder' : ''
                    }`}
                  >
                    {newProfessionValue === ''
                      ? 'Select your profession'
                      : newProfessionValue.split(',').join(', ')}
                  </div>
                  <div className="specialty-down-close-arrow">
                    {newProfessionValue !== '' && (
                      <ButtonCustom
                        buttonAction="Profession clear button clicked"
                        id={'btn-onboardinng-clear-profession'}
                        onClick={clearProfessions}
                      >
                        <HiOutlineX />
                      </ButtonCustom>
                    )}
                  </div>
                  <div className="specialty-down-arrow">
                    <ButtonLink
                      buttonAction="Profession dropdown clicked"
                      id={'btn-onboardinng-profession-dropdown'}
                    >
                      <HiOutlineChevronDown />
                    </ButtonLink>
                  </div>
                </div>
                {isProfessionDropdown && newProfessionList ? (
                  <div className="specialty-container" ref={specialtyRef}>
                    <div className="specialty-div">
                      <div className="specialty-body-div">
                        {getProfessionList(newProfessionList)}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            {showProfessionError && (
              <div className="color-error error-text">{errorText}</div>
            )}
            {(newProfessionValue.indexOf('Other clinical') > -1 ||
              newProfessionValue.indexOf('Other nonclinical') > -1) && (
              <>
                <div className="input-label mb-2 mt-4">Other profession</div>
                <TextInput
                  placeholder="Other profession"
                  isFloatingLabel={false}
                  value={formState.otherProfession.value}
                  isError={!formState.otherProfession.isValid}
                  errorText={formState.otherProfession.errorText}
                  onChange={(e) => handleChange(e.target.value, 'otherProfession')}
                  onFocus={() => handleFocus('otherProfession')}
                  onBlur={handleOtherProfessionBlur}
                  id={'textInput-onboardinng-other-profession'}
                />
              </>
            )}
            <div className="input-label mb-2 mt-4">Specialty</div>
            <Select
              options={newSpecialtyList}
              value={specialtySelectedOption}
              onChange={handleSpecialtyChange}
              onFocus={onFocusOfSelect}
              styles={customStyles}
              placeholder={'Select your specialty'}
              isClearable={true}
              id={'select-onboarding-specialty'}
              noOptionsMessage={({ inputValue: string }) =>
                'Please select a valid specialty'
              }
            />
            {showSpecialtyError && (
              <div className="color-error error-text">{errorText}</div>
            )}
          </div>
          <div className="d-md-flex flex-md-justify-end onboarding-btn-container mt-4">
            <ButtonCustom
              cssClass={`gradient-btn mt-md-0 mt-3 py-3 px-3 position-relative width-100`}
              onClick={onClickOfSubmitProfileInfo}
              buttonAction={'Confirm onboarding profile info button clicked'}
              id={'btn-onboarding-confirm-profile-info-button'}
            >
              Confirm and Continue
            </ButtonCustom>
          </div>
        </>
      )}
    </>
  );
};

export default OnboardingProfile;
