import { call, takeLatest, put } from 'redux-saga/effects';
import { viewAs } from 'api/';
import * as viewAsActions from './types';
import orderFormErrorStr from 'constants/errorStrings';

export function* checkUserViewAccess({
  payload: { token, params, handleViewAsTransfer },
}) {
  try {
    yield put({
      type: viewAsActions.CHECK_VIEW_ACCESS_LOADING,
    });
    const { response, error } = yield call(viewAs.fetchUserViewAsAccess, {
      token,
      params,
    });

    if (!!response) {
      handleViewAsTransfer({ response });
    } else {
      yield put({
        type: viewAsActions.CHECK_VIEW_ACCESS_FAILURE,
        payload:
          error?.data?.message || error?.data?.error || orderFormErrorStr.commonError,
      });
    }
  } catch (error) {
    yield put({
      type: viewAsActions.CHECK_VIEW_ACCESS_FAILURE,
      payload:
        error?.data?.message || error?.data?.error || orderFormErrorStr.commonError,
    });
  }
}

function* watchCheckUserViewAccess() {
  yield takeLatest(viewAsActions.CHECK_VIEW_ACCESS_REQUEST, checkUserViewAccess);
}

export const sagas = [watchCheckUserViewAccess];
