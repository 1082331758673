import React from 'react';

function PicotDataDisplay({ data, updated_pico }) {
  const value = Array.isArray(data?.value) ? data.value.join(', ') : data?.value;

  return (
    value && (
      <div className="mb-1 detail-item">
        <strong className="text-capitalize txt size-16 text-bold gray-20 lh-28">
          {data?.key}:{' '}
        </strong>
        <span
          className={`txt size-16 gray-20 ${
            updated_pico && updated_pico.includes(data?.key) ? 'text-underline' : ''
          }`}
        >
          {Array.isArray(data?.value) ? data.value.join(', ') : data?.value}
        </span>
      </div>
    )
  );
}

export default PicotDataDisplay;
