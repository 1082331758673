import { InputNumber } from 'primereact/inputnumber';
import styled from 'styled-components';

export const StyledInputNumber = styled(InputNumber)`
  .ah-input {
    width: 100%;
    font-size: ${(props) => props.fontSize || '16px'};
    border: ${(props) =>
      props.border || '1px solid var(--border-on-surface-medium-emphasis)'};
    border-radius: ${(props) => props.borderradius || 'var(--border-radius-8)'};
    background: ${(props) => props.background || 'var(--bg-gray-50)'};
    padding: ${(props) => props.padding || '12px'};
    color: ${(props) => props.color || 'var(--color-black-opacity-60)'};
    height: ${(props) => props.height};

    :hover {
      border-color: var(--border-secondary-300);
    }

    :enabled:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 2px var(--border-secondary-300);
      border-color: var(--border-secondary-300);
    }

    :disabled {
      opacity: 0.6;
    }
  }
`;
