import React from 'react';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { labels } from 'constants/labels';
import styles from './studyparameters.module.scss';
import RadioButton from 'components/form-input/RadioButton';
import { InputText } from 'components/form/input';
import { ToggleablePanel } from 'components/Panel';
import { Strings } from 'constants/Strings';
import { InputNumber } from 'components/form/inputNumber';
import { useMixpanel } from 'react-mixpanel-browser';

function Downsampling() {
  const mixpanel = useMixpanel();
  const {
    ordersPageData: { chatRWD },
    dispatchOrderPageAction,
  } = useOrdersPagedata();
  const { generalStudyParameters } = chatRWD;

  function handleOptionChange(e) {
    const { value } = e.target;

    mixpanel.track('General study parameters downsampling selection', {
      'Downsampling option': value,
    });

    const payload = {
      downsample: value,
    };

    dispatchOrderPageAction({
      type: 'order/updateGeneralStudyParametersValues',
      payload,
    });
  }

  function handleTotalPatientsChange(e) {
    const payload = {
      downsample_number: e.value,
    };
    dispatchOrderPageAction({
      type: 'order/updateGeneralStudyParametersValues',
      payload,
    });
  }

  return (
    <ToggleablePanel panelClassName="panel-border-bottom" header={labels.downsampling}>
      <div className={styles.tooglePanelMd}>
        <div className="study-param-inputs">
          <div
            className={`d-flex flex-wrap align-items-center gap-25  ${styles.customFormContainer}`}
          >
            <div className="d-flex flex-wrap align-items-center gap-15">
              <RadioButton
                buttonName="Downsample"
                onClick={handleOptionChange}
                name="downsample"
                checked={generalStudyParameters?.downsample === 'downsample'}
                id="downsample-radio-button"
                value="downsample"
                disabled={chatRWD.isStudyParamFinlized}
              />
              <div className="d-flex gap-10 align-items-center">
                <div className={styles.inputContainer}>
                  <InputNumber
                    inputId="total-patients"
                    onChange={handleTotalPatientsChange}
                    value={generalStudyParameters?.downsample_number}
                    disabled={
                      generalStudyParameters?.downsample !== 'downsample' ||
                      chatRWD.isStudyParamFinlized
                    }
                  />
                </div>
                <div className="txt size-14 w-500 lh-20 dark">Total Patient</div>
              </div>
            </div>
            <div>
              <RadioButton
                buttonName="Full dataset"
                onClick={handleOptionChange}
                name="downsample"
                checked={generalStudyParameters?.downsample === 'fullDataset'}
                id="full-dataset-radio-button"
                value="fullDataset"
                disabled={chatRWD.isStudyParamFinlized}
              />
            </div>
          </div>
        </div>

        {generalStudyParameters?.downsample === 'downsample' &&
          (generalStudyParameters?.downsample_number < 100 ||
            generalStudyParameters?.downsample_number > 10000000) && (
            <div className="color-error error-text">{Strings.downsampleError}</div>
          )}
      </div>
    </ToggleablePanel>
  );
}

export default Downsampling;
