export const GET_SEARCH_SUGGESTION = 'searchOrder/getSearchSuggestion';
export const GET_SEARCH_SUGGESTION_START = 'searchOrder/getSearchSuggestionStart';
export const GET_SEARCH_SUGGESTION_COMPLETE = 'searchOrder/getSearchSuggestionCompleted';
export const GET_SEARCH_SUGGESTION_ERROR = 'searchOrder/getSearchSuggestionError';
export const CLEAR_SEARCH_TERM_DATA = 'orderDetails/clearSearchTermData';

export const getSearchSuggestion = (payload = {}) => ({
  type: GET_SEARCH_SUGGESTION,
  payload,
});

export const clearSearchTermData = (payload = {}) => ({
  type: CLEAR_SEARCH_TERM_DATA,
  payload,
});
