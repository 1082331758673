export const GET_ALL_NOTIFICATIONS = 'notifications/getAllNotifications';
export const GET_ALL_NOTIFICATIONS_COMPLETE = 'notifications/getAllNotificationsComplete';
export const GET_ALL_NOTIFICATIONS_ERROR = 'notifications/getAllNotificationsError';
export const READ_NOTIFICATION = 'notifications/readNotificationStart';
export const READ_NOTIFICATION_COMPLETE = 'notifications/readNotificationComplete';
export const READ_NOTIFICATION_ERROR = 'notifications/readNotificationError';
export const SET_NOTIFICATION_DROPDOWN_OPEN = 'notifications/setNotificationDropdownOpen';

export const RESET_NOTIFICATION_COUNT = 'notifications/resetNotification';
export const RESET_NOTIFICATION_COUNT_START = 'notifications/resetNotificationCountStart';
export const RESET_NOTIFICATION_COUNT_COMPLETE = 'notifications/resetNotificationCountComplete';
export const RESET_NOTIFICATION_COUNT_ERROR = 'notifications/resetNotificationError';
export const SET_NOTIFICATION_GUID = 'notifications/setNotificationGuid';
export const SET_PAGE_NUMBER = 'notifications/setPageNumber';

export const GET_BADGE_NOTIFICATIONS = 'notifications/getBadgeNotifications';
export const GET_BADGE_NOTIFICATIONS_COMPLETE = 'notifications/getBadgeNotificationsComplete';
export const GET_BADGE_NOTIFICATIONS_ERROR = 'notifications/getBadgeNotificationsError';
export const SET_NOTIFICATIONS_LOADING = 'notifications/setNotificationLoading';
export const SET_BADGE_NOTIFICATION_COUNT = 'notifications/setBadgeNotificationCount';
export const TOGGLE_NOTIFICATIONS_DROPDOWN = 'notifications/toggleNotificationDropdown';
export const SET_ALL_NOTIFICATIONS_COUNT = 'notifications/setAllNotificationsCount';
export const SET_INTERVAL_TASK = 'notifications/setInterval';
export const CLEAR_INTERVAL_TASK = 'notifications/clearIntervalTask';

export const getAllNotifications = (payload = {}) => ({
    type: GET_ALL_NOTIFICATIONS,
    payload,
});

export const getAllNotificationsComplete = (payload) => ({
    type: GET_ALL_NOTIFICATIONS_COMPLETE,
    payload,
});

export const getAllNotificationsError = (payload = {}) => ({
    type: GET_ALL_NOTIFICATIONS_ERROR,
    payload,
});

export const readNotification = (payload = {}) => ({
    type: READ_NOTIFICATION,
    payload,
});

export const readNotificationComplete = (payload = {}) => ({
    type: READ_NOTIFICATION_COMPLETE,
    payload,
});

export const readNotificationError = (payload = {}) => ({
    type: READ_NOTIFICATION_ERROR,
    payload,
});

export const setNotificationDropdownOpen = (payload) => ({
    type: SET_NOTIFICATION_DROPDOWN_OPEN,
    payload,
})

export const resetNotification = (payload) => ({
    type: RESET_NOTIFICATION_COUNT,
    payload,
})

export const resetNotificationCountStart = (payload) => ({
    type: RESET_NOTIFICATION_COUNT_START,
    payload,
})

export const resetNotificationCountComplete = (payload) => ({
    type: RESET_NOTIFICATION_COUNT_COMPLETE,
    payload,
})

export const resetNotificationError = (payload) => ({
    type: RESET_NOTIFICATION_COUNT_ERROR,
    payload,
})

export const setNotificationGuid = (payload) => ({
    type: SET_NOTIFICATION_GUID,
    payload,
})

export const setPageNumber = (payload) => ({
    type: SET_PAGE_NUMBER,
    payload,
})

export const getBadgeNotifications = (payload) => ({
    type: GET_BADGE_NOTIFICATIONS,
    payload,
})

export const getBadgeNotificationsComplete = (payload) => ({
    type: GET_BADGE_NOTIFICATIONS_COMPLETE,
    payload,
})

export const getBadgeNotificationsError = (payload) => ({
    type: GET_BADGE_NOTIFICATIONS_ERROR,
    payload,
})

export const setNotificationLoading = (payload) => ({
    type: SET_NOTIFICATIONS_LOADING,
    payload,
})

export const setBadgeNotificationCount = (payload) => ({
    type: SET_BADGE_NOTIFICATION_COUNT,
    payload,
})

export const toggleNotificationDropdown = (payload) => ({
    type: TOGGLE_NOTIFICATIONS_DROPDOWN,
    payload,
})

export const setAllNotificationsCount = (payload) => ({
    type: SET_ALL_NOTIFICATIONS_COUNT,
    payload,
})

export const setintervalTask = (payload) => ({
    type: SET_INTERVAL_TASK,
    payload,
})

export const clearIntervalTask = (payload) => ({
    type: CLEAR_INTERVAL_TASK,
    payload,
})


