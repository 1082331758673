import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './header.module.scss';
import { labels } from 'constants/labels';
import { ButtonCustom } from 'components/form-input/Button';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { resetOrderIndexData } from 'redux/modules/orderDetails/actions';
import { useOrdersPagedata } from '../OrderPageContext';

function Header() {
  const dispatch = useDispatch();
  const { singleOrderData } = useSelector(({ orderDetails }) => orderDetails);
  const { dispatchOrderPageAction, orderTypePath } = useOrdersPagedata();

  const headerText =
    singleOrderData?.guid && orderTypePath === 'edit'
      ? labels.editOrder
      : singleOrderData?.guid && orderTypePath === 'reorder'
      ? labels.modifyandReorder
      : labels.orderaPrognostogram;

  const handleOnClick = () => {
    dispatch(resetOrderIndexData());
    dispatchOrderPageAction({
      type: 'order/setNavigationConfirmationModalOpen',
      payload: true,
    });
  };

  return (
    <div className={`${styles.headerContainer} my-3`}>
      <div className="d-flex align-items-center gap-10">
        <ButtonCustom
          cssClass="bg-white border-none d-flex cursor-pointer p-0"
          onClick={() => handleOnClick()}
          buttonAction="Order back button clicked"
          id="btn-order-back-button"
        >
          <HiOutlineArrowLeft fontSize={24} />
        </ButtonCustom>
        <h1 className="txt dark">{headerText}</h1>
      </div>
    </div>
  );
}

export default Header;
