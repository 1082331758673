import { Checkbox } from 'primereact/checkbox';
import styled from 'styled-components';

export const StyledCheckbox = styled(Checkbox)`
  &.ah-checkbox {
    position: relative;
    display: inline-flex;
    user-select: none;
    vertical-align: bottom;
    position: relative;
    display: inline-flex;
    user-select: none;
    vertical-align: bottom;
    width: ${(props) => props.width || '18px'};
    height: ${(props) => props.height || '18px'};

    .p-checkbox-input {
      appearance: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      z-index: 1;
      outline: 0 none;
      cursor: pointer;
      border: ${(props) => props.border || '2px solid var(--border-gray-400)'};
      border-radius: ${(props) => props.borderradius || 'var(--border-radius-2)'};
    }

    .p-checkbox-box {
      display: flex;
      justify-content: center;
      align-items: center;
      border: ${(props) => props.border || '2px solid var(--border-gray-400)'};
      border-radius: ${(props) => props.borderradius || 'var(--border-radius-2)'};
      background: ${(props) => props.background || ' var(--bg-white)'};
      width: ${(props) => props.width || '18px'};
      height: ${(props) => props.height || '18px'};
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      outline-color: transparent;

      .p-checkbox-icon {
        transition-duration: 0.2s;
        color: ${(props) => props.iconColor || 'var(--color-white)'};
        font-size: 13px;

        &.p-icon {
          width: 12px;
          height: 12px;
        }
      }
    }

    &.p-highlight .p-checkbox-box {
      border-color: var(--border-ocean-green);
      background: var(--bg-primary);
    }

    &:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
      border-color: #111827;
    }

    &:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
      border-color: var(--border-dark-teal);
      background: var(--bg-dark-teal);
      color: var(--color-white);
    }

    &:not(.p-disabled):has(.p-checkbox-input:focus-visible) .p-checkbox-box {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 8px rgba(29, 27, 32, 0.2);
      border: 0.5px solid #1d1b20;
      border-radius: 5px;
    }

    &.p-invalid > .p-checkbox-box {
      border-color: var(--border-error);
    }

    &.p-disabled {
      cursor: default;
      pointer-events: none;
      user-select: none;
      opacity: 0.8;
    }
  }
`;
