import React from 'react';
import { useSelector } from 'react-redux';
import { useOrdersPagedata } from './OrderPageContext';
import { ButtonCustom } from 'components/form-input/Button';
import { labels } from 'constants/labels';

function FormButtons({ disabled, buttonAction, actionLabel, onSubmit }) {
  const { orderTypePath, dispatchOrderPageAction } = useOrdersPagedata();
  const { singleOrderData } = useSelector(({ orderDetails }) => orderDetails);
  const buttonLabelsAction = singleOrderData?.guid && orderTypePath === 'edit';
  const { confirmAndSendRequest } = labels;

  function handleCancelRequest() {
    dispatchOrderPageAction({
      type: 'order/setNavigationConfirmationModalOpen',
      payload: true,
    });
  }

  return (
    <div className="d-md-flex flex-md-justify-between flex-md-row-reverse">
      <ButtonCustom
        disabled={disabled}
        cssClass={`gradient-btn send-request-btn mt-md-0 mt-3 ${
          disabled ? 'btn-disabled' : ''
        }`}
        onClick={onSubmit}
        buttonAction={buttonAction}
        actionLabel={actionLabel}
        id="btn-submit-order-button"
      >
        {confirmAndSendRequest}
      </ButtonCustom>
      <ButtonCustom
        cssClass="cancel-btn mt-md-0 mt-3"
        onClick={handleCancelRequest}
        id="btn-cancel-order-button"
        buttonAction={
          buttonLabelsAction
            ? 'Discard Changes edit order clicked'
            : 'Cancel new PG order clicked'
        }
        actionLabel={
          buttonLabelsAction ? 'Edit order changes discarded' : 'New PG order canceled'
        }
      >
        {buttonLabelsAction ? 'Discard Changes' : 'Cancel Request'}
      </ButtonCustom>
    </div>
  );
}

export default FormButtons;
