const orderFormErrorStr = {
  firstName: 'First name is not valid!',
  lastName: 'Last name is not valid!',
  email: 'Email is not valid!',
  physicianInstitution: 'Please select a valid organization!',
  question: 'Question should not be empty!',
  phone_number: 'Please enter valid 10 digit phone number!',
  birthDate: 'Please enter valid birthdate!',
  commonError: 'Something went wrong. Please contact support for help!',
  orderFirstNameError:
    'Please proceed to your profile and update the first name before ordering a Prognostogram.',
  orderLastNameError:
    'Please proceed to your profile and update the last name before ordering a Prognostogram.',
  orderOrgnisationError:
    'Please ensure your profile is associated with an organization. Please proceed to your profile and choose an appropriate organization before ordering a Prognostogram.',
  orderQuestionTypeError:
    'Please proceed to question type section and update the question type before ordering a Prognostogram.',
  phenotypeValidationsError:
    'Please resolve all phenotype validation errors before proceeding.',
};
export default orderFormErrorStr;
