import React from 'react';
import { ButtonSecondary } from './form-input/Button';

const ErrorBoundaryModal = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert" className="errors-container d-flex flex-justify-center">
      <div className="text-center py-6">
        <h2>Oops! Something went wrong!</h2>
        <div className="error-color">
          We encountered an error while attempting to log you in. Please click the 'Try Again' button below.
        </div>
        <div className="error-color">
          If the issue persists, please contact us at <a href="mailto:support@atroposhealth.com">support@atroposhealth.com</a>.
        </div>
        <div className="mt-2">Error: {error.message}</div>
        <ButtonSecondary
          cssClass="btn-secondary py-2 px-6 mt-4"
          onClick={resetErrorBoundary}
          label="Try again"
          buttonAction={'Reset error button clicked'}
          id={'btn-try-again'}
        />
      </div>
    </div>
  );
};
export default ErrorBoundaryModal;
