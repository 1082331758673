import React, { useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { useMixpanel } from 'react-mixpanel-browser';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const CustomModal = ({ isOpen, handleClose, cssClass, children, modalname, overlayCssClass }) => {
  const mixpanel = useMixpanel();
  useEffect(() => {
    if (isOpen) {
      mixpanel.track('Modal opened', {
        modal_name: modalname,
      });
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className={'modal ' + cssClass}
      overlayClassName={"modal-overlay " + overlayCssClass}
    >
      <div className="modal-container">{children}</div>
    </Modal>
  );
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

CustomModal.defaultProps = {
  isOpen: false,
};

export default CustomModal;
