import React from 'react';
import StyledGroupMultiSelect from './StyledGroupMultiSelect';
import { ReactComponent as InputCheck } from 'assets/images/InputCheck.svg';
import { ReactComponent as ChevronDown } from 'assets/images/ChevronDown.svg';
import { useMixpanel } from 'react-mixpanel-browser';

function GroupMultiSelect({
  value = null,
  options = [],
  onChange,
  optionLabel,
  optionGroupLabel,
  optionGroupChildren,
  placeholder,
  showSelectAll = false,
  groupedItemTemplate,
  dropdownIcon = <ChevronDown />,
  ...props
}) {
  const mixpanel = useMixpanel();

  function handleShow() {
    const { dropdownname } = props;
    const action = dropdownname + ' ' + 'dropdown opened';
    mixpanel.track(action, {
      action: action,
      action_value: dropdownname,
    });
  }

  function handleHide() {
    const { dropdownname } = props;
    const action = dropdownname + ' ' + 'dropdown closed';
    mixpanel.track(action, {
      action: action,
      action_value: dropdownname,
    });
  }

  function handleChange(event) {
    const { value, selectedOption } = event;
    const isPresent = value.some((v) => v.name === selectedOption.name);
    let action;
    if (isPresent) {
      action = selectedOption.name + ' ' + 'option selected';
    } else {
      action = selectedOption.name + ' ' + 'option unselected';
    }
    mixpanel.track(action, {
      action: action,
      action_value: selectedOption.name,
    });
    onChange(event);
  }

  return (
    <div className="card flex justify-content-center">
      <StyledGroupMultiSelect
        className="ah-dropdown"
        panelClassName="ah-dropdown-panel ah-groupMultiselect-dropdown-panel"
        value={value}
        options={options}
        onChange={handleChange}
        optionLabel={optionLabel}
        optionGroupLabel={optionGroupLabel}
        optionGroupChildren={optionGroupChildren}
        optionGroupTemplate={groupedItemTemplate}
        placeholder={placeholder}
        showSelectAll={showSelectAll}
        checkboxIcon={<InputCheck className="p-checkbox-box" />}
        dropdownIcon={dropdownIcon}
        onShow={handleShow}
        onHide={handleHide}
        {...props}
      />
    </div>
  );
}

export default GroupMultiSelect;
