import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import WhiteLogo from 'assets/images/atropos_logo_white.png';
import { ButtonSecondary } from './form-input/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { requestGetUserProfile } from 'redux/modules/userProfile/actions';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRawToken } from '../utils/validations';
import { useMixpanel } from 'react-mixpanel-browser';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const OrderSuccessModal = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { orderScopeData } = useSelector(({ orderDetails }) => orderDetails);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    if (showModal) {
      mixpanel.track('Modal opened', {
        modal_name: 'New order creation success modal',
      });
    }
  }, [showModal]);
  const OnClickOfClose = () => {
    setShowModal(false);
    if (isAuthenticated) {
      getProfileData();
      history.push(
        orderScopeData?.scopeTabIndex === 1
          ? urlConstantsEnum.LIBRARY_DASHBOARD
          : urlConstantsEnum.DASHBOARD,
      );
    } else {
      history.push(urlConstantsEnum.LOGIN);
    }
  };
  const getProfileData = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      requestGetUserProfile({
        token: getRawToken(token),
      }),
    );
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={OnClickOfClose}
      className="modal"
      overlayClassName="modal-overlay"
    >
      <div className="modal-container">
        <div className="logo-img">
          <img src={WhiteLogo} alt="Atropos logo" />
        </div>
        <div className="my-8 pr-3 pb-3 modal-desc text-center">
          {'Great! Your question has been successfully submitted'}
        </div>
        <ButtonSecondary
          label={'Go to the Dashboard'}
          cssClass="gradient-btn px-6 py-3"
          buttonAction={'Go to the Dashboard button clicked'}
          onClick={OnClickOfClose}
          id={'btn-go-to-dashboard-button'}
        />
      </div>
    </Modal>
  );
};

export default OrderSuccessModal;
