import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AppRoutes from 'routes/AppRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { requestGetUserProfile, getUsersConfig } from 'redux/modules/userProfile/actions';
import {
  getSearchQueryDetails,
  getQuestionTypes,
} from 'redux/modules/orderDetails/actions';
import AuthLoader from '../components/AuthLoader';
import { useHistory } from 'react-router-dom';
import { getRawToken } from '../utils/validations';
import { useMixpanel } from 'react-mixpanel-browser';
import LogOutUtil from '../utils/LogOutUtil';

const Dashboard = () => {
  const history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const { logOutUser } = LogOutUtil();
  const { userData, isFetchProfileCompleted, isLoadingUsersConfig } = useSelector(
    ({ userProfile }) => userProfile,
  );
  const [isAuthorised, setAuthorised] = useState(false);
  const [isLoggedUserId, setLoggedUserId] = useState(false);

  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  useEffect(() => {
    if (userData) {
      userData.emails?.forEach((emailObj) => {
        if (emailObj.email === userData.last_login_email) {
          if (emailObj.verification_status === 'Verified') {
            setAuthorised(true);
          } else {
            setAuthorised(false);
          }
        }
      });
    }
  }, [userData]);

  useEffect(() => {
    //Logged user guid on GA
    if (userData) {
      if (!isLoggedUserId) {
        var email = '';
        setLoggedUserId(true);
        userData.emails?.forEach((emailObj) => {
          if (emailObj.primary) {
            email = emailObj.email;
          }
        });
        mixpanel.identify(userData.guid);
        mixpanel.people.set({
          $name: userData.first_name + ' ' + userData.last_name,
          $email: email,
        });
        var param = {
          email: email,
        };
        if (userData.first_name || userData.last_name) {
          param.name = userData.first_name + ' ' + userData.last_name;
        }
        //sending user identity information to helpscoute
        window.Beacon('identify', param);
      }
    }
  }, [userData]);

  useEffect(() => {
    const getArtiFactData = async () => {
      const token = await getIdTokenClaims();
      // dispatch(
      //   requestGetUserProfile({
      //     token: getRawToken(token),
      //     logOutCallback: logOutCallbackFuc,
      //   }),
      // );
    };

    getArtiFactData();
  }, [dispatch, getIdTokenClaims]);

  useEffect(() => {
    const getQuestions = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getQuestionTypes({
          token: getRawToken(token),
        }),
      );
    };
    const getMotivations = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getMotivations({
          token: getRawToken(token),
        }),
      );
    };
    const getSearchQuery = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getSearchQueryDetails({
          token: getRawToken(token),
        }),
      );
    };
    const getUsersConfigData = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getUsersConfig({
          token: getRawToken(token),
        }),
      );
    };
    if (isAuthorised) {
      getSearchQuery();
      getQuestions();
      getUsersConfigData();
    }
  }, [dispatch, getIdTokenClaims, isAuthorised]);

  const logOutCallbackFuc = () => {
    logOutUser();
  };

  return !isFetchProfileCompleted && isLoadingUsersConfig ? (
    <AuthLoader fullScreen={false} />
  ) : (
    <AppRoutes />
  );
};
export default Dashboard;
