import React, { useState, useEffect } from 'react';
import { ButtonSecondary, ButtonLink } from './form-input/Button';
import PersonalEmptyIcon from 'assets/images/NoPGs.png';
import ModalVideo from 'react-modal-video';
import NoOrder from './NoOrder';
import { VIDEO_ID } from 'constants/Constants';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import { labels } from 'constants/labels';

const PersonalNoOrder = () => {
  const [isOpenModal, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const bodyTextTemplate = () => {
    return (
      <div className="no-order-text my-3">
        Need some help figuring out what to ask first,{' '}
        <ButtonSecondary
          cssClass="link"
          onClick={openModal}
          label="click here"
          buttonAction="How to order prognostogram video button clicked"
          id={'btn-click-here'}
        />{' '}
        to learn more about how it works.
      </div>
    );
  };

  return (
    <div className="text-center no-order-personal">
      <NoOrder
        imgUrl={PersonalEmptyIcon}
        headerText="You haven’t ordered any prognostograms yet!"
        bodyText={bodyTextTemplate}
      />
      <ButtonLink
        cssClass="gradient-btn mt-4 mb-7"
        route={urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_ONE}
        buttonAction="Order first PG button clicked"
        id={'btn-order-first-pg-button'}
      >
        {labels.orderYourFirstPrognostogram}
      </ButtonLink>
      <ModalVideo
        channel="vimeo"
        autoplay={true}
        isOpen={isOpenModal}
        videoId={VIDEO_ID}
        onClose={() => setModalOpen(false)}
      />
    </div>
  );
};
export default PersonalNoOrder;
