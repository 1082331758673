import React, { useState, useRef } from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import { useAuth0 } from '@auth0/auth0-react';
import { USER_IDEAL_TIME, SESSION_WARNING_MODAL_DISPLAY_TIME } from 'constants/Constants';
import SessionTimeoutNotificationModal from './SessionTimeoutNotificationModal';
import LogOutUtil from '../utils/LogOutUtil';

const CheckIdleTimer = () => {
  const [showModal, setShowModal] = useState(false);
  const { loginWithRedirect } = useAuth0();
  const { logOutUser } = LogOutUtil();

  const onIdle = () => {
    setShowModal(false);
    logOutUser();
  };
  const onActive = () => {
    setShowModal(false);
    loginWithRedirect({
      redirectUri: `${window.location.origin}`,
      appState: { returnTo: window.location.pathname },
    });
  };

  const handleCloseOfModal = () => {
    setShowModal(false);
  };

  const handleClickOfLogIn = () => {
    loginWithRedirect({
      redirectUri: `${window.location.origin}`,
      appState: { returnTo: window.location.pathname },
    });
  }; 

  const handleClickOfLogOut = () => {
    logOutUser();
  }; 

  const onPrompt = () => {
    setShowModal(true);
  };
  return (
    <>
      <SessionTimeoutNotificationModal
        showModal={showModal}
        OnClickOfClose={handleCloseOfModal}
        OnClickOfLogin={handleClickOfLogIn}
        OnClickOfLogout={handleClickOfLogOut}
      />
      <IdleTimerProvider
        timeout={USER_IDEAL_TIME * 60}
        onPrompt={onPrompt}
        promptTimeout={SESSION_WARNING_MODAL_DISPLAY_TIME * 60}
        onIdle={onIdle}
        onActive={onActive}
      ></IdleTimerProvider>
    </>
  );
};
export default CheckIdleTimer;
