import React from 'react';
import StyledButton from './StyledButton';
import { useMixpanel } from 'react-mixpanel-browser';

function Button({
  label,
  icon,
  type,
  outlined,
  onClick = () => {},
  disabled,
  actionLabel,
  id,
  severity,
  buttonClass,
  ...props
}) {
  const mixpanel = useMixpanel();

  function handleOnClick() {
    const buttonAction = props.buttonaction || label + ' ' + 'button clicked';
    mixpanel.track(buttonAction, {
      action: buttonAction,
      action_value: label,
    });
    onClick();
  }

  return (
    <StyledButton
      className={`ah-button ${buttonClass}`}
      outlined={outlined}
      label={label}
      icon={icon}
      type={type}
      onClick={handleOnClick}
      disabled={disabled}
      severity={severity}
      id={id}
      {...props}
    />
  );
}

export default Button;
