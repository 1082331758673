export const labels = {
  orderaPrognostogram: 'Order a Prognostogram',
  modifyandReorder: 'Modify and Reorder',
  editOrder: 'Edit Order',
  accessErrorMessage:
    'Oops! It looks like you do not have access to that Prognostogram. If you think this is an error, please reach out to our team at',
  email: 'Support@atroposhealth.com',
  confirmAndSendRequest: 'Confirm and Send Request',
  orderYourFirstPrognostogram: 'Order Your First Prognostogram!',
  scheduleaCall: 'Schedule a Call',
  modifyAndReorder: 'Modify and Reorder',
  editOrder: 'Edit Order',
  unableToReturnPhenotypeDescription: 'Unable to return an appropriate phenotype',
  failedToGeneratePhenotype: 'Failed to generate the phenotype',
  patientCare: 'patient care',
  inputQuestionPlaceholder:
    'Enter your question here (example: Is there a difference in incidents of fractures in female oncology patients without a history of osteoporosis who received zoledronic acid vs denosumab?) and press return.',
  enterYourQuestion: 'Enter your question below',
  oneMoreStep: 'One more step! Please set your study parameters:',
  studyYears: 'Study Years',
  downsampling: 'Downsampling',
  minimumLengthofPatientRecord: 'Minimum Length of Patient Record ',
  preIndex: 'Pre-Index',
  postIndex: 'Post-Index',
  caseSeries: 'case series',
  crossSectional: 'cross-sectional',
  comparativeEffectiveness: 'comparative effectiveness',
  placeholderCopy: 'Please choose your study format and enter the study parameters.',
  crossSectional: 'cross-sectional',
  intervention: 'intervention',
  control: 'control',
  cohort_1: 'cohort_1',
  cohort_2: 'cohort_2',
  manualEdit: 'manualEdit',
  studyParametersAccurate: 'Study Parameters Accurate',
  cancelRequest: 'Cancel Request',
  conflicting_ages: 'conflicting_ages',
  no_sex_selected: 'no_sex_selected',
  conflicting_sexes: 'conflicting_sexes',
  population: 'population',
  submitMotivation: 'Submit Motivation',
  viewAs: 'View As',
  pleaseEnterEmailAddress: 'Please enter email address.',
  studyAgeRange: 'Age Range - please select the MIN and MAX ages for your study',
  studySexSelection: 'Sex - please select the sexes to include',
  ahSalesEmail: 'sales@atroposhealth.com',
  vocabulary: 'Vocabulary',
  code: 'Code',
  description: 'Description',
};
