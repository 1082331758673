import React, { useEffect, useState } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { emailVerification } from 'redux/modules/userProfile/actions';
import { Link, Redirect, useParams } from 'react-router-dom';
import AuthLoader from 'components/AuthLoader';
import { useMixpanel } from 'react-mixpanel-browser';

const EmailVerification = () => {
  const { isLoading, emailVerificationError, successMessage } = useSelector(
    ({ userProfile }) => userProfile,
  );
  let { email, token } = useParams();
  const mixpanel = useMixpanel();
  const [redirect, setRedirect] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      emailVerification({
        params: { email: email, token: token },
      }),
    );
  }, [dispatch, email, token]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRedirect(true);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const trackEmailVCerification = () => {
    mixpanel.track('Email Verified');
  };

  return !isLoading ? (
    <div className="app-container d-flex col-12 flex-justify-center flex-items-center">
      <div className="email-outer-container">
        {emailVerificationError ? (
          <div className="d-flex flex-column flex-items-center email-inner-container">
            <div className="email-error-icon mb-6 d-flex flex-items-center flex-justify-center">
              <AiOutlineClose />
            </div>
            <div className="email-header-text">Error</div>
            <div className="mt-3">
              {emailVerificationError
                ? emailVerificationError
                : 'Your email address could not be verified.'}
            </div>
            <Link className="link mt-3" to="/dashboard" id={'go-to-portal-link'}>
              Go to Portal
            </Link>
          </div>
        ) : (
          <div className="d-flex flex-column flex-items-center email-inner-container">
            {redirect ? (
              <>
                {trackEmailVCerification()}
                <Redirect to="/dashboard" />
              </>
            ) : (
              <div className="d-flex flex-column flex-items-center">
                <div className="email-success-icon mb-6 d-flex flex-items-center flex-justify-center">
                  <AiOutlineCheck />
                </div>
                <div className="email-header-text">Success</div>
                <div className="mt-3">
                  {successMessage ? successMessage : 'Your email address is verified.'}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="position-relative">
      <AuthLoader fullScreen={false} />
    </div>
  );
};
export default EmailVerification;
