import { searchOrder } from 'api/';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  GET_SEARCH_SUGGESTION,
  GET_SEARCH_SUGGESTION_START,
  GET_SEARCH_SUGGESTION_COMPLETE,
  GET_SEARCH_SUGGESTION_ERROR,
} from './actions';
import orderFormErrorStr from '../../../constants/errorStrings';

export function* getSearchTermSuggestion({ payload: { token, params } }) {
  try {
    yield put({
      type: GET_SEARCH_SUGGESTION_START,
    });
    const { response, error } = yield call(searchOrder.getSearchTerm, {
      token,
      params,
    });
    if (!!response) {
      yield put({
        type: GET_SEARCH_SUGGESTION_COMPLETE,
        payload: {
          searchTerms: response.data.preferredTerms,
        },
      });
    } else {
      //Show error boundary if error is 500 or other
      yield put(
        onGetSearchTermSuggestionError({
          searchTerms: undefined,
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      onGetSearchTermSuggestionError({
        searchTerms: undefined,
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

const onGetSearchTermSuggestionError = (payload = {}) => ({
  type: GET_SEARCH_SUGGESTION_ERROR,
  payload,
});

function* watchSearchTermSuggestion() {
  yield takeLatest(GET_SEARCH_SUGGESTION, getSearchTermSuggestion);
}

export const sagas = [watchSearchTermSuggestion];
