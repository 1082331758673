import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { resetLocalData } from 'redux/modules/orderDetails/actions';
import { useDispatch } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import { useSessionStorageState } from 'hooks/useSessionStorageState';

const LogOutUtil = () => {
  const [viewAsSession, setViewAsSession] = useSessionStorageState({}, 'viewAsSession');
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  const logOutUser = () => {
    mixpanel.track('User logged out');
    mixpanel.reset();
    if (viewAsSession?.impersonate_header) {
      setViewAsSession({});
    }
    logout({ returnTo: window.location.origin });
    dispatch(resetLocalData());
  };
  return { logOutUser };
};
export default LogOutUtil;
