import React from 'react';
import { Frame } from 'components/Frame';
import styled from 'styled-components';
import TextLoader from 'components/skeleton-loader/TextLoader';

const StyledDiv = styled.div`
  width: 80%;
`;

const SkeletonLoader = ({ loadingTitle }) => {
  return (
    <Frame>
      <Frame.Aside></Frame.Aside>
      <Frame.Body>
        <StyledDiv>
          <div className="mb-3 txt size-16 lh-28 gray-20">{loadingTitle}</div>
          <TextLoader width={'100%'} />
          <TextLoader width={'100%'} />
        </StyledDiv>
      </Frame.Body>
    </Frame>
  );
};

export default SkeletonLoader;
