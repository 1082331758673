import React, { useCallback } from 'react';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import RangeSlider from 'components/form-input/RangeSlider';

import { labels } from 'constants/labels';
import styles from './studyparameters.module.scss';
import { ToggleablePanel } from 'components/Panel';
import { useMixpanel } from 'react-mixpanel-browser';

const studyYears = {
  min: 2010,
  max: 2024,
};

function StudyYears() {
  const mixpanel = useMixpanel();
  const {
    ordersPageData: { chatRWD },
    dispatchOrderPageAction,
  } = useOrdersPagedata();
  const { generalStudyParameters } = chatRWD;

  const handleInput = useCallback(
    (event) => {
      const newMinValue = event?.minValue;
      const newMaxValue = event?.maxValue;

      if (
        newMinValue !== generalStudyParameters?.study_years_min ||
        newMaxValue !== generalStudyParameters?.study_years_max
      ) {
        mixpanel.track('General study parameters - study years changed', {
          study_years_min: newMinValue,
          study_years_max: newMaxValue,
        });

        const payload = {
          study_years_min: newMinValue,
          study_years_max: newMaxValue,
        };

        dispatchOrderPageAction({
          type: 'order/updateGeneralStudyParametersValues',
          payload,
        });
      }
    },
    [
      dispatchOrderPageAction,
      generalStudyParameters?.study_years_min,
      generalStudyParameters?.study_years_max,
    ],
  );

  return (
    <ToggleablePanel
      panelClassName="panel-border-bottom none-padding-top"
      header={labels.studyYears}
    >
      <div className={styles.tooglePanel}>
        <div
          className=""
          style={{
            paddingRight: '25px',
          }}
        >
          <RangeSlider
            min={2010}
            max={new Date().getFullYear()}
            minValue={generalStudyParameters?.study_years_min}
            maxValue={generalStudyParameters?.study_years_max}
            name="studyYears"
            handleInputChange={handleInput}
            step={1}
            disabled={chatRWD.isStudyParamFinlized}
          />
        </div>
      </div>
    </ToggleablePanel>
  );
}

export default StudyYears;
