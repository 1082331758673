import React, { useState } from 'react';
import { Button } from 'components/button';
import { InputText } from 'components/form/input';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { Controller } from 'react-hook-form';
import { labels } from 'constants/labels';

function CrossSectional({ fields, clearErrors, setError, control, ...props }) {
  const { workflow } = props;

  const {
    ordersPageData: { chatRWD },
    dispatchOrderPageAction,
  } = useOrdersPagedata();
  const { nextFieldIndex, visibleFields, formValues } = chatRWD;

  const handleAddField = () => {
    const nextCohort = `cohort_${nextFieldIndex}`;
    if (nextFieldIndex <= 5) {
      dispatchOrderPageAction({
        type: 'order/setChatRWDData',
        payload: {
          visibleFields: [...visibleFields, nextCohort],
          nextFieldIndex: nextFieldIndex + 1,
        },
      });
    }
  };

  function handleChange({ event, field }) {
    const { name, value } = event.target;
    let formValues = {
      [name]: value,
    };

    if (workflow === labels.manualEdit) {
      if (name === labels.cohort_1) {
        formValues = {
          ...formValues,
          intervention: value,
        };
      }
      if (name === labels.cohort_2) {
        formValues = {
          ...formValues,
          control: value,
        };
      }
    }

    dispatchOrderPageAction({
      type: 'order/updateChatRWDFormValues',
      payload: formValues,
    });

    if (value) {
      clearErrors(name);
    } else {
      const errorMessage = field.required ? `${field.name} is required` : '';

      setError(name, {
        type: 'manual',
        message: errorMessage,
      });
    }
  }

  const formatLabel = (key) => {
    return key
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <>
      {fields.map((field, index) => (
        <React.Fragment key={field.name}>
          {visibleFields.includes(field.name) && (
            <div className="d-flex flex-column gap-8">
              <label
                className="txt w-700 size-14 dark text-capitalize"
                htmlFor={field.name}
              >
                {formatLabel(field.name)}
                {field.required && '*'}
              </label>

              <Controller
                control={control}
                name={field.name}
                defaultValue={formValues[field.name] || ''}
                rules={{
                  required: field.required
                    ? `${formatLabel(field.name)} is required`
                    : false,
                }}
                render={({
                  field: { onChange, name, ref, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    ref={ref}
                    name={name}
                    value={value}
                    onChange={(event) => {
                      const { value } = event.target;
                      onChange(value);

                      handleChange({ event, field });
                    }}
                    invalid={error && error.message ? true : false}
                    errormesaage={error ? error.message : ''}
                  />
                )}
              />
              {field.name === `cohort_${nextFieldIndex - 1}` && nextFieldIndex <= 5 && (
                <Button
                  text
                  buttonClass="text-left"
                  onClick={handleAddField}
                  label="+ Add another cohort"
                />
              )}
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

export default CrossSectional;
