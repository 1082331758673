const subscriptionObjEnum = Object.freeze([
  {
    name: 'Free',
    guid: 'cb8fb64f-7657-42d5-842b-c1188ba666d0',
    frequency: 'monthly',
    pricing: '',
    subtext: 'Try it out',
    features: [
      '2 Free Prognostograms',
      'Run Prognostograms on one National Dataset',
      'View 10 sample Prognostograms',
    ],
  },
  {
    name: 'Individual',
    guid: 'ea0fa76c-aa19-402f-9329-d5f5b3197515',
    frequency: 'monthly',
    pricing: '$250/mo',
    subtext: 'Meant for individual providers and researchers',
    features: [
      '2 Prognostograms per Month',
      'Run Prognostograms on multiple National Datasets',
      'Access to our vast and growing Evidence Library of publicly shared Prognostograms',
    ],
  },
  {
    name: 'Enterprise',
    guid: 'af35c3c0-f582-4a84-8e66-f2f2cdfd31fd',
    frequency: 'monthly',
    pricing: 'Contact us',
    subtext:
      'Unlock the power of Prognostograms and RWE insights for your entire organization',
    features: [
      'Access across your department or entire organization',
      "Run Prognostograms on your organization's data and compare to National Data",
      'Complete Access to our growing Evidence Library and your organization’s prior questions',
    ],
  },
  {
    name: 'Free',
    guid: 'a4946cd6-2592-4f4c-9e90-f889f87a7f4f',
    frequency: 'yearly',
    pricing: '',
    subtext: 'Try it out',
    features: [
      '2 Free Prognostograms',
      'Run Prognostograms on one National Dataset',
      'View 10 sample Prognostograms',
    ],
  },
  {
    name: 'Individual',
    guid: '127039f2-eed5-4974-93fe-f16c91c4e3bb',
    frequency: 'yearly',
    pricing: '$2000/year',
    subtext: 'Meant for individual providers and researchers',
    features: [
      '2 Prognostograms per Month',
      'Run Prognostograms on multiple National Datasets',
      'Access to our vast and growing Evidence Library of publicly shared Prognostograms',
    ],
  },
  {
    name: 'Enterprise',
    guid: '7b351ed2-2deb-4c03-9522-304ddfb168c4',
    frequency: 'yearly',
    pricing: 'Contact us',
    subtext:
      'Unlock the power of Prognostograms and RWE insights for your entire organization',
    features: [
      'Access across your department or entire organization',
      "Run Prognostograms on your organization's data and compare to National Data",
      'Complete Access to our growing Evidence Library and your organization’s prior questions',
    ],
  },
]);

const subscriptionEnum = Object.freeze({
  STANDARD: 'Individual',
  ENTERPRISE: 'Enterprise',
  FREE: 'Free',
});

const subscriptionFrequencyEnum = Object.freeze({
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
});

export { subscriptionObjEnum };
export { subscriptionEnum };
export { subscriptionFrequencyEnum };
