import React, { forwardRef } from 'react';
import { StyledInputText } from './StyledInput';

const InputText = forwardRef(({ value, onChange, ...props }, ref) => {
  return (
    <>
      <StyledInputText
        autoComplete="off"
        className="ah-input"
        value={value}
        onChange={onChange}
        ref={ref}
        {...props}
      />
      {props.invalid && (
        <span className="txt size-12 text-danger">{props.errormesaage}</span>
      )}
    </>
  );
});

export default InputText;
