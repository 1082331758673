const urlConstantsEnum = Object.freeze({
  LOGIN: '/',
  DASHBOARD: '/dashboard/personal',
  LIBRARY_DASHBOARD: '/dashboard/library',
  REQUEST_PROGNOSTOGRAMSECTION_ONE: '/order',
  REQUEST_PROGNOSTOGRAMSECTION_TWO: '/order/questions-type',
  REQUEST_PROGNOSTOGRAMSECTION_THREE: '/order/clinical-question',
  PROFILE: '/profile',
  SEARCH_RESULT: '/orders/search/personal',
  LIBRARY_SEARCH_RESULT: '/orders/search/library',
  ORDER_SUBMITTED: '/order/submitted',
  EMAIL_VERIFICATION_PENDING: '/dashboard/email-verification-pending',
  ONBOARDING_STEP_1: '/onboarding/personal-info',
  ONBOARDING_STEP_2: '/onboarding/terms-of-services',
  ONBOARDING_STEP_3: '/onboarding/plan-and-pricing',
  ORDER_SEARCH: '/orders/search' //added static path to show notifications banners on specific screens only
});

export default urlConstantsEnum;
