import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter, useHistory } from 'react-router-dom';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const AuthProvider = () => {
  const history = useHistory();
  const [isRedirected, setRedirected] = useState(false);

  const onRedirectCallback = (appState) => {
    if (!isRedirected && history !== undefined) {
      setRedirected(true);
      history.push(
        appState && appState.returnTo ? appState.returnTo : window.location.href,
      );
    }
  };

  return (
    <BrowserRouter>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback()}
      >
        <App />
      </Auth0Provider>
    </BrowserRouter>
  );
};

ReactDOM.render(<AuthProvider />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
