import styled from 'styled-components';

const StyledFrame = styled.div`
  padding: ${(props) => props.padding || '16px'};
  border: ${(props) => props.border || '1px solid var(--border-primary-light)'};
  background: ${(props) => props.background || 'var(--background-primary-light)'};
  width: 100%;
  @media (min-width: 992px) {
    padding: 20px;
  }
  @media (min-width: 1200px) {
    padding: 30px;
  }
`;

const StyledFrameWrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  @media (min-width: 992px) {
    gap: 20px;
  }
  @media (min-width: 1200px) {
    gap: 30px;
  }
`;

const StyledFrameAside = styled.div`
  flex-shrink: 0;
`;

const StyledFrameBody = styled.div`
  width: 100%;
`;

const StyledFrameFooter = styled.div`
  width: 100%;
  padding: ${(props) => props.padding || '30px'};
  border: ${(props) => props.border || '1px solid var(--border-primary-light)'};
  background: var(--bg-white);
`;

export {
  StyledFrame,
  StyledFrameWrapper,
  StyledFrameAside,
  StyledFrameBody,
  StyledFrameFooter,
};
