import React from 'react';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { StudyForm } from '../StudyDetailsForm';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { useDispatch, useSelector } from 'react-redux';
import { savePicoOptions } from 'redux/modules/orderDetails/actions';
import { labels } from 'constants/labels';

function StudyDetailsEditForm({ studyFormat = {}, onFormSubmit, onCancel, data }) {
  const dispatch = useDispatch();
  const { PICOGuid } = useSelector(({ orderDetails }) => orderDetails);
  const {
    dispatchOrderPageAction,
    ordersPageData: { chatRWD },
    token,
  } = useOrdersPagedata();
  const title = `We categorized your question as ${
    data?.study_design || chatRWD?.selectedStudyFormat
  } type of study. here is the study design:`;

  function handleButtonClick({ key, values }) {
    switch (key) {
      case aiButtonsEnum.SAVE_PICO:
        onFormSubmit(key);
        dispatch(
          savePicoOptions({
            params: {
              previous_pico_guid: PICOGuid,
              study_design: chatRWD?.selectedStudyFormat,
              study_format: {
                ...values,
              },
            },
            token,
          }),
        );
        break;
      case aiButtonsEnum.CANCEL_PICO:
        dispatchOrderPageAction({
          type: 'order/clearChatRWDFormValues',
        });
        onCancel();
        break;
      default:
        console.log('wrong option');
    }
  }

  if (studyFormat && Object.keys(studyFormat).length > 0) {
    return (
      <>
        <StudyForm
          title={title}
          onFormSubmit={(values) =>
            handleButtonClick({ key: aiButtonsEnum.SAVE_PICO, values })
          }
          onCancel={() => handleButtonClick({ key: aiButtonsEnum.CANCEL_PICO })}
          cancelBtnId="cancel-manually-edit-study-parameters-btn"
          cancelBtnAction="Manually Edit Study Parameters form closed"
          saveBtnId="save-manually-edit-study-parameters"
          saveBtnAction="Save Manually Edit Study Parameters button clicked"
          workflow={labels.manualEdit}
        />
      </>
    );
  }

  return null;
}

export default StudyDetailsEditForm;
