import axios from 'axios';

export const userProfile = async ({ token }) => {
  return axios({
    method: 'get',
    url: '/users/profile/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const updateUserProfileInfo = async ({ token, params, userGuid }) => {
  return axios({
    method: 'patch',
    url: `/users/${userGuid}/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const emailVerification = async ({ params }) => {
  return axios({
    method: 'post',
    url: '/users/check-email-verification/',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const sendEmailVerification = async ({ token, params }) => {
  return axios({
    method: 'post',
    url: '/users/send-email-verification/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const phoneVerification = async ({ token, params }) => {
  return axios({
    method: 'post',
    url: '/users/check-phone-verification/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const sendPhoneVerification = async ({ token, params }) => {
  return axios({
    method: 'post',
    url: '/users/send-phone-verification/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};
export const getPhysicianInstituteTermList = async ({ token, params }) => {
  var searchInstituteData = params.institute_name
    ? '?search=' + params.institute_name
    : '';
  return axios({
    method: 'get',
    url: '/institutions/' + searchInstituteData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getProfessionList = async ({ token }) => {
  return axios({
    method: 'get',
    url: '/users/professions/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getSpecialtyDropDownList = async ({ token }) => {
  return axios({
    method: 'get',
    url: '/specialties/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getRequesters = async ({ token, guid }) => {
  return axios({
    method: 'get',
    url: `/users/${guid}/requester/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const requestCompleteProfile = async ({ token, userGuid }) => {
  return axios({
    method: 'post',
    url: `/users/${userGuid}/profile/completed/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const createNewUser = async ({ params, csrfToken }) => {
  return axios({
    method: 'post',
    url: '/users/profile/',
    headers: {
      'X-CSRFToken': csrfToken,
      'Content-Type': 'application/json',
    },
    data: params,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};

export const getUsersConfig = async ({ token }) => {
  return axios({
    method: 'get',
    url: `/users/config/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response }));
};
