import React from 'react';

const StyleGuide = () => {
  return (
    <div className="m-6">
      <h1>Heading H1</h1>
      <h2>Heading H2</h2>
      <h3>Heading H3</h3>
      <h4>Heading H4</h4>
      <h5>Heading H5</h5>
      <h6>Heading H6</h6>
      <p>Text</p>
      <p className="small mb-6">Text small</p>
      refer{' '}
      <a
        href="https://primer.style/css/utilities/typography"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        Primer Typography
      </a>{' '}
      <br />
      refer{' '}
      <a
        href="https://primer.style/css/utilities/margin"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        Margin
      </a>{' '}
      <br />
      refer{' '}
      <a
        href="https://primer.style/css/utilities/padding"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        Padding
      </a>{' '}
      <br />
      refer{' '}
      <a
        href="https://primer.style/css/utilities/layout"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        Layout
      </a>{' '}
      <br />
      refer{' '}
      <a
        href="https://primer.style/css/objects/grid"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        Grid
      </a>{' '}
      <br />
      refer{' '}
      <a
        href="https://primer.style/css/utilities/flexbox"
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        Flexbox
      </a>{' '}
      <div className="my-3"></div>
      <button className="btn-primary py-3 px-6">Primary</button>
      <button className="btn-disabled py-3 px-6 ml-2">Disabled</button>
      <button className="gradient-btn py-3 px-6 ml-2">Gradient</button>
      <button className="link ml-2">Primary Link</button>
      <button className="secondary-link py-3 px-6 ml-2">Secondary Link</button>
      <div className="my-3"></div>
      <input type="text" className="form-input" />
      <input type="text" className="form-input disabled ml-2" />
      <input type="text" className="form-input border-error ml-2" />
      <div className="my-3"></div>
      <label className="checkbox-container">
        Checkbox
        <input type="checkbox" defaultChecked />
        <span className="checkmark"></span>
      </label>
      <label className="checkbox-container">
        Checkbox unchecked
        <input type="checkbox" />
        <span className="checkmark"></span>
      </label>
      <div className="my-3"></div>
      <label className="radio-container">
        Radio
        <input type="radio" defaultChecked name="radio" />
        <span className="checkmark"></span>
      </label>
      <label className="radio-container">
        Radio unchecked
        <input type="radio" name="radio" />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};
export default StyleGuide;
