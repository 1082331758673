import { Dropdown } from 'primereact/dropdown';
import styled from 'styled-components';

const StyledDropdown = styled(Dropdown)`
  &.ah-dropdown {
    width: 100%;
    border: ${(props) => props.border || '1px solid var(--border-gray-neutral)'};
    padding: ${(props) => props.padding || '12px'};
    background: ${(props) => props.background || 'var(--bg-gray-50)'};
    border-radius: ${(props) => props.borderRadius || 'var(--border-radius-7)'};

    &.p-disabled,
    &.p-component:disabled {
      opacity: 0.6;
    }

    :not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 2px var(--border-secondary-300);
      border-color: var(--border-secondary-300);
    }

    :hover {
      border-color: var(--border-secondary-300);
    }

    .p-dropdown-label {
      &.p-placeholder {
        color: ${(props) => props.placeholderColor || 'var(--color-black-opacity-60)'};
        font-size: ${(props) => props.placeholderfontSize || 'var(--color-Neutral-400)'};
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
      &.p-inputtext {
        color: ${(props) => props.inputtextColor || 'var(--color-neutral-900)'};
        font-size: ${(props) => props.inputtextfontSize || '14px'};
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
    }
    .p-dropdown-clear-icon {
      top: 35%;
      margin-right: 5px;
    }

    &.p-invalid.p-component {
      border-color: var(--border-error);
    }
  }
`;

export default StyledDropdown;
