import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  modifyPrognostogramOrder,
  orderPrognostogram,
  clearOrderFormData,
  resetOrderIndexData,
} from 'redux/modules/orderDetails/actions';
import { useOrdersPagedata } from '../OrderPageContext';
import TextArea from 'components/form-input/TextArea';
import { INPUT_SIZE } from 'constants/Constants';
import urlConstantsEnum from 'constants/enum/urlConstantsEnum';
import FormButtons from '../FormButtons';

function Form() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isUploadingDocument, singleOrderData } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const {
    isFormValid,
    orderPayloadData,
    dispatchOrderPageAction,
    isReorder,
    ordersPageData: { PICOOrderDetails, selectedDataSourceOption },
    token,
    isUserInViewAsMode,
  } = useOrdersPagedata();

  const {
    clinicalQuestion,
    initialClinicalQuestions,
    population,
    intervention,
    control,
    outcome,
    timeframe,
  } = PICOOrderDetails;

  const onInputChange = (e, name) => {
    if (e.target.value.length <= INPUT_SIZE) {
      const data = {
        ...PICOOrderDetails,
        [name]: e.target.value,
      };

      dispatchOrderPageAction({
        type: 'order/PICOOrderDetails',
        payload: data,
      });
    } else {
      e.preventDefault();
    }
  };

  const submitOrder = () => {
    if (isFormValid()) {
      dispatch(resetOrderIndexData());
      dispatchOrderPageAction({
        type: 'order/seIsOrderSubmitted',
        payload: true,
      });

      const order = {
        ...orderPayloadData,
        order: {
          ...orderPayloadData?.order,
          initial_clinical_questions: !initialClinicalQuestions
            ? clinicalQuestion
            : initialClinicalQuestions,
          clinical_questions: clinicalQuestion,
          question_title: clinicalQuestion,
          pico_guid: '',
          user_phenotypes: '',
          product_case_type: 'PG',
          population,
          intervention,
          control,
          outcome,
          timeframe,
          data_source: selectedDataSourceOption,
        },
      };

      if (isReorder) {
        dispatch(
          modifyPrognostogramOrder({
            params: order,
            orderGuid: singleOrderData?.guid,
            token: token || '',
            isChatrwd: false,
            navigateToDashboard: navigateToDashboard,
          }),
        );
      } else {
        dispatch(
          orderPrognostogram({
            params: order,
            csrfToken: Cookies.get('csrftoken'),
            token: token || '',
            isChatrwd: false,
            navigateToDashboard: navigateToDashboard,
          }),
        );
      }
    }
  };

  const navigateToDashboard = () => {
    dispatchOrderPageAction({
      type: 'order/seIsOrderSubmitted',
      payload: false,
    });
    dispatch(clearOrderFormData());
    history.push(urlConstantsEnum.ORDER_SUBMITTED);
  };

  const handleCancelRequest = () => {
    dispatchOrderPageAction({
      type: 'order/setNavigationConfirmationModalOpen',
      payload: true,
    });
  };

  return (
    <>
      <div className="picot-container">
        <div className="picot-lable mb-2">Question title</div>
        <TextArea
          value={clinicalQuestion}
          rows={2}
          onChange={(e) => onInputChange(e, 'clinicalQuestion')}
          id="textarea-question-text"
        />
        <div className="text-count">
          {clinicalQuestion.length}/{INPUT_SIZE}
        </div>
        <div className="picot-lable mb-2">Population</div>
        <TextArea
          value={population}
          rows={2}
          onChange={(e) => onInputChange(e, 'population')}
          id="textarea-population"
        />
        <div className="text-count">
          {population.length}/{INPUT_SIZE}
        </div>
        <div className="picot-lable mb-2">Intervention</div>
        <TextArea
          value={intervention}
          rows={2}
          onChange={(e) => onInputChange(e, 'intervention')}
          id="textarea-intervention"
        />
        <div className="text-count">
          {intervention.length}/{INPUT_SIZE}
        </div>
        <div className="picot-lable mb-2">Control</div>
        <TextArea
          value={control}
          rows={2}
          onChange={(e) => onInputChange(e, 'control')}
          id="textarea-control"
        />
        <div className="text-count">
          {control.length}/{INPUT_SIZE}
        </div>
        <div className="picot-lable mb-2">Outcome</div>
        <TextArea
          value={outcome}
          rows={2}
          onChange={(e) => onInputChange(e, 'outcome')}
          id="textarea-outcome"
        />
        <div className="text-count">
          {outcome.length}/{INPUT_SIZE}
        </div>
        <div className="picot-lable mb-2">Timeframe</div>
        <TextArea
          value={timeframe}
          rows={2}
          onChange={(e) => onInputChange(e, 'timeframe')}
          id="textarea-timeframe"
        />
        <div className="text-count">
          {timeframe.length}/{INPUT_SIZE}
        </div>
      </div>

      <FormButtons
        disabled={!clinicalQuestion || isUploadingDocument || isUserInViewAsMode}
        onSubmit={submitOrder}
        buttonAction="Submit order button clicked, order type PG"
        actionLabel="New PG order is submitted"
        id="btn-submit-order-button"
      />
    </>
  );
}

export default Form;
