import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from 'components/form/checkbox';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';

function Suggestion({ suggestion, index, optionIndex }) {
  const {
    ordersPageData: { chatRWD },
    dispatchOrderPageAction,
  } = useOrdersPagedata();
  const { checkedSuggestionsOptions } = chatRWD;
  const { studyFormats, isOpenAiLoading } = useSelector(
    ({ orderDetails }) => orderDetails,
  );

  const isChecked = checkedSuggestionsOptions[optionIndex]?.some(
    (item) => item.field === suggestion.field && item.id === suggestion.id,
  );

  const isDisabled = studyFormats?.length - 1 > optionIndex || isOpenAiLoading || false;

  function onSuggestionChange(e) {
    const updatedSuggestionsOptions = structuredClone(checkedSuggestionsOptions);
    let _checkedSuggestions = updatedSuggestionsOptions[optionIndex] || [];

    if (e.checked) {
      _checkedSuggestions.push(e.value);
    } else {
      _checkedSuggestions = _checkedSuggestions.filter((item) => item.id !== e.value.id);
    }

    updatedSuggestionsOptions[optionIndex] = _checkedSuggestions;

    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'checkedSuggestionsOptions',
        value: updatedSuggestionsOptions,
      },
    });
  }

  return (
    <div className="d-flex">
      <Checkbox
        className="mt-2"
        inputId={`suggestion-${optionIndex}-${index}`}
        name={`suggestion-${optionIndex}`}
        onChange={onSuggestionChange}
        value={suggestion}
        checked={isChecked}
        disabled={isDisabled}
        checkboxname={suggestion?.name}
        background="transparent"
      />

      <label
        className="ah-checkbox-label margin ml-sm-12 ml-md-3 ml-lg-4"
        htmlFor={`suggestion-${optionIndex}-${index}`}
        style={{ cursor: isDisabled ? 'auto' : '' }}
      >
        <div className="mb-1 detail-item">
          <strong className="txt size-16 gray-20 lh-28 text-bold">
            {index + 1}. {suggestion.name} ({suggestion.field}):{' '}
          </strong>
          <span className="txt size-16 gray-20 lh-28">{suggestion.description}</span>
        </div>
      </label>
    </div>
  );
}

export default Suggestion;
