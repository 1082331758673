import React from 'react';
import { ImCheckmark } from 'react-icons/im';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { ButtonLink, ButtonCustom } from 'components/form-input/Button';

const showButtonText = (isCompleted, sectionNumber) => {
  if (isCompleted) {
    return <ImCheckmark />;
  } else {
    return sectionNumber;
  }
};

const OrderPrognostogramHeader = ({
  headerArray = [],
  onClickOfBackBtn,
  headerText,
  hideHeaderSections = true,
  isShowBackArrow = true,
  isSinglePage = false,
}) => {
  const handleOnClick = () => {
    !!onClickOfBackBtn && onClickOfBackBtn();
  };
  return (
    <div className="order-header-container">
      {isShowBackArrow && (
        <ButtonCustom
          cssClass="order-back-button"
          onClick={() => handleOnClick()}
          buttonAction={'Order back button clicked'}
          id={'btn-order-back-button'}
        >
          <HiOutlineArrowLeft />
        </ButtonCustom>
      )}
      <div
        className={`order-page-title ${
          isSinglePage ? 'single-order-page-title my-3 px-md-6 px-0' : 'my-3 mb-md-8 mt-md-4'
        }`}
      >
        {headerText}
      </div>
      {hideHeaderSections ? (
        <div className="container-lg clearfix text-center">
          {headerArray?.map((section, index) => (
            <div className={'question-section-btn col-4 float-left'} key={index}>
              <ButtonLink
                cssClass={
                  section.isActive || section.isCompleted
                    ? 'active question-btn'
                    : 'question-btn'
                }
                buttonAction={`${index + 1}' order creation section'`}
              >
                {showButtonText(section.isCompleted, index + 1)}
              </ButtonLink>
              <div
                className={
                  section.isActive ? 'active section-name mt-2' : 'section-name mt-2 mb-3'
                }
              >
                <span className="d-desktop">{section.name}</span>
                <div className="d-mobile">
                  <div>{section.name.split(' ')[0]}</div>
                  <div>{section.name.split(' ')[1]}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default OrderPrognostogramHeader;
