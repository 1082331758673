import { notification } from 'api';
import {
  call,
  takeLatest,
  put,
  select,
  all,
  fork,
  delay,
  cancel,
} from 'redux-saga/effects';
import {
  CLEAR_INTERVAL_TASK,
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_COMPLETE,
  GET_BADGE_NOTIFICATIONS,
  GET_BADGE_NOTIFICATIONS_COMPLETE,
  getAllNotificationsError,
  getBadgeNotificationsError,
  READ_NOTIFICATION,
  READ_NOTIFICATION_COMPLETE,
  readNotificationError,
  RESET_NOTIFICATION_COUNT,
  resetNotificationError,
  SET_BADGE_NOTIFICATION_COUNT,
  SET_NOTIFICATION_GUID,
  SET_NOTIFICATIONS_LOADING,
  SET_PAGE_NUMBER,
  RESET_NOTIFICATION_COUNT_START,
  setintervalTask,
} from './actions';
import { currentInterval, getNotificationsFromState } from './selector';
import orderFormErrorStr from 'constants/errorStrings';

function* getAllNotifications({ payload: { token, userGuid, page, size } }) {
  try {
    const { response, error } = yield call(notification.getAllNotifications, {
      token,
      userGuid,
      page,
      size,
    });

    if (!!response) {
      const prevNotifications = yield select(getNotificationsFromState);
      const {
        banner_failure_unread_count: prev_banner_failure_unread_count,
        banner_success_unread_count: prev_banner_success_unread_count,
        bell_unread_count: prev_bell_unread_count,
      } = prevNotifications;

      const {
        banner_failure_unread_count,
        banner_success_unread_count,
        bell_unread_count,
      } = response.data;

      if (
        banner_failure_unread_count !== prev_banner_failure_unread_count ||
        banner_success_unread_count !== prev_banner_success_unread_count ||
        prev_bell_unread_count !== bell_unread_count
      ) {
        yield put({
          type: GET_ALL_NOTIFICATIONS_COMPLETE,
          payload: response.data,
        });
      }
    } else {
      yield put(
        getAllNotificationsError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      getAllNotificationsError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

function* intervalWorker(interval, payload) {
  while (true) {
    yield call(getAllNotifications, payload);
    yield delay(interval);
  }
}

export function* startGetAllNotificationsInterval(payload) {
  const interval = 5000;
  const intervalTask = yield fork(intervalWorker, interval, payload);
  yield put(setintervalTask(intervalTask));
}

export function* getBadgeNotifications({ payload: { token, userGuid, page, size } }) {
  try {
    yield put({
      type: SET_NOTIFICATIONS_LOADING,
      payload: true,
    });
    const { response, error } = yield call(notification.getAllNotifications, {
      token,
      userGuid,
      page,
      size,
    });
    if (!!response) {
      const { result, count, end_index, start_index, total_pages, unread_count } =
        response.data;
      const badgeNotificationsCount = {
        count,
        end_index,
        start_index,
        total_pages,
        unread_count,
      };

      yield all([
        put({
          type: GET_BADGE_NOTIFICATIONS_COMPLETE,
          payload: result,
        }),
        put({
          type: SET_BADGE_NOTIFICATION_COUNT,
          payload: badgeNotificationsCount,
        }),
        put({
          type: SET_PAGE_NUMBER,
          payload: page + 1,
        }),
        put({
          type: SET_NOTIFICATIONS_LOADING,
          payload: false,
        }),
      ]);
    } else {
      yield put(
        getBadgeNotificationsError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      getBadgeNotificationsError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* readNotification({
  payload: { token, userGuid, notificationGuid, isExternalPage },
}) {
  try {
    yield put({
      type: SET_NOTIFICATIONS_LOADING,
      payload: true,
    });
    const { response, error } = yield call(notification.readNotification, {
      token,
      userGuid,
      notificationGuid,
    });

    if (!!response) {
      const { notification_guid } = response.data;
      yield put({
        type: READ_NOTIFICATION_COMPLETE,
        payload: {
          notificationGuid: notification_guid,
        },
      });

      if (isExternalPage) {
        yield put({
          type: SET_NOTIFICATION_GUID,
          payload: undefined,
        });
      }
    } else {
      yield put(
        readNotificationError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch (error) {
    yield put(
      readNotificationError({
        error: orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* resetUnreadNotificationCount({
  payload: { token, userGuid, page, size, params, callBadgeNotifications },
}) {
  try {
    const { response, error } = yield call(notification.resetNotificationCount, {
      token,
      userGuid,
      params,
    });

    if (!!response) {
      if (callBadgeNotifications) {
        yield getBadgeNotifications({ payload: { token, userGuid, page, size } });
      }
      yield put({
        type: RESET_NOTIFICATION_COUNT_START,
        payload: false,
      });
      yield put(setintervalTask(null));
      const payloadObj = {
        token,
        userGuid,
        page,
        size: 15,
      };
      yield put({
        type: GET_ALL_NOTIFICATIONS,
        payload: payloadObj,
      });
    } else {
      yield put(
        resetNotificationError({
          error: error?.data.message
            ? error?.data.message
            : orderFormErrorStr.commonError,
        }),
      );
    }
  } catch {
    yield put(
      resetNotificationError({
        error: error?.data.message ? error?.data.message : orderFormErrorStr.commonError,
      }),
    );
  }
}

export function* clearNotificationIntervalTask() {
  const interval = yield select(currentInterval);
  if (interval) {
    yield cancel(interval);
  }
}

function* watchgetAllNotifications() {
  yield takeLatest(GET_ALL_NOTIFICATIONS, startGetAllNotificationsInterval);
}

function* watchgetReadNotification() {
  yield takeLatest(READ_NOTIFICATION, readNotification);
}

function* watchResetNotificationCount() {
  yield takeLatest(RESET_NOTIFICATION_COUNT, resetUnreadNotificationCount);
}

function* watchgetBadgeNotifications() {
  yield takeLatest(GET_BADGE_NOTIFICATIONS, getBadgeNotifications);
}

function* watchgetClearIntervalTask() {
  yield takeLatest(CLEAR_INTERVAL_TASK, clearNotificationIntervalTask);
}

export const sagas = [
  watchgetAllNotifications,
  watchgetReadNotification,
  watchResetNotificationCount,
  watchgetBadgeNotifications,
  watchgetClearIntervalTask,
];
