import React, { useEffect, useRef } from 'react';
import { Strings } from '../constants/Strings';
import { ButtonCustom } from 'components/form-input/Button';
import { useMixpanel } from 'react-mixpanel-browser';
import Tag from './tag/Tag';

const PrognostorgramOrderDetails = ({
  uuid,
  date,
  tag,
  color,
  questionStatus,
  email,
  specialtyTag,
  tabIndex,
  filterBySpecialty,
  filterByStatus,
  filterByDataSet,
  filterByMotivation,
  dataSource,
  triage,
  motivations,
  dataSourceDetails,
  order,
}) => {
  const ref = useRef(null);
  const mixpanel = useMixpanel();
  const [show, setShow] = React.useState(false);
  const [isShowMotivationTag, setShowMotivationTag] = React.useState(false);
  const [filteredMotivationsList, setFilteredMotivationsList] = React.useState('');
  const [motivationCount, setMotivationCount] = React.useState(0);
  const [triageShow, setTraigeShow] = React.useState(false);
  const [isShowMotivationDropdown, setShowMotivationDropdown] = React.useState(false);
  const [showDataSourceTooltip, setShowDataSourceTooltip] = React.useState(false);

  var newUuid;
  if (uuid) {
    newUuid = uuid.slice(0, 8);
  }

  const showDescription = (status) => {
    switch (status) {
      case 'Pending':
        return Strings.pendingDesc;
      default:
      case 'In Progress':
        return (
          <div>
            {Strings.inProgressDesc}
            <a href={'mailto:consult@atroposhealth.com'}>click here.</a>
          </div>
        );
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  useEffect(() => {
    if (motivations) {
      var filteredMotivations = [];
      const isMotivationPresent = motivations?.map((motivation) => {
        return motivation.value;
      });
      for (var i = 0; i < motivations?.length; i++) {
        if (motivations[i].value) {
          filteredMotivations.push(motivations[i].category);
        }
      }
      setFilteredMotivationsList(filteredMotivations);
      setMotivationCount(isMotivationPresent.filter((x) => x == true).length);
      setShowMotivationTag(isMotivationPresent.includes(true));
    }
  }, [motivations]);

  const filterSingleMotivation = (e, motivation) => {
    e.stopPropagation();
    setShowMotivationDropdown(false);
    filterByMotivation(motivation);
  };

  const showMotivations = () => {
    return isShowMotivationTag ? (
      <>
        {motivationCount === 1 ? (
          <div onClick={filterByMultipleProviderMotivations}>
            {motivations?.map((motivation, index) => {
              if (motivation.value) {
                return (
                  <React.Fragment key={index + '' + motivation.category}>
                    <Tag tagName={motivation.category} type="motivation" />
                  </React.Fragment>
                );
              }
            })}
          </div>
        ) : (
          <div
            onMouseEnter={() => setShowMotivationDropdown(true)}
            onMouseLeave={() => setShowMotivationDropdown(false)}
            onClick={filterByMultipleProviderMotivations}
            className="provider-motivation-tag"
          >
            <Tag type="motivation" tagName={`Motivation (${motivationCount})`} />

            {isShowMotivationDropdown ? (
              <div className="provider-container">
                <ul>
                  {motivations?.map((motivation, index) => {
                    if (motivation.value) {
                      return (
                        <li key={index + '-' + motivation.category}>
                          <ButtonCustom
                            cssClass="my-2 px-2 cursor-pointer"
                            onClick={(e) =>
                              filterSingleMotivation(e, motivation.category)
                            }
                            id={'btn-specialty-tag-button'}
                            buttonAction={'Motivation button clicked'}
                            actionValue={motivation.category}
                          >
                            {motivation.category}
                          </ButtonCustom>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </>
    ) : (
      ''
    );
  };

  const filterByMultipleProviderMotivations = () => {
    if (window.innerWidth < 768) {
      if (isShowMotivationDropdown) {
        filterByMotivation(filteredMotivationsList.join(','));
      } else {
        setShowMotivationDropdown(true);
      }
    } else {
      filterByMotivation(filteredMotivationsList.join(','));
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDataSourceTooltip(false);
    }
  };

  const showEditButton = (questionStatus) => {
    return (
      // <div
      //   className={`text-bold ${
      //     show === true && questionStatus !== 'Completed' ? 'top-arrow' : ''
      //   }`}
      //   onClick={filterByStatus}
      //   onMouseEnter={() => {
      //     setShow(true);
      //     mixpanel.track('Order status tooltip opened event');
      //   }}
      //   onMouseLeave={() => {
      //     setShow(false);
      //     mixpanel.track('Order status tooltip closed event');
      //   }}
      // >
      //   {show && questionStatus !== 'Completed' ? (
      //     <div className="position-absolute status-desc">
      //       {showDescription(questionStatus)}
      //     </div>
      //   ) : (
      //     ''
      //   )}

      //   <Tag tagName={questionStatus} type="status" />
      // </div>
      <div className="">
        <div className="custom-tooltip-container">
          <Tag onClick={filterByStatus} tagName={questionStatus} type="status" />
          {questionStatus !== 'Completed' && (
            <span className="custom-tooltipTop-text custom-tooltip-right">
              {showDescription(questionStatus)}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="details">
      <div className="d-flex flex-wrap gap-10 mb-1">
        {tabIndex === 0 && questionStatus !== 'Triage' ? (
          showEditButton(questionStatus)
        ) : questionStatus === 'Triage' ? (
          <div className="">
            <div className="custom-tooltip-container">
              <Tag onClick={filterByStatus} tagName="Triage" type="status" />

              <span className="custom-tooltipTop-text custom-tooltip-right">
                "This order was triaged by the Atropos team, please contact us at{' '}
                <a
                  className="txt size-13 text-white"
                  href={'mailto:consult@atroposhealth.com'}
                >
                  support@atroposhealth.com
                </a>{' '}
                if you have any questions."
              </span>
            </div>
          </div>
        ) : (
          ''
        )}

        {order?.product_case_type && (
          <Tag tagName={order?.product_case_type} type="caseProductType" />
        )}

        {dataSourceDetails && (
          <div className="">
            <div className="custom-tooltip-container">
              <Tag tagName={dataSourceDetails?.name} type="datasetName" />
              {dataSourceDetails.description && (
                <span className="custom-tooltipTop-text custom-tooltip-right">
                  {dataSourceDetails.description}
                </span>
              )}
            </div>
          </div>
        )}

        {showMotivations()}

        {specialtyTag ? <Tag tagName={specialtyTag} type="specialty" /> : ''}

        {/* {dataSource && (
          <div
            ref={ref}
            className="tag my-2 p-1 data-source-tag position-relative"
            onMouseEnter={() => {
              setShowDataSourceTooltip(true);
            }}
            onClick={() => {
              setShowDataSourceTooltip(!showDataSourceTooltip);
              filterByDataSet();
            }}
            onMouseLeave={() => {
              setShowDataSourceTooltip(false);
            }}
          >
            {dataSource}
            <div
              className={`${
                showDataSourceTooltip ? 'tooltip-boxs visible' : 'tooltip-boxs'
              } ${dataSource === 'Institutional' ? 'tooltip-i' : ''}`}
            >
              {dataSource === 'Institutional' ? (
                <div>
                  This Prognostogram was generated using EHR data from your organization.
                  For more information on the specific attributes of this data set, please
                  contact us at{' '}
                  <a href="mailto:support@atroposhealth.com">support@atroposhealth.com</a>
                </div>
              ) : (
                <div>
                  This Prognostogram was generated from a national data source procured
                  and maintained by Atropos Health. For more information on the specific
                  attributes of this data set, please contact us at{' '}
                  <a href="mailto:support@atroposhealth.com">support@atroposhealth.com</a>
                </div>
              )}
            </div>
          </div>
        )} */}

        {/* {tag !== 'N/A' ? (
          <div style={{ backgroundColor: color }} className="tag my-2 p-1">
            {tag !== 'N/A' ? tag : ''}
          </div>
        ) : (
          ''
        )} */}

        {/* {questionStatus === 'Triage' ? (
          <div
            className={`tag triageTag status my-2 p-1 text-bold ${
              triageShow === true ? 'top-arrow' : ''
            }`}
            onClick={filterByStatus}
            onMouseEnter={() => setTraigeShow(true)}
            onMouseLeave={() => setTraigeShow(false)}
          >
            {triageShow ? (
              <div className="position-absolute status-desc">
                "This order was triaged by the Atropos team, please contact us at{' '}
                <a className="link status-link" href={'mailto:consult@atroposhealth.com'}>
                  support@atroposhealth.com
                </a>{' '}
                if you have any questions."
              </div>
            ) : (
              ''
            )}
            Triaged
          </div>
        ) : (
          ''
        )} */}
      </div>
      <div className="d-flex flex-justify-start">
        <div className="date my-2">{date}</div>
        {uuid ? (
          <div className="date my-2 ml-sm-1">
            &nbsp;-&nbsp;ID {newUuid.replace(/.{4}(?!$)/g, '$&-')}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default PrognostorgramOrderDetails;
