import React, { useState } from 'react';
import PropTypes from 'prop-types';

const TextArea = ({
  label,
  placeholder,
  value,
  onChange,
  onKeyUp,
  onFocus,
  onBlur,
  showErrorText,
  errorText,
  style,
  testID,
  accessibilityLabel,
  rows,
  id,
  ...props
}) => {
  const [focus, setFocus] = useState(false);

  const handleChange = (e) => {
    !!onChange && onChange(e);
  };

  const handleKeyUp = (e) => {
    !!onKeyUp && onKeyUp(e);
  };

  const handleFocus = () => {
    setFocus(true);
    onFocus && onFocus();
  };

  const handleBlur = () => {
    setFocus(false);
    onBlur && onBlur();
  };
  return (
    <div className="input-conatainer">
      <textarea
        className={`input-textarea${showErrorText ? ' border-error' : ''}`}
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onFocus={handleFocus}
        onBlur={handleBlur}
        id={id}
        {...props}
      />
      {showErrorText && <div>{errorText}</div>}
    </div>
  );
};

TextArea.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChangeText: PropTypes.func,
  showErrorText: PropTypes.bool,
  errorText: PropTypes.string,
  style: PropTypes.array,
  testID: PropTypes.string,
  accessibilityLabel: PropTypes.string,
};

TextArea.defaultProps = {
  placeholder: '',
  value: '',
  testID: '',
  accessibilityLabel: '',
  showErrorText: false,
  errorText: '',
  style: [],
};

export default TextArea;
