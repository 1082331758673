import React, { useEffect, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import AuthLoader from 'components/AuthLoader';
import { useMixpanel } from 'react-mixpanel-browser';
import {
  resetSubscriptionError,
  resetStripeRedirectUrl,
} from 'redux/modules/userSubscription/actions';
import { setVideoModalDisplay } from 'redux/modules/userProfile/actions';
import {subscriptionEnum} from '../constants/enum/subscriptionEnum';

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(({ userProfile }) => userProfile);
  let { transaction_id } = useParams();
  const mixpanel = useMixpanel();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    dispatch(
      setVideoModalDisplay({
        isDisplayModal: true,
        subscriptionType: subscriptionEnum.STANDARD,
      }),
    );
    const timer = setTimeout(() => {
      setRedirect(true);
    }, 6000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    dispatch(resetSubscriptionError());
    dispatch(resetStripeRedirectUrl());
  }, [dispatch]);

  const trackPaymentStatus = () => {
    mixpanel.track('Payment completed successfully');
  };

  return !isLoading ? (
    <div className="app-container d-flex col-12 flex-justify-center flex-items-center">
      <div className="email-outer-container">
        <div className="d-flex flex-column flex-items-center email-inner-container">
          {redirect ? (
            <>
              {trackPaymentStatus()}
              <Redirect to="/dashboard" />
            </>
          ) : (
            <div className="d-flex flex-column flex-items-center">
              <div className="email-success-icon mb-6 d-flex flex-items-center flex-justify-center">
                <AiOutlineCheck />
              </div>
              <div className="email-header-text">Success</div>
              <div className="mt-3">Your payment completed successfully.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="position-relative">
      <AuthLoader fullScreen={false} />
    </div>
  );
};
export default PaymentSuccess;
