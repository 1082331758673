import * as viewAsActions from './types';

let initialState = {
  errors: {},
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case viewAsActions.CHECK_VIEW_ACCESS_LOADING:
      return {
        ...state,
        checkViewAccessLoading: true,
      };
    case viewAsActions.CHECK_VIEW_ACCESS_SUCCESS:
      return {
        ...state,
        checkViewAccessLoading: false,
      };
    case viewAsActions.CHECK_VIEW_ACCESS_FAILURE:
      return {
        ...state,
        checkViewAccessLoading: false,
        errors: {
          ...state.errors,
          viewAsError: payload,
        },
      };
    case viewAsActions.RESET_VIEW_AS:
      return {
        ...state,
        checkViewAccessLoading: false,
        errors: {},
      };
    default:
      return state;
  }
};

export default reducer;
