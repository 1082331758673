import React from 'react';
import { StyledInputNumber } from './StyledInputNumber';

function InputNumber({ value, inputId, onChange, ...props }) {
  return (
    <StyledInputNumber
      inputClassName="ah-input"
      value={value}
      onChange={onChange}
      inputId={inputId}
      {...props}
    />
  );
}

export default InputNumber;
