import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthLoader from 'components/AuthLoader';
import { requestGetUserProfile } from 'redux/modules/userProfile/actions';
import { useToken } from 'hooks/useToken';

function UserProvider({ children }) {
  const token = useToken();
  const dispatch = useDispatch();
  const { isFetchProfileCompleted, userData, userProfileError } = useSelector(
    ({ userProfile }) => userProfile,
  );

  useEffect(() => {
    if (token) {
      dispatch(
        requestGetUserProfile({
          token,
        }),
      );
    }
  }, [token, dispatch]);

  if (!isFetchProfileCompleted) {
    return <AuthLoader fullScreen={false} />;
  }

  if (userProfileError) {
    throw new Error(userProfileError);
  }

  if (userData && userData?.guid && isFetchProfileCompleted) return children;

  return null;
}

export default UserProvider;
