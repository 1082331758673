import React from 'react';
import { Button } from 'components/button';
import { labels } from 'constants/labels';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';

function CancelRequest() {
  const { dispatchOrderPageAction } = useOrdersPagedata();
  function handleCancelRequest() {
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'hasClickedOnStartOver',
        value: true,
      },
    });
  }

  return (
    <Button
      label={labels.cancelRequest}
      severity="secondary"
      buttonClass="w-100"
      onClick={handleCancelRequest}
    />
  );
}

export default CancelRequest;
