import React from 'react';

const NoOrder = ({ headerText, imgUrl, bodyText }) => {
  return (
    <div className="text-center no-order-personal">
      <img src={imgUrl} alt={headerText} />
      <div className="no-order-msg mt-5">{headerText}</div>
      {bodyText()}
    </div>
  );
};
export default NoOrder;
