import React from 'react';
import StyledDropdown from './StyledDropdown';
import { ReactComponent as ChevronDown } from 'assets/images/ChevronDown.svg';
import { useMixpanel } from 'react-mixpanel-browser';

function Dropdown({
  value,
  onChange,
  options,
  optionLabel,
  placeholder = 'select',
  checkmark = true,
  dropdownIcon = <ChevronDown />,
  ...props
}) {
  const mixpanel = useMixpanel();

  function handleShow() {
    const { dropdownname } = props;
    const action = dropdownname + ' ' + 'dropdown opened';
    mixpanel.track(action, {
      action: action,
      action_value: dropdownname,
    });
  }

  function handleHide() {
    const { dropdownname } = props;
    const action = dropdownname + ' ' + 'dropdown closed';
    mixpanel.track(action, {
      action: action,
      action_value: dropdownname,
    });
  }

  function handleChange(event) {
    const action = event.value + ' ' + 'option selected';
    mixpanel.track(action, {
      action: action,
      action_value: event.value,
    });
    onChange(event);
  }

  return (
    <StyledDropdown
      className="ah-dropdown"
      panelClassName={`ah-dropdown-panel ${checkmark ? 'has-svg' : ''}`}
      value={value}
      onChange={handleChange}
      options={options}
      optionLabel={optionLabel}
      placeholder={placeholder}
      dropdownIcon={dropdownIcon}
      checkmark={checkmark}
      onShow={handleShow}
      onHide={handleHide}
      {...props}
    />
  );
}

export default Dropdown;
