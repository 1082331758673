import * as viewAsActions from './types';

export const setViewAsMode = (payload) => ({
  type: viewAsActions.SET_VIEW_AS_MODE,
  payload,
});

export const checkViewAccessRequest = (payload) => ({
  type: viewAsActions.CHECK_VIEW_ACCESS_REQUEST,
  payload,
});

export const resetViewAs = (payload) => ({
  type: viewAsActions.RESET_VIEW_AS,
  payload,
});
