import React from 'react';
import { InputText } from 'components/form/input';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { Controller } from 'react-hook-form';
import { labels } from 'constants/labels';

function StudyFormInputs({ fields, clearErrors, setError, control, ...props }) {
  const { workflow } = props;

  const {
    ordersPageData: { chatRWD },
    dispatchOrderPageAction,
  } = useOrdersPagedata();
  const { formValues } = chatRWD;

  function handleChange({ event, field }) {
    const { name, value } = event.target;
    let formValues = {
      [name]: value,
    };
    if (workflow === labels.manualEdit) {
      if (name === labels.intervention) {
        formValues = {
          ...formValues,
          cohort_1: value,
        };
      }
      if (name === labels.control) {
        formValues = {
          ...formValues,
          cohort_2: value,
        };
      }
    }
    dispatchOrderPageAction({
      type: 'order/updateChatRWDFormValues',
      payload: formValues,
    });

    if (value) {
      clearErrors(field.name);
    } else {
      const errorMessage = field.required ? `${field.name} is required` : '';

      setError(field.name, {
        type: 'manual',
        message: errorMessage,
      });
    }
  }

  return (
    <>
      {fields.map((field) => (
        <div className="d-flex flex-column gap-8" key={field.name}>
          <label className="txt w-700 size-14 dark text-capitalize" htmlFor={field.name}>
            {field.name}
            {field.required && '*'}
          </label>

          <Controller
            control={control}
            name={field.name}
            defaultValue={formValues[field.name] || ''}
            rules={{ required: field.required ? `${field.name} is required` : false }}
            render={({
              field: { onChange, name, ref, value },
              fieldState: { error },
            }) => (
              <InputText
                ref={ref}
                name={name}
                value={value}
                onChange={(event) => {
                  const { value } = event.target;
                  onChange(value);

                  handleChange({ event, field });
                }}
                invalid={error && error.message ? true : false}
                errormesaage={error ? error.message : ''}
              />
            )}
          />
        </div>
      ))}
    </>
  );
}

export default StudyFormInputs;
